import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NetworkService } from './network.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class WishlistService {
  private wishlist:any[]=[];
  public wishlistCount:BehaviorSubject<number>=new BehaviorSubject(this.wishlist.length);
  constructor(private http:HttpClient){}
  getMyWishList(){
    // this.http.get(NetworkService.myWishLists(),{headers:NetworkService.getAuthHeader()}).pipe(map((r:any)=>{
    //   this.wishlist.next(!r.payload || !r.payload.favourites?[]:r.payload.favourites);
    //   return r;
    // }));
    this.http.get(NetworkService.myWishLists(),{headers:NetworkService.getAuthHeader()}).subscribe((r:any)=>{
      //console.log(r);
      this.wishlist=r.payload.favourites?r.payload.favourites:[];
      this.wishlistCount.next(this.wishlist.length);

      setTimeout(() => {
        if ($(window).width() > 620) {
          $('.product-header img').height('230px');
        } else {
            $('.product-header img').height('inherit');
            $('.product-header').height('100px');
        }
      }, 100)
  
    });
  }

  addToWishList(productId):Observable<any>{
    return this.http.post(NetworkService.addToFavourites(),{"_id":productId},{headers:NetworkService.getAuthHeader()}).pipe(map(r=>{
      this.getMyWishList();
      return r;
    }));
  }

  removeFromWishList(productId):Observable<any>{
    return this.http.post(NetworkService.removeFromFavourites(),{"_id":productId},{headers:NetworkService.getAuthHeader()}).pipe(map(r=>{
      this.getMyWishList();
      return r;
    }));
  }

  getLocalWishList(){
    return this.wishlist;
  }

  clearWishList():Observable<any>{
    return this.http.post(NetworkService.clearFavourites(),{},{headers:NetworkService.getAuthHeader()}).pipe(r=>{
      this.getMyWishList();
      return r;
    });
  }

}
