import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { ProfileService } from 'src/app/services/profile.service';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
// import { ReviewService } from 'src/app/services/review.service';

declare var $:any;


@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent implements OnInit {
  submitted:boolean=false;
  error:string;
  registerForm:FormGroup;
  loading:boolean=true;

  constructor(private fb:FormBuilder,private pfService:ProfileService,private route:Router,private altService:AlertService,) {
    console.log(this.pfService.isUserLoggedIn());   
    if(this.pfService.isUserLoggedIn()){
      document.location.href='/';      
      return;
    }    

    if(!this.pfService.isTokenExists())
    {
      document.location.href='/';
      return;
    }

  }

  ngOnInit(): void {
    this.loading=false
    this.registerForm=this.fb.group({
        // confirmPassword:['',[Validators.required]],  
        // firstName:['',[Validators.required]],
        // lastName:['',[Validators.required]],
        // email:['',[Validators.required,Validators.email]],
        // password:['',[Validators.required]],
        address:this.fb.group({
          type:['',Validators.required],
          addressLine:['',Validators.required],
          district:['',Validators.required],
          state:['',Validators.required],
          pinCode:['',[Validators.required,Validators.pattern(/^[0-9]{6,12}$/)]],
          locality:['',Validators.required],
          landMark:['',Validators.required]
        }),
        mobile:['',[Validators.required,Validators.pattern(/^[0-9]{10}$/)]],
        referralCode:['']
    },
    {
      // validator:RegisterUserComponent.passwordValidator('password','confirmPassword')
    }
    );
  }

  skipRegistration(){
    localStorage.setItem('userFound','FOUND');
    localStorage.setItem('service-available','N');
    localStorage.setItem('notify','YES');
    this.altService.showAlertVW('Login Successfully',this.altService.alertTypes.success);
    // window.history.back();
    location.reload();
  }

  get fMessage(){
    return this.registerForm.controls;
  }

  get aMsg(){
    return this.registerForm['controls'].address['controls'];
  }

  
  static passwordValidator(password,confirmPassword){
    return (formGroup:FormGroup)=>{
        const pControl=formGroup.controls[password];
        const cPControl=formGroup.controls[confirmPassword];
        //console.log("P CONTROL",pControl);

        if (cPControl.errors && !cPControl.errors.mustMatch) {
          return;
        }

        if(pControl.value!==cPControl.value){
          cPControl.setErrors({mustMatch:"Password Must Match"})
        }else{
          cPControl.setErrors(null);
        }
    }
  }

  onSubmit(){
    // //console.log(this.registerForm.value);
    // //console.log(this.registerForm.invalid);
   // //console.log(this.fMessage.password.errors);
    
   
   //console.log(this.aMsg);
    //console.log(this.fMessage.confirmPassword);
    this.submitted=true;

    if(this.loading)
      return;

    if(!this.registerForm.invalid){
      this.loading=true;
      this.pfService.register(this.registerForm.value).subscribe(r=>{
        this.loading=false;
        if(r.success)
          { 
            localStorage.setItem('session_user',r.payload.renewedToken);
            localStorage.setItem('service-available',r.payload.isServiceAvailable?'Y':'N');
            localStorage.setItem('userFound','FOUND');
            localStorage.setItem('notify','YES');
            document.location.href='/';
          }
        else
          this.error=r.message;          
      });
    }else{
      this.error="Please Fill All The Required Fields...";
    }
  }




}
