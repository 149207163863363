import { Directive } from '@angular/core';

@Directive({
  selector: '[appProductPrice]'
})
export class ProductPriceDirective {

  constructor() { }

}
