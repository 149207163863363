import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { getApiUrl} from '../../../utils';

@Component({
  selector: 'app-offer-banner2',
  templateUrl: './offer-banner2.component.html',
  styleUrls: ['./offer-banner2.component.css']
})
export class OfferBanner2Component implements OnInit {

  apiUrl:string;
  @Input()
    slider:any;
  constructor(public sanitizer:DomSanitizer) {
    this.apiUrl=getApiUrl();
  }

  ngOnInit(): void {
  }
}
