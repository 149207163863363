<div>
    <div class="card card-body account-right">
        <div class="widget">
            <div class="mb-3">
                <span class="float-right font-weight-bolder" type="button" *ngIf="!addAddress && !editAddress" (click)="this.editAddress=false;addAddress=true;" style="color:orangered;font-size:20px;">
                  Add
                </span>
                <h5 class="heading-design-h5" *ngIf="addAddress">
                    Add New Address
                </h5>
                <h5 class="heading-design-h5" *ngIf="editAddress">
                    Edit Address
                </h5>

                <h5 class="heading-design-h5" *ngIf="!addAddress && !editAddress">
                    Contact Address(es)
                </h5>
            </div>
            <div class="" [class.loading]="loading" *ngIf="(!addAddress || !isLoggedIn) && !editAddress">
                <div class="addressList" style="flex-wrap: inherit !important;" *ngFor="let x of address; let ia=index;" [class.addressListActive]="x._id===selected">
                    <div class=" flex-fill row mr-1 info">
                        <div class="col-sm-2">
                            {{x.type | titlecase}},
                        </div>
                        <div class="col-sm-4">
                            {{x.addressLine | titlecase}},
                        </div>
                        <div class="col-sm-4">
                            {{x.district | titlecase}} - {{x.pinCode}}.
                        </div>
                    </div>

                    <div class="text-center actionBtn d-flex flex-wrap" style=" width: 111px;">
                        <span type="button" class="mx-1 btn btn-sm" (click)="selectAddress(x)" [class.d-none]="x._id===selected"><i class="mdi mdi-check"></i></span>
                        <span type="button" class="mx-1 btn btn-sm" (click)="setEAddress(x)"><i class="mdi mdi-pencil"></i></span>
                        <span type="button" class="mx-1 btn btn-sm" [class.d-none]="address.length==1 || x._id===selected" (click)="deleteAddress(x._id)"><i class="mdi mdi-close"></i></span>
                    </div>
                </div>
                <!-- 
                <div *ngIf="!isLoggedIn" class="card-address card p-0 m-0 card-plus">
                    <div class="card-body text-center">
                        Pin-Code Location {{defaultLocation}}
                    </div>
                </div> -->
            </div>
            <div class="" *ngIf="addAddress">
                <form [formGroup]="newAddress" (ngSubmit)="submit()">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label class="control-label" for="type">Address Type <span class="required">*</span></label>
                                <select class="form-control border-form-control" id="addressType" placeholder="" type="text" formControlName="type" value="" id="addressType" required>
                                    <option value="">Select Type</option>
                                    <option value="Home">Home</option>
                                    <option value="Business">Business</option>
                                </select>
                                <!-- <input class="form-control border-form-control" id="addressType" placeholder="" type="text" formControlName="type" value="" id="addressType" required> -->
                                <div *ngIf="submitted && aMsg.type.errors">
                                    <span class="error-message" *ngIf="aMsg.type.errors.required">Address Type Required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label class="control-label" for="addressLine">Address Line <span class="required">*</span></label>
                                <textarea name="addressLine" value="" formControlName="addressLine" id="addressLine" class="form-control" required></textarea>
                                <div *ngIf="submitted && aMsg.addressLine.errors">
                                    <span class="error-message" *ngIf="aMsg.addressLine.errors.required">Address Line Required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="landMark">Land Mark <span class="required">*</span></label>
                                <input class="form-control border-form-control" type="text" name="landMark" formControlName="landMark" value="" id="landMark" required>
                                <div *ngIf="submitted && aMsg.landMark.errors">
                                    <span class="error-message" *ngIf="aMsg.landMark.errors.required">Land Mark Required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="locality">Locality <span class="required">*</span></label>
                                <input class="form-control border-form-control" type="text" name="locality" formControlName="locality" value="" id="locality" required>
                                <div *ngIf="submitted && aMsg.locality.errors">
                                    <span class="error-message" *ngIf="aMsg.locality.errors.required">Locality Required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="district">District <span class="required">*</span></label>
                                <input class="form-control border-form-control" type="text" name="district" formControlName="district" value="" id="district" required>
                                <div *ngIf="submitted && aMsg.district.errors">
                                    <span class="error-message" *ngIf="aMsg.district.errors.required">District Required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="state">State <span class="required">*</span></label>
                                <input class="form-control border-form-control" type="text" name="state" value="" id="state" formControlName="state" class="form-control" required>
                                <div *ngIf="submitted && aMsg.state.errors">
                                    <span class="error-message" *ngIf="aMsg.state.errors.required">State Required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="pinCode">Pin Code<span class="required">*</span></label>
                                <input class="form-control border-form-control" name="pinCode" value="" id="pinCode" formControlName="pinCode" type="number" required>
                                <div *ngIf="submitted && aMsg.pinCode.errors">
                                    <span class="error-message" *ngIf="aMsg.pinCode.errors.required">Pincode Required.</span>
                                    <span class="error-message" *ngIf="aMsg.pinCode.errors.pattern">Invalid PinCode.</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="row">
                        <div class="col-sm-12">
                            <div class="custom-control custom-checkbox mb-3">
                                <input type="checkbox" class="custom-control-input" id="customCheck1">
                                <label class="custom-control-label" for="customCheck1">Same as Contact Address</label>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-sm-12 text-right">
                            <button type="button" class="btn btn-danger btn-lg mx-2" (click)="reset(); addAddress=false"> Cancel </button>
                            <button type="submit" class="btn btn-success btn-lg"> Add Address </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="" *ngIf="editAddress">
                <form [formGroup]="editAddressForm" (ngSubmit)="submitEditAddress()">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label class="control-label" for="type">Address Type <span class="required">*</span></label>
                                <input class="" id="_id" placeholder="" type="text" formControlName="_id" hidden>
                                <select class="form-control border-form-controlz" id="addressType" placeholder="" formControlName="type" id="addressType" required>
                                    <option value="">Select Type</option>
                                    <option value="Home">Home</option>
                                    <option value="Business">Business</option>
                                </select>
                                <!-- 
                                <input class="form-control border-form-control" id="addressType" placeholder="" type="text" formControlName="type" value="" id="addressType" required> -->
                                <div *ngIf="submitted && eMsg.type.errors">
                                    <span class="error-message" *ngIf="eMsg.type.errors.required">Address Type Required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label class="control-label" for="addressLine">Address Line <span class="required">*</span></label>
                                <textarea name="addressLine" formControlName="addressLine" id="addressLine" class="form-control" required></textarea>
                                <div *ngIf="submitted && eMsg.addressLine.errors">
                                    <span class="error-message" *ngIf="eMsg.addressLine.errors.required">Address Line Required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="landMark">Land Mark <span class="required">*</span></label>
                                <input class="form-control border-form-control" type="text" name="landMark" formControlName="landMark" value="" id="landMark" required>
                                <div *ngIf="submitted && eMsg.landMark.errors">
                                    <span class="error-message" *ngIf="eMsg.landMark.errors.required">Land Mark Required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="locality">Locality <span class="required">*</span></label>
                                <input class="form-control border-form-control" type="text" name="locality" formControlName="locality" value="" id="locality" required>
                                <div *ngIf="submitted && eMsg.locality.errors">
                                    <span class="error-message" *ngIf="eMsg.locality.errors.required">Locality Required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="district">District <span class="required">*</span></label>
                                <input class="form-control border-form-control" type="text" name="district" formControlName="district" value="" id="district" required>
                                <div *ngIf="submitted && eMsg.district.errors">
                                    <span class="error-message" *ngIf="eMsg.district.errors.required">District Required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="state">State <span class="required">*</span></label>
                                <input class="form-control border-form-control" type="text" name="state" value="" id="state" formControlName="state" class="form-control" required>
                                <div *ngIf="submitted && eMsg.state.errors">
                                    <span class="error-message" *ngIf="eMsg.state.errors.required">State Required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="pinCode">Pin Code<span class="required">*</span></label>
                                <input class="form-control border-form-control" name="pinCode" value="" id="pinCode" formControlName="pinCode" type="number" required>
                                <div *ngIf="submitted && eMsg.pinCode.errors">
                                    <span class="error-message" *ngIf="eMsg.pinCode.errors.required">Pincode Required.</span>
                                    <span class="error-message" *ngIf="eMsg.pinCode.errors.pattern">Invalid PinCode.</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="row">
                        <div class="col-sm-12">
                            <div class="custom-control custom-checkbox mb-3">
                                <input type="checkbox" class="custom-control-input" id="customCheck1">
                                <label class="custom-control-label" for="customCheck1">Same as Contact Address</label>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-sm-12 text-right">
                            <button type="button" class="btn btn-danger btn-lg mx-2" (click)="reset(); editAddress=false"> Cancel </button>
                            <button type="submit" class="btn btn-success btn-lg"> Update Address </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>