<section class="account-page section-padding">
    <div class="container-fluid">
        <div class="row" [class.loading]="loading">
            <div class="col-lg-12">
                <div class="row no-gutters">
                    <div class="col-lg-4">
                        <div class="card account-left">
                            <div class="user-profile-header">
                                <img [src]="profile.picture" height="100px" alt="profile-pic">
                                <h5 class="mb-1 text-secondary"><strong>Hi </strong> {{profile.name}}</h5>
                                <p> +91 {{profile.mobile}}</p>
                                <p> {{profile.email}}</p>
                                <h6 class="mt-2"> Wallet : {{profile.walletAmount | currency:"INR"}}</h6>
                                <h6 class="mt-2">
                                    <span title="Click to copy" class="">Referral Code : {{profile.referralCode}}</span>
                                    <span type="button" data-toggle="modal" data-target="#referEarnModal" class="mx-1 text-secondary" title="Refer & Earn" style="vertical-align: middle;"><i class="h5 mdi mdi-information"></i></span>
                                </h6>
                            </div>
                            <div class="list-group">
                                <!-- <a routerLink="profile" [class.active]="currentRoute=='/profile'" class="list-group-item list-group-item-action"><i aria-hidden="true" class="mdi mdi-account-outline"></i>  My Profile</a> -->
                                <a routerLink="address" [class.active]="currentRoute=='/address'" class="list-group-item list-group-item-action"><i aria-hidden="true" class="mdi mdi-map-marker-circle"></i>  My Address</a>
                                <a routerLink="elite-plans" [class.active]="currentRoute=='/elite-plans'" class="list-group-item list-group-item-action"><i aria-hidden="true" class="mdi mdi-crown"></i>  Elite Membership</a>
                                <a routerLink="wishlist" [class.active]="currentRoute=='/wishlist'" class="list-group-item list-group-item-action"><i aria-hidden="true" class="mdi mdi-heart-outline"></i>  Wish List </a>
                                <a routerLink="orders" [class.active]="currentRoute=='/orders'" class="list-group-item list-group-item-action"><i aria-hidden="true" class="mdi mdi-format-list-bulleted"></i>  Order List</a>
                                <a href="javascript:void(0)" data-target="#bd-example-modal-logout" data-toggle="modal" class="list-group-item list-group-item-action"><i aria-hidden="true" class="mdi mdi-lock"></i>  Logout</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal -->
<div class="modal fade custom-modal" id="referEarnModal" tabindex="-1" role="dialog" aria-labelledby="referEarnModalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header bg-dark" style="border-radius: 0 !important;">
                <h5 class="modal-title text-white" id="referEarnModalTitle">REFER &amp; EARN</h5>
                <span type="button" class="modal-btn-close" data-dismiss="modal" aria-label="Close"><i class="mdi mdi-close"></i>
                </span>
            </div>
            <div class="modal-body">
                <h5 class="mb-3 text-secondary">How to Earn and Refer ?</h5>

                <p>Step1: Share your Referral Code to Your Friends.</p>
                <p>Step2: Refer and Earn Process valid when your friend register with the referral Code you referred.</p>
                <p>Step3: After your Friend's first order is delivered. Your wallet will be credited with <span class="text-primary">Rs %1$s</span> and your fiends wallet will be credited with <span class="text-primary">Rs %2$s</span>.</p>
            </div>
        </div>
    </div>
</div>