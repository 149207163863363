import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { CartService } from 'src/app/services/cart.service';
import { ProfileService } from 'src/app/services/profile.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import {getApiUrl} from 'src/app/utils';
declare var $:any;

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {
  apiUrl=getApiUrl();
  wishList:any;
  wlCount:number;
  profile:any;
  
  constructor(
    private pfService:ProfileService,
    private wlService:WishlistService,
    private alert:AlertService,
    private ctS:CartService
  ) { 
    this.profile=this.pfService.getLocalProfile();
    console.log(this.profile);
  }

  ngOnInit(): void {
    this.wlService.wishlistCount.subscribe(v=>{
      this.wlCount=v;
      this.wishList=this.wlService.getLocalWishList();
      //console.log(this.wishList);
    });
  }

  ngAfterViewInit():void{
    setTimeout(() => {
      this.productsListColumnSetup();
    }, 100)
  }

  productsListColumnSetup(){
    if ($(window).width() > 620) {
      $('.product-header img').height('230px');
    } else {
        $('.product-header img').height('inherit');
        $('.product-body h5').css('font-size', 'revert');
        $('.product-header').height('100px');
    }
  }
  
  removeFromFavourites(_id){
    this.wlService.removeFromWishList(_id).subscribe(r=>{
      if(r.success){
        this.alert.showAlertVW(r.message, this.alert.alertTypes.success);  
      }
    });
  }

  addToCart(productId:string,priceId:string):void{
    console.log("ADDING TO CART");
    if(!this.pfService.isUserLoggedIn()){
      $('#login-signup-open-btn').click();
      return; 
    }

    this.ctS.addToCart({quantity:1,productId,priceId}).subscribe(r=>{
      if(r.success){
        $('body').toggleClass('toggled');                
        this.alert.showAlertVW(r.message,this.alert.alertTypes.success);

        // this.wlService.removeFromWishList(_id).subscribe(r=>{
        //   if(r.success){
        //     $('body').toggleClass('toggled');                
        //   }
        //   else{
        //     this.alert.showAlertVW(r.message,this.alert.alertTypes.danger);
        //   }
        //   //console.log(r);
        // });  
      }
      else
      this.alert.showAlertVW(r.message,this.alert.alertTypes.danger);  
    },e=>{
      this.alert.showAlertVW(e.message,this.alert.alertTypes.danger);
    })
  }
  
}
