import {  Component, OnInit, AfterViewInit, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { CartService } from 'src/app/services/cart.service';
import {getApiUrl} from 'src/app/utils';
declare var $:any;

@Component({
  selector: 'app-cart-sidebar',
  templateUrl: './cart-sidebar.component.html',
  styleUrls: ['./cart-sidebar.component.css']
})
export class CartSidebarComponent implements OnInit,AfterViewInit,OnChanges  {
  apiUrl=getApiUrl();
  @Input()
    profile:any;
  @Input()
    carts:any[];
  @Input()
    subTotal:any;
  @Input()
    cartCount:any;
    
  constructor(private ctService:CartService, private altService:AlertService) { }

  @Output()
    refreshComponent=new EventEmitter<any>();

  ngOnInit(): void {
    //console.log("Cart On Init");
  }

  ngAfterViewInit():void{
    //console.log("Cart After Init");
  }

  ngOnChanges():void{
    //console.log("Cart On Change",this.carts);
    //console.log("Cart Length",this.carts.length);
    //console.log("Cart SubTotal",this.subTotal);
  }
  closeSideBar(){
    ////console.log($('.sidebar-cart-wrap'));
    $('body').toggleClass('toggled');        
  }

  clearCart(){
    this.ctService.clearCart().subscribe(r=>{
      this.altService.showAlertVW(r.message, this.altService.alertTypes.success)
      //console.log("Cart Clear Result==>",r);
    });   
  }

  removeFromCart(cart){
    this.ctService.removeFromCart(cart._id).subscribe(r=>{
      //console.log("Cart Deleting Result==>",r);    
    });
  }
}
