import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { ProfileService } from 'src/app/services/profile.service';
declare var window:any;
declare var $:any;
declare var Razorpay:any;
@Component({
  selector: 'app-elite-plan',
  templateUrl: './elite-plan.component.html',
  styleUrls: ['./elite-plan.component.css']
})
export class ElitePlanComponent implements OnInit {
  loading:boolean=true;
  elitePlans:any[]=[];
  constructor(private pfs:ProfileService,private alert:AlertService) {
    this.onRazorFailed=this.onRazorFailed.bind(this);
    this.onRazorSuccess=this.onRazorSuccess.bind(this);
  }
  url:string="https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/";
  txnDetails:any=null;
  config:any;
  subsId:string="";

  ngOnInit(): void {
    this.pfs.getElitePlans().subscribe(r=>{
      if(r.success){
        this.elitePlans=r.payload.elitePlans;
      }else{
        this.elitePlans=[];
      }
      console.log(this.elitePlans);
    },e=>{
      this.elitePlans=[];
      console.log(e.message);
    })
    this.loading = false;
  }

  onRazorSuccess(response:any):void{
    this.loading=true;
    this.pfs.completeEliteSubscription(response,this.subsId).subscribe(r=>{
      if(r.success){
        location.reload();
        return;
      }else{
        this.alert.showAlertVW(r.message,this.alert.alertTypes.danger);
        return;     
      }
    },e=>{
      this.alert.showAlertVW(e,this.alert.alertTypes.danger);
    },()=>{this.loading=false;})
  }

  onRazorFailed(response:any):void{
    this.alert.showAlertVW("Transaction Failed",this.alert.alertTypes.danger);
  }


  subscribeElite(_id:string):void{
    // $(".elite-container").addClass("loading");
    if(this.loading)
      return;
    this.loading=true;
      this.pfs.subscribeElite(_id).subscribe(r=>{
        if(r.success){
          this.subsId=r.payload._id;
          let txnDetails=r.payload.txnDetails;
          delete txnDetails.src;
          txnDetails.handler=this.onRazorSuccess;
          txnDetails.modal={
            ondismiss:this.onRazorFailed
          }
          let rzp=new Razorpay(txnDetails);
          rzp.on('payment.failed',this.onRazorFailed);
          rzp.open();          
        }else{
          this.alert.showAlertVW(r.message,this.alert.alertTypes.danger);
        }
      },e=>{
        return;
      },()=>{
        // $(".elite-container").removeClass("loading");
        this.loading=false;
      });
  }

  // subscribeElite(_id:string):void{
  //   $(".elite-container").addClass("loading");
  //   this.pfs.subscribeElite(_id).subscribe(r=>{
  //     if(r.success){
  //       this.txnDetails=r.payload.txnDetails;
  //       this.txnDetails=r.payload.txnDetails;
  //           let s=this;
  //           this.loadScript().then(()=>{
  //             s.config={
  //               "root":"",
  //               "flow":"DEFAULT",
  //               "data":{
  //                 "orderId":s.txnDetails.orderId,
  //                 "token":s.txnDetails.txnToken,
  //                 "tokenType":"TXN_TOKEN",
  //                 "amount":s.txnDetails.txnAmount.value
  //               },
  //               "handler":{
  //                 "notifyMerchant":function(eventName,data){
  //                   if(eventName=='APP_CLOSED') 
  //                   {	
  //                   }
  //                   s.removePaymentScripts();
  //                 }
  //               }
  //             };	
  //             window.Paytm.CheckoutJS.onLoad(this.check.bind(this));						
  //           });
  //     }else{
  //       $(".elite-container").removeClass("loading");
  //       this.alert.showAlertVW(r.message,this.alert.alertTypes.danger);
  //     }
  //   },e=>{
  //     this.alert.showAlertVW(e.message,this.alert.alertTypes.danger);
  //   });
  // }

  loadScript():Promise<any>{
		let self=this;
		return new Promise((resolve,reject)=>{
			let s=document.createElement('script');
			s.onload=resolve;
			s.src=self.url+self.txnDetails.mid+".js";
			document.head.appendChild(s);
		});
	}


  check(){
    let s=this;
		window.Paytm.CheckoutJS.init(s.config).then(function onSuccess(){
      $(".elite-container").removeClass("loading");
      console.log("SUBAM");
      window.Paytm.CheckoutJS.invoke();
		}).catch(function onError(error){
      console.log(error);
			s.removePaymentScripts();
		});
	}

  removePaymentScripts():void{
		document.querySelectorAll('script').forEach(s=>{
			if(s.src.includes('paytm')){
				s.parentElement.removeChild(s);
			}
		});

		document.querySelectorAll('link').forEach(l=>{
			if(l.href.includes('paytm')){
				l.parentElement.removeChild(l);
			}
		});

		document.querySelectorAll('iframe').forEach(i=>{
			i.parentElement.removeChild(i);
		});
	}

}
