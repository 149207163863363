import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {getApiUrl} from '../../../utils';

@Component({
  selector: 'app-offer-banner',
  templateUrl: './offer-banner.component.html',
  styleUrls: ['./offer-banner.component.css']
})
export class OfferBannerComponent implements OnInit {

  apiUrl:string;
  @Input()
    slider:any;
  constructor(public sanitizer:DomSanitizer) {
    this.apiUrl=getApiUrl();
  }

  ngOnInit(): void {
  }
}
