import { Injectable } from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { mergeMapTo } from 'rxjs/operators';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  cMessage:BehaviorSubject<string>=new BehaviorSubject('');
  constructor(private fmsg:AngularFireMessaging,private pfService:ProfileService) { 
    this.requestPermission=this.requestPermission.bind(this);
    this.listen=this.listen.bind(this);
  }

  requestPermission() {
    this.fmsg.requestPermission
      .pipe(mergeMapTo(this.fmsg.tokenChanges))
      .subscribe(
        (token) => { 
          // console.log('Permission granted! Save to the server!', token); 
          this.sentToServer(token);
          this.listen();
        },
        (error) => { 
          console.error(error); 
        },  
      );
  }

  
  listen() {
    this.fmsg.messages
      .subscribe((message:any) => { 
          // console.log(message); 
          new Notification(message.notification.title,message.notification);
      });
  }


  sentToServer(token){
    this.pfService.sendNotificationToken(token).subscribe(r=>{
      
      if(r.success)
        this.listen();
    });
  }

  unregister(){
    navigator.serviceWorker.getRegistrations().then(registrations=>{
      registrations.forEach(reg=>{
        reg.unregister();
        window.location.reload();
      });
    });
  }
  


}
