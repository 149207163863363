<section class="pt-3 pb-3 page-info section-padding border-bottom bg-white">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <a href="#"><strong><span class="mdi mdi-home"></span> Home</strong></a>
                <span class="mdi mdi-chevron-right"></span> <a href="#">Cart</a>
            </div>
        </div>
    </div>
</section>
<section class="cart-page section-padding">
    <div class="container" *ngIf="!carts || carts.length == 0">
        <div class="empty-message">
            <trash-svg></trash-svg>
            <h2>Your cart is empty</h2>
            <span>Looks like you haven't made any choice yet.</span>
        </div>
    </div>
    <div class="container">
        <div class="row" *ngIf="carts && carts.length != 0">
            <div class="col-md-12">
                <div class="card card-body cart-table mb-4" *ngFor="let cts of vendorCarts; let j = index">
                    <div class="shop-details p-3 font-weight-bold">
                        <span class="flex-fill">
                            Shop Name:
                            <span style="color: var(--color-primary)">{{cts.vendorDetails?.shopName | titlecase}}</span>
                        </span>
                        <!-- <span class="float-right">
                            Mobile :
                            <span style="color: var(--color-primary)">{{cts.vendorDetails?.mobile | titlecase}}</span>
                        </span> -->
                        <br>
                        <!-- <pre>{{cts.vendorDetails?.shopAddress | json}}</pre> -->
                    </div>
                    <div class="table-responsive">
                        <table class="table cart_summary shopping-cart-table table-borderless">
                            <thead>
                                <tr>
                                    <th class="cart_product">Product</th>
                                    <th>Description</th>
                                    <th>Avail.</th>
                                    <th>Unit price</th>
                                    <th>Qty</th>
                                    <th>Total</th>
                                    <th class="action">
                                        <i class="mdi mdi-delete-forever"></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor=" let cart of cts.carts;let i = index">
                                    <td class="cart_product">
                                        <!-- <div style="position: relative;"> -->
                                        <div class="product-image">
                                            <a [routerLink]="['/category/pv/'+cart.productId]">
                                                <img class="img-fluid" [src]="cart.productPicture && cart.productPicture[0] ? apiUrl +cart.productPicture[0]: 'assets/img/item/1.jpg'" alt="" /></a>
                                        </div>
                                    </td>
                                    <td class="cart_description">
                                        <h5 class="product-name">
                                            <a [routerLink]="['/category/pv/'+cart.productId]">{{ cart.productName }}
                                            </a>
                                        </h5>
                                        <h6>
                                            <strong><span class="mdi mdi-approval mr-1"></span>
                                            </strong>{{ cart.productUnit }} {{ cart.productScale }}
                                        </h6>
                                    </td>
                                    <td class="availability in-stock" [class.in-stock]="!cart.outOfStock" [class.out-of-stock]="cart.outOfStock">
                                        <label>Avail.:</label>
                                        <span class="badge badge-success" *ngIf="!cart.outOfStock">In stock</span>
                                        <span class="badge badge-primary" *ngIf="cart.outOfStock">No stock</span>
                                    </td>
                                    <td class="price">
                                        <label>Unit Price:</label>
                                        <span>{{ cart.pricePerQuantity }}</span>
                                    </td>
                                    <td class="qty" style="min-width: 141px">
                                        <label>Quantity:</label>
                                        <div class="input-group">
                                            <span class="input-group-btn"><button (click)="updateQuantity(cart.quantity - 1,i,j)" [disabled]="cart.quantity == 1 || cart.expired" class="btn btn-theme-round btn-number" type="button">-</button></span>
                                            <input type="number" readonly [attr.max]="cart.prodQuantity - cart.prodLowQuantity" min="1" [value]="cart.quantity" class="form-control border-form-control input-number p-2 border-0" name="quant[{{ i }}]" />
                                            <span class="input-group-btn"><button class="btn btn-theme-round btn-number" (click)="updateQuantity( cart.quantity + 1, i, j )" [disabled]=" cart.quantity == cart.quantity - cart.prodLowQuantity || cart.outOfStock || cart.expired" type="button">+</button>
                                            </span>
                                        </div>
                                    </td>
                                    <td class="price">
                                        <label>Total:</label>
                                        <span>{{ cart.totalPrice }}</span>
                                    </td>
                                    <td class="action">
                                        <button type="button" class="btn btn-sm btn-danger" (click)="removeFromCart(i, j)" data-original-title="Remove" title="" data-placement="top" data-toggle="tooltip">
                                            <i class="mdi mdi-close-circle-outline"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <!-- <tr>
                            <td colspan="1"></td>
                            <td colspan="4">
                               <form class="form-inline float-right">
                                  <div class="form-group">
                                     <input type="text" placeholder="Enter discount code" class="form-control border-form-control form-control-sm">
                                  </div>
                                  &nbsp;
                                  <button class="btn btn-success float-left btn-sm" type="submit">Apply</button>
                               </form>
                            </td>
                            <td colspan="2">Discount : $237.88 </td>
                         </tr> -->
                                <!-- <tr>
                            <td colspan="2"></td>
                            <td class="text-right" colspan="3">Total products (tax incl.)</td>
                            <td colspan="2">$437.88 </td>
                         </tr> -->
                                <tr>
                                    <!-- <td colspan="2"></td> -->
                                    <td class="text-right" colspan="6">
                                        Delivery Charge
                                        <!-- </td>
                                    <td colspan="2"> -->
                                        <span class="ml-3">{{ cts.deliveryCharge }}</span>
                                    </td>
                                    <td></td>

                                </tr>

                                <tr>
                                    <td class="text-right" colspan="6">
                                        <strong>Total</strong>
                                        <!-- </td>
                                    <td class="text-danger" colspan="2"> -->
                                        <strong class="ml-3 text-danger">{{
                                            cts.subTotal | currency: "INR"
                                            }}
                                        </strong>
                                    </td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <button [class.disabled]="cts.hasInvalidCarts || loading" (click)="goToCheckout(j)" class="btn btn-secondary btn-lg btn-block text-left" type="button">
                        <span class="float-left"><i class="mdi mdi-cart-outline"></i>
                            Proceed to
                            Checkout
                        </span>
                        <span class="float-right"><strong>{{
                                (cts.subTotal + cts.deliveryCharge) | currency: "INR"
                                }}</strong>
                            <span class="mdi mdi-chevron-right"></span>
                        </span>
                    </button>
                    <span class="error-message" *ngIf="cts.hasInvalidCarts && cts.errorMessage">{{cts?.errorMessage}}</span>

                </div>
                <!-- <div class="card mt-2">
                    <h5 class="card-header">My Cart (Design Two)<span class="text-secondary float-right">(5 item)</span></h5>
                    <div class="card-body pt-0 pr-0 pl-0 pb-0">
                        <div class="cart-list-product">
                            <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
                            <img class="img-fluid" src="assets/img/item/11.jpg" alt="">
                            <span class="badge badge-success">50% OFF</span>
                            <h5><a href="#">Product Title Here</a></h5>
                            <h6><strong><span class="mdi mdi-approval mr-1"></span> Available in</strong> - 500 gm</h6>
                            <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i> <span class="regular-price">$800.99</span></p>
                        </div>
                        <div class="cart-list-product">
                            <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
                            <img class="img-fluid" src="assets/img/item/1.jpg" alt="">
                            <span class="badge badge-success">50% OFF</span>
                            <h5><a href="#">Product Title Here</a></h5>
                            <h6><strong><span class="mdi mdi-approval mr-1"></span> Available in</strong> - 500 gm</h6>
                            <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i> <span class="regular-price">$800.99</span></p>
                        </div>
                        <div class="cart-list-product">
                            <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
                            <img class="img-fluid" src="assets/img/item/2.jpg" alt="">
                            <span class="badge badge-success">50% OFF</span>
                            <h5><a href="#">Product Title Here</a></h5>
                            <h6><strong><span class="mdi mdi-approval mr-1"></span> Available in</strong> - 500 gm</h6>
                            <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i> <span class="regular-price">$800.99</span></p>
                        </div>
                    </div>
                    <div class="card-footer cart-sidebar-footer">
                        <div class="cart-store-details">
                            <p>Sub Total <strong class="float-right">$900.69</strong></p>
                            <p>Delivery Charges <strong class="float-right text-danger">+ $29.69</strong></p>
                            <h6>Your total savings <strong class="float-right text-danger">$55 (42.31%)</strong></h6>
                        </div>
                        <a href="checkout.html"><button class="btn btn-secondary btn-lg btn-block text-left" type="button"><span class="float-left"><i class="mdi mdi-cart-outline"></i> Proceed to Checkout </span><span class="float-right"><strong>$1200.69</strong> <span class="mdi mdi-chevron-right"></span></span></button></a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>