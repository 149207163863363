<section class="custom-page-wrap clearfix">
    <div class="container">
        <div class="custom-page-content clearfix">
            <div class=WordSection1>

                <p class=MsoBodyText style='margin-top:2.05pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>Privacy Policy</p>

                <p class=MsoBodyText style='margin-top:9.05pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>Effective date: October 00, 2020</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.45pt'><span style='font-size:14.5pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:.05pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>Tokoshope (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates<span style='letter-spacing:-.95pt'> </span>the<span style='letter-spacing:-.15pt'> </span><a href="https://tokoshope.com/">https://tokoshope.com/</a> website and
                    the<span style='letter-spacing:-.25pt'> </span>FP</p>

                <p class=MsoBodyText style='margin-top:1.05pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>mobile application (the &quot;Service&quot;).
                </p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.45pt'><span style='font-size:14.5pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:5.2pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;text-align:justify;line-height:
                107%'>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. Our Privacy Policy Tokoshope is managed through
                    Free Privacy Policy.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:13.85pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy
                    have the same meanings as in our Terms and Conditions.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.35pt'><span style='font-size:13.5pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:.05pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>Information Collection And Use</p>

                <p class=MsoBodyText style='margin-top:9.05pt;margin-right:34.4pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:366.6pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;text-align:justify;line-height:
                167%'>Types of Data Collected Personal Data</p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:16.45pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;text-align:justify;line-height:
                107%'>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (&quot;Personal Data&quot;). Personally, identifiable information may include, but is not
                    limited to:</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.5pt'><span style='font-size:13.5pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-left:5.0pt'>Email address</p>

                <p class=MsoBodyText style='margin-top:9.1pt;margin-right:331.9pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:167%'>First name and last name Phone number</p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:265.95pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:167%'>Address, State, Province, ZIP/Postal code, City Cookies and Usage Data</p>

                <p class=MsoBodyText style='margin-left:5.0pt;line-height:13.3pt'>Usage Data</p>

                <p class=MsoBodyText style='margin-top:9.0pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>Bank Account details for vendors only
                </p>

            </div>

            <span style='font-size:11.0pt;font-family:"Calibri",sans-serif'><br clear=all
                style='page-break-before:always'>
                </span>

            <div class=WordSection2>

                <p class=MsoBodyText style='margin-top:2.05pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device (&quot;Usage Data&quot;).
                </p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:21.2pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those
                    pages, unique device identifiers and other diagnostic data.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:4.85pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>When you access the Service by or through a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system,
                    the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-left:5.0pt'>Tracking &amp; Cookies Data</p>

                <p class=MsoBodyText style='margin-top:9.05pt;margin-right:21.3pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:105%'>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.3pt'><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:8.9pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;text-align:justify;line-height:
                107%'>Cookies<span style='letter-spacing:-.15pt'> </span>are<span style='letter-spacing:-.1pt'> </span>files<span style='letter-spacing:-.15pt'> </span>with<span style='letter-spacing:.1pt'> </span>small<span style='letter-spacing:-.15pt'> </span>amount
                    <span
                        style='letter-spacing:-.1pt'> </span>of<span style='letter-spacing:-.25pt'> </span>data<span style='letter-spacing:-.15pt'> </span>which<span style='letter-spacing:-.2pt'> </span>may<span style='letter-spacing:-.15pt'> </span>include<span style='letter-spacing:-.15pt'>
                </span>an<span style='letter-spacing:-.2pt'> </span>anonymous<span style='letter-spacing:-.2pt'> </span>unique<span style='letter-spacing:-.15pt'>
                </span>identifier.<span style='letter-spacing:-.2pt'> </span>Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and
                        to improve and analyze our<span style='letter-spacing:-1.1pt'> </span>Service.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:11.05pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;text-align:justify;line-height:
                107%'>You<span style='letter-spacing:-.15pt'> </span>can<span style='letter-spacing:
                -.15pt'> </span>instruct<span style='letter-spacing:-.05pt'> </span>your<span style='letter-spacing:-.2pt'> </span>browser<span style='letter-spacing:-.15pt'>
                </span>to<span style='letter-spacing:.1pt'> </span>refuse<span style='letter-spacing:-.1pt'> </span>all<span style='letter-spacing:-.1pt'> </span>cookies<span style='letter-spacing:-.2pt'> </span>or<span style='letter-spacing:-.2pt'> </span>to
                    <span
                        style='letter-spacing:-.1pt'> </span>indicate<span style='letter-spacing:-.1pt'>
                </span>when<span style='letter-spacing:-.15pt'> </span>a<span style='letter-spacing:
                -.15pt'> </span>cookie<span style='letter-spacing:-.1pt'> </span>is<span style='letter-spacing:-.15pt'> </span>being<span style='letter-spacing:-.05pt'>
                </span>sent.<span style='letter-spacing:-.15pt'> </span>However, if you do not accept cookies, you may not be able to use some portions of our<span style='letter-spacing:-.9pt'> </span>Service.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-left:5.0pt'>Examples of Cookies we use:</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.45pt'><span style='font-size:14.5pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-left:5.0pt'>Session Cookies. We use Session Cookies to operate our<span style='letter-spacing:-.9pt'> </span>Service.</p>

                <p class=MsoBodyText style='margin-top:9.1pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:167%'>Preference Cookies. We use Preference Cookies to remember your preferences and various<span style='letter-spacing:-1.6pt'> </span>settings. Security Cookies. We use Security Cookies for security<span style='letter-spacing:-.35pt'> </span>purposes.</p>

                <p class=MsoBodyText style='margin-left:5.0pt;line-height:13.35pt'>Use of Data</p>

                <p class=MsoBodyText style='margin-top:9.05pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>Tokoshope uses the collected data for various purposes:</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.45pt'><span style='font-size:14.5pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-left:5.0pt'>To provide and maintain the Service
                </p>

            </div>

            <span style='font-size:11.0pt;font-family:"Calibri",sans-serif'><br clear=all
                style='page-break-before:always'>
                </span>

            <div class=WordSection3>

                <p class=MsoBodyText style='margin-top:2.05pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>To notify you about changes to our Service
                </p>

                <p class=MsoBodyText style='margin-top:9.05pt;margin-right:58.05pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:167%'>To allow you to participate in interactive features of our Service when you choose to do so To provide customer care and support</p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:117.3pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:167%'>To provide analysis or valuable information so that we can improve the Service To monitor the usage of the Service</p>

                <p class=MsoBodyText style='margin-left:5.0pt;line-height:13.3pt'>To detect, prevent and address technical issues</p>

                <p class=MsoBodyText style='margin-top:9.0pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>our consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:4.85pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>Tokoshope will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country
                    unless there are adequate controls in place including the security of your data and other personal information.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.35pt'><span style='font-size:13.5pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:382.55pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:167%'>Disclosure Of Data Legal Requirements</p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:39.25pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>Tokoshope may disclose your Personal Data in the good faith belief that such action is necessary to:</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.5pt'><span style='font-size:13.5pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-left:5.0pt'>To comply with a legal obligation
                </p>

                <p class=MsoBodyText style='margin-top:9.1pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>To protect and defend the rights or property of Tokoshope</p>

                <p class=MsoBodyText style='margin-top:9.05pt;margin-right:117.3pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:167%'>To prevent or investigate possible wrongdoing in connection with the Service To protect the personal safety of users of the Service or the public</p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:297.65pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:167%'>To protect against legal liability Security Of Data</p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:13.85pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal
                    Data, we cannot guarantee its absolute security.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.45pt'><span style='font-size:13.5pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-left:5.0pt'>Service Providers</p>

            </div>

            <span style='font-size:11.0pt;font-family:"Calibri",sans-serif'><br clear=all
                style='page-break-before:always'>
                </span>

            <div class=WordSection4>

                <p class=MsoBodyText style='margin-top:2.05pt;margin-right:6.7pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>We may employ third party companies and individuals to facilitate our Service (&quot;Service Providers&quot;), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-left:5.0pt;line-height:107%'>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-left:5.0pt'>Analytics</p>

                <p class=MsoBodyText style='margin-top:9.05pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.45pt'><span style='font-size:14.5pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:.05pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>Google Analytics</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.4pt'><span style='font-size:14.5pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:.05pt;margin-right:6.7pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:106%'>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may
                    use the collected data to contextualize and personalize the ads of its own advertising network.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.25pt'><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-left:5.0pt;line-height:107%'>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page: https://policies.google.com/privacy?hl=en</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-left:5.0pt'>Firebase</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.45pt'><span style='font-size:14.5pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:.05pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>Firebase is an analytics service provided by Google Inc.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.4pt'><span style='font-size:14.5pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:.05pt;margin-right:15.55pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy:
                    <a href="https://policies.google.com/privacy?hl=en"><span style='color:#0462C1'>https://policies.google.com/privacy?hl=en</span></a>
                </p>

                <p class=MsoBodyText><span style='font-size:10.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText><span style='font-size:10.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText><span style='font-size:10.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:.15pt'>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:2.8pt;margin-right:48.15pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>We also encourage you to review the Google's policy for safeguarding your data: <a href="https://support.google.com/analytics/answer/6004245"><span
                style='color:#0462C1'>https://support.google.com/analytics/answer/6004245</span><span
                style='color:windowtext;text-decoration:none'>. </span></a>For more information on what type of information Firebase collects, please visit please visit the Google Privacy &amp; Terms web page:
                    <a href="https://policies.google.com/privacy?hl=en"><span style='color:#0462C1'>https://policies.google.com/privacy?hl=en</span></a>
                </p>

            </div>

            <span style='font-size:11.0pt;line-height:107%;font-family:"Calibri",sans-serif'><br
                clear=all style='page-break-before:always'>
                </span>

            <div class=WordSection5>

                <p class=MsoBodyText style='margin-top:2.05pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>Links To Other Sites</p>

                <p class=MsoBodyText style='margin-top:9.05pt;margin-right:7.3pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:18.5pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-left:5.0pt'>Children's Privacy</p>

                <p class=MsoBodyText style='margin-top:9.1pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>Our Service does not address anyone under the age of 18 (&quot;Children&quot;).</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.45pt'><span style='font-size:14.5pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:4.85pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:106%'>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware
                    that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText style='margin-top:.3pt'><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-left:5.0pt'>Changes To This Privacy Policy</p>

                <p class=MsoBodyText style='margin-top:9.05pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:10.15pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the &quot;effective date&quot; at the top of this Privacy Policy.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-top:0in;margin-right:10.2pt;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

                <p class=MsoBodyText>&nbsp;</p>

                <p class=MsoBodyText><span style='font-size:14.0pt'>&nbsp;</span></p>

                <p class=MsoBodyText style='margin-left:5.0pt'>Contact Us</p>

                <p class=MsoBodyText style='margin-top:9.1pt;margin-right:0in;margin-bottom:
                0in;margin-left:5.0pt;margin-bottom:.0001pt'>If you have any questions about this Privacy Policy, please contact us:</p>
            </div>

        </div>
    </div>
</section>