import { Component, OnInit } from '@angular/core';
import {AlertService} from 'src/app/services/alert.service';
@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  alert:any;
  alertTypes:any;
  constructor(private alertService:AlertService) { }
  ngOnInit(): void {
      this.alertTypes=this.alertService.alertTypes;
      this.alertService.alert.subscribe(value=>{
        //console.log("HELLO ALERT");
        this.alert=value;
      });
  }

}
