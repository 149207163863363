import { Directive } from '@angular/core';

@Directive({
  selector: '[appNiceSelect]'
})
export class NiceSelectDirective {

  constructor() { }

}
