import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { NetworkService } from './network.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private myCarts:any[]=[];
  private vendorCarts:any[]=[];
  private subTotal:any=0;
  public hasInvalidCarts:Boolean=false;
  public currentVendorCart:any=null;
  public cartCount:BehaviorSubject<any>=new BehaviorSubject(this.myCarts.length);
  constructor(private http:HttpClient) {}

  getMyCarts():Observable<any>{
    console.log('getMyCarts called');
    
    return this.http.get(NetworkService.myCarts(), { headers: NetworkService.getAuthHeader() }).pipe(map((r: any) => {
      this.myCarts = r.payload.carts || [];
      this.subTotal= r.payload.subTotal || 0;
      this.hasInvalidCarts=r.payload.hasInvalidCarts || false;
      this.getVendorWiseCarts().subscribe(re=>{
        this.cartCount.next([this.myCarts.length,this.subTotal]);
      });
      return r;
    }));
  }

  getVendorWiseCarts():Observable<any>{
    return this.http.post(NetworkService.vendorWiseCarts(),{}, { headers: NetworkService.getAuthHeader() }).pipe(map((r: any) => {
      // this.myCarts = r.payload.carts || [];
      // this.subTotal= r.payload.subTotal || 0;
      // this.hasInvalidCarts=r.payload.hasInvalidCarts || false;
      // this.cartCount.next([this.myCarts.length,this.subTotal]);
      // return r;
      this.vendorCarts=r.payload.carts || [];
      // console.log(`is have this.currentVendorCart`, this.currentVendorCart);
      
      if(!this.currentVendorCart)
        this.currentVendorCart=this.vendorCarts[0];
      return r;
    }));
  }

  getLocalCarts():any{
    return {carts:this.myCarts,subTotal:this.subTotal};
  }

  getVendorLocalCarts():any{
    return this.vendorCarts;
  }
  addToCart(obj:any):Observable<any>{
    return this.http.post(NetworkService.addToCart(),obj,{headers:NetworkService.getAuthHeader()}).pipe(map(r=>{
      this.getMyCarts().subscribe(res=>{
        //console.log(res)
      });

      return r;
    }))
  }

  removeFromCart(cartId):Observable<any>{
    return this.http.post(NetworkService.removeFromCart(),{"_id":cartId},{headers:NetworkService.getAuthHeader()}).pipe(map(r=>{
      this.getMyCarts().subscribe(res=>{
      //  console.log(res)
      });

      return r;
    }));
  }

  clearCart():Observable<any>{
    return this.http.post(NetworkService.clearCart(),{},{headers:NetworkService.getAuthHeader()}).pipe(map(r=>{
      this.getMyCarts().subscribe(res=>{
      //  console.log(res)
      });
      return r;
    }));
  }

  updateCart(obj):Observable<any>{
    return this.http.post(NetworkService.updateCart(),obj,{headers:NetworkService.getAuthHeader()}).pipe(map(r=>{
      this.getMyCarts().subscribe(res=>{
        // console.log(res)
      });
      return r;
    }));
  }

}
