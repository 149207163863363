<section class="pt-3 pb-3 page-info section-padding border-bottom bg-white">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <a routerLink=""><strong><span class="mdi mdi-home"></span> Home</strong></a> <span class="mdi mdi-chevron-right"></span> <a href="javascript:void(0)">Checkout</a>
            </div>
        </div>
    </div>
</section>
<section class="checkout-page section-padding" [class.loading]="loading">
    <div class="container" *ngIf="!loading && vendorCart">
        <span class="error-message">{{vendorCart?.errorMessage}}</span>
        <div class="row">
            <div class="col-md-8">
                <div class="checkout-step">
                    <div class="accordion" id="accordionExample">
                        <div class="card checkout-step-one">
                            <div class="card-header" id="headingOne">
                                <h5 class="mb-0">
                                    <!-- <button class="btn btn-link" type="button" data-toggle="collapse" [attr.data-target]="isAddressChecked ? '#collapseOne' : ''" aria-expanded="true" aria-controls="collapseOne"> -->
                                    <span class="number"></span> Delivery Address
                                    <!-- </button> -->
                                </h5>
                            </div>
                            <!-- <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample"> -->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6 team-card text-center">
                                        <img [src]="profile.picture" height="100px" alt="profile-pic">
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="control-label">Name <span class="required">*</span></label>
                                            <input class="form-control border-form-control" [value]="profile.name" disabled placeholder="" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="control-label">Phone <span class="required">*</span></label>
                                            <input class="form-control border-form-control" [value]="profile.mobile" disabled placeholder="" type="number">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="control-label">Email Address <span class="required">*</span></label>
                                            <input class="form-control border-form-control " [value]="profile.email" disabled="" type="email">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="control-label">Street Address <span class="required">*</span></label>
                                            <input name="addressLine1" id="billing-address-1" placeholder="" class="form-control" [value]="currentAddress.addressLine | titlecase" disabled>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="control-label">Locality <span class="required">*</span></label>
                                            <input name="country" id="billing-locality" class="form-control" [value]="currentAddress.locality" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="control-label">Country <span class="required">*</span></label>
                                            <input name="country" id="billing-country" class="form-control" [value]="currentAddress.country" disabled>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="control-label">District <span class="required">*</span></label>
                                            <input name="country" id="billing-district" class="form-control" [value]="currentAddress.district" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="control-label">Zip Code <span class="required">*</span></label>
                                            <input class="form-control border-form-control" [value]="currentAddress.pinCode" disabled type="number">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="control-label">State <span class="required">*</span></label>
                                            <input name="country" id="billing-state" class="form-control" [value]="currentAddress.state | titlecase" disabled>
                                        </div>
                                    </div>
                                </div>

                                <!-- <button type="button" (click)="isAddressChecked=true" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" class="btn btn-secondary mb-2 btn-lg">NEXT</button> -->

                            </div>
                            <!-- </div> -->
                        </div>

                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <h5 class="mb-0">
                                    <!-- <button class="btn btn-link collapsed" type="button" data-toggle="collapse" [attr.data-target]="isPaymentMethodChecked ? '#collapseThree' : '' " aria-expanded="false" aria-controls="collapseThree"> -->
                                    <span class="number"></span> Wallet
                                    <!-- </button> -->
                                </h5>
                            </div>
                            <!-- <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample"> -->
                            <div class="card-body">
                                <div class="checkout-right">
                                    <div>
                                        <input [(ngModel)]="applyToWallet" type="number" [max]="profile.walletAmount" class="border-form-control form-control" placeholder="Enter amount to apply of wallet">
                                        <ng-container *ngIf="(profile.walletAmount >= applyToWallet && !walletError); else invalidWalletAmt">
                                            <span class="error-message text-secondary" *ngIf="applyToWallet == null || applyToWallet == 0">Wallet Amount {{profile.walletAmount | currency:"INR"}}</span>
                                            <span class="error-message text-success" *ngIf="applyToWallet && applyToWallet != 0">Balance Amount in Wallet {{(profile.walletAmount - applyToWallet) | currency:"INR"}}</span>
                                        </ng-container>
                                        <ng-template #invalidWalletAmt>
                                            <span class="error-message" *ngIf="profile.walletAmount < applyToWallet || walletError">
                                                {{walletError?walletError: 'Invalid Amount to apply'}}
                                            </span>
                                        </ng-template>
                                    </div>

                                    <button type="button" [class.disabled]="profile.walletAmount < 1 || profile.walletAmount < applyToWallet" [class.btn-loading]="walletLoading" (click)="applyWallet();" class="btn btn-secondary disabled my-2 w-auto">Apply Wallet</button>
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>

                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <h5 class="mb-0">
                                    <!-- <button class="btn btn-link collapsed" type="button" data-toggle="collapse" [attr.data-target]="isPaymentMethodChecked ? '#collapseThree' : '' " aria-expanded="false" aria-controls="collapseThree"> -->
                                    <span class="number"></span> Payment Method
                                    <!-- </button> -->
                                </h5>
                            </div>
                            <!-- <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample"> -->
                            <div class="card-body">
                                <div class="checkout-right">

                                    <div class="payment-method mb-2" *ngIf="pm?.paytm || pm?.cashOn || pm?.razorPay">
                                        <!-- <h4 class="title">Payment Method</h4> -->
                                        <div class="payment-method-form">
                                            <div class="form-group" (change)="onChange($event)">
                                                <div class="form-radio" *ngIf="pm?.cashOn">
                                                    <input type="radio" name="form.payment_method" id="cod" value="CASH_ON_DELIVERY" [attr.checked]="paymentMethod=='CASH_ON_DELIVERY'">
                                                    <label for="cod">Cash On Delivery</label>
                                                    <span class="helper-text">Pay with cash upon delivery.</span>
                                                </div>

                                                <div class="form-radio" *ngIf="pm?.paytm">
                                                    <input type="radio" name="form.payment_method" id="paytm" value="paytm" [attr.checked]="paymentMethod=='paytm'">
                                                    <label for="paytm">Pay With Paytm</label>
                                                    <span class="helper-text">
                                                      Make your payment online via Paytm.
                                                </span>
                                                </div>
                                                <div class="form-radio" *ngIf="pm?.razorPay">
                                                    <input type="radio" name="form.payment_method" id="razor" value="razor" [attr.checked]="paymentMethod=='razorPay'">
                                                    <label for="razor">Pay With RazorPay</label>
                                                    <span class="helper-text">
                                                      Make your payment online via RazorPay.
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" [class.disabled]="vendorCart.hasInvalidCarts" [class.btn-loading]="loading" (click)="placeOrder(); isPaymentMethodChecked=true" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"
                                        class="btn btn-secondary mb-2 btn-lg">Place Order</button>
                                    <span class="error-message">During the Payment, If you close or refresh the page, then payment will be failed. </span>
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>

                        <div class="card" *ngIf="orderStatus != null || orderFailed != null">
                            <div class="card-header" id="headingFour">
                                <h5 class="mb-0">
                                    <!-- <button class="btn btn-link collapsed" type="button" data-toggle="collapse" [attr.data-target]="isOrderPlaced ? '#collapseFour' : '' " aria-expanded="false" aria-controls="collapseFour"> -->
                                    <span class="number"></span> Order Status
                                    <!-- </button> -->
                                </h5>
                            </div>
                            <!-- <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample"> -->
                            <div class="card-body" *ngIf="!orderLoading">
                                <div class="text-center" [class.loading]="orderLoading">
                                    <div class="col-lg-10 col-md-10 mx-auto order-done" *ngIf="this.isOrderPlaced">
                                        <i class="mdi mdi-check-circle-outline text-secondary" *ngIf="referenceNo != null"></i>
                                        <h4 class="text-success" *ngIf="orderStatus !=  null">{{orderStatus}}</h4>
                                        <p *ngIf="referenceNo != null">
                                            Your order ID is: <b>{{referenceNo}} </b>
                                        </p>
                                    </div>
                                    <div class="text-center" *ngIf="this.isOrderPlaced">
                                        <a routerLink="/"><button type="submit" class="btn btn-secondary mb-2 btn-lg">Return to store</button></a>
                                    </div>
                                    <div class="col-lg-10 col-md-10 mx-auto order-done" *ngIf="this.isOrderPlaced">
                                        <h4 class="text-danger" *ngIf="orderFailed !=  null">{{orderFailed}}</h4>
                                    </div>
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <h5 class="card-header px-3">Order Summary <span class="text-secondary float-right">({{vendorCart.numberOfItems}} item)</span></h5>
                    <div class="card-body pb-0 pt-0 px-3">
                        <div class="order-summary-top border-0">
                            <ul class="list-inline cart-item">
                                <li *ngFor="let prod of vendorCart?.carts;let i=index;">
                                    <a href="javascript:void(0);" class="product-name">
                                       {{(prod.productName | titlecase)+" X "+ prod.quantity}}
                                 </a>
                                    <span class="price-amount">{{(prod.quantity * prod.pricePerQuantity) | currency:"INR"}}</span>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div class="card-footer px-3 text-body">
                        <div class="order-summary-middle">
                            <ul class="list-inline order-summary-list">
                                <li><label>Subtotal</label> <span class="price-amount">{{vendorCart?.subTotal | currency:"INR"}}</span></li>
                                <li><label>Delivery Charge</label> <span class="price-amount">{{deliveryCharge | currency:"INR"}}</span></li>
                                <li *ngIf="isWalletApplicable"><label>Wallet</label> <span class="price-amount">(-) {{applyToWallet | currency:"INR"}}</span></li>
                                <!---->
                            </ul>
                            <div class="order-summary-total h-4">
                                <label>Total</label>
                                <span *ngIf="!isWalletApplicable" class="total-price">{{vendorCart?.subTotal+deliveryCharge | currency:"INR"}}</span>
                                <span *ngIf="isWalletApplicable" class="total-price">{{(vendorCart?.subTotal+deliveryCharge)-applyToWallet | currency:"INR"}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>