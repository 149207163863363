import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  public search:BehaviorSubject<boolean>=new BehaviorSubject(false);
  public searchTxt="";


  initialState={
    _id:null
  };
  currentSearchItem:any=this.initialState;
  constructor() {}

  clearSearchItem(){
    this.currentSearchItem=this.initialState;
  }

  getSearchItem(){
    // console.log('getSearchItem called', this.currentSearchItem);
    
    return this.currentSearchItem;
  }

  setSearchItem(obj){
    this.currentSearchItem=obj;
    console.log('setSearchItem called', this.currentSearchItem);

  }
  
  getDropDownFilters(){
    return {
      "newToOld":{
        title:"New To Old",
        sortBy:"createdAt",
        order:-1
      },
      "oldToNew":{
        title:"Old To New",
        sortBy:"createdAt",
        order:1
      },
      "alphabetic":{
          title:"Alphabetic",
          sortBy:"name",
          order:1
       },
       "deAlphabetic":{
        title:"DeAlphabetic",
        sortBy:"name",
        order:-1
      }, 
       "priceLowToHigh":{
           title:"Price: Low To High",
           sortBy:"price",
           order:1
       },
       "priceHighToLow":{
           title:"Price: High To Low",
           sortBy:"price",
           order:-1
       }
    };
  }

  

}
