import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortString'
})
export class ShortStringPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    let v:string[]=value.split("\ ");
    if(v.length>3){
      return v.slice(0,3).join("\ ")+"...";
    }else{
      return v.join("\ ");
    } 
  }
}
