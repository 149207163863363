import { Component, OnInit,AfterViewInit, OnDestroy } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import {DataService} from 'src/app/services/data.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import {AlertService} from 'src/app/services/alert.service';
import {GeneralService} from 'src/app/services/general.service';
import {getApiUrl} from 'src/app/utils';
import { CartService } from 'src/app/services/cart.service';
import { ReviewService } from 'src/app/services/review.service';
import { BehaviorSubject } from 'rxjs';
declare var $:any;

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  avgRating:number|string;
  url:string;
  catId:any=null;
  // public product:any=null;
  // public product:BehaviorSubject<any>=new BehaviorSubject(null);

  loggedIn:boolean = false;
  title="Category";
  
  apiUrl=getApiUrl();
  mySubscription: any;  
  products:any[]=[];
  prodViewType:string="grid";
  
  selectedLength:number=10;

  dropDownFilters:any;
  selectedDdFilter:any;
  page:number=1;
  totalLength:number=0;
 // pageOffSet:number=0;
  currentShowingPages:number[];
  currentType:any={
      _id:null
  };
  getArray:Function;
  loading:Boolean=true;
  // priceRange:any=[0,200000];
  searchTxt:string="";

  quantity:number=1;

  profile:any=null;
  settings:any={};

  rmt:number=3;
  requestBody:any;

  constructor(private pdService:ProductsService,
      private dService:DataService,
      private router:Router,
      private pfService:ProfileService,
      private wlService:WishlistService,
      private alert:AlertService,
      private gnl:GeneralService,
      private ctS:CartService
  ) {
      let str = document.URL;    
      this.catId = str.substring(str.lastIndexOf('/') + 1);
      this.currentType = JSON.parse(localStorage.getItem('currentCategory'));
      // console.log(this.currentType);

      if(this.catId == '' || this.catId == null || this.currentType == null){
        this.router.navigate(['/']);
        return;
      } 

      this.dropDownFilters=this.dService.getDropDownFilters();
      
    
      this.selectedDdFilter=this.dropDownFilters['newToOld'];
      this.searchTxt=this.dService.searchTxt;
      
      this.pdService.getRequestBody().subscribe(response => {
        this.requestBody = response;
      })
  
      this.pdService.getProducts().subscribe(response => {
        // console.log(response);
        this.products = response.products;
        this.totalLength = response.totalLength;
        this.loading=response.loading;
        setTimeout(() => {
          this.productsListColumnSetup();
        }, 10)
    
      })

      this.loggedIn=this.pfService.isUserLoggedIn();
      // console.log(this.loggedIn);     
  
      this.profile=this.pfService.getLocalProfile();
      // console.log(this.profile);
      
      this.settings=this.gnl.settings;

      this.gnl.getSettings().subscribe(r=>{
        this.settings=r.payload && r.payload.settings || {};
        if(this.settings && this.settings.paymentMethod){
          let {paymentMethod:{cashOn,razorPay,paytm}}=this.settings;
          if(!cashOn && !razorPay && !paytm){
            this.rmt=0;
          }
        }
      });
      
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.mySubscription = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.router.navigated = false;
        }
      });
      
      // console.table(this.settings);
  }

  ngOnInit(): void {
    this.getTotalLength();
  }

  ngAfterViewInit():void{
    // this.loading = false;

  }

  productsListColumnSetup(){
    if ($(window).width() > 620) {
      $('.product-header img').height('230px');
    } else {
        $('.product-header img').height('inherit');
        $('.product-body h5').css('font-size', 'revert');
        $('.product-header').height('140px');
    }
  }

  ngOnDestroy():void{
    console.log('ngdestroy called');
    
    this.dService.searchTxt="";
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
  
  getTotalLength():void{  
    // console.log(this.getRequestBody());
      this.pdService.totalProduct(this.getRequestBody()).subscribe(r=>{
        // console.log(r);
        this.totalLength= (r.payload && r.payload.totalCount) || 0;
        // console.log(this.totalLength);
      },err=>{
        this.totalLength=0;
        // console.log(err.message);
      });
  }

  addToCart(productId:string,priceId:string):void{
    // console.log("ADDING TO CART");
    if(!this.pfService.isUserLoggedIn()){
      $('#login-signup-open-btn').click();
      return; 
    }

    this.ctS.addToCart({quantity:1,productId,priceId}).subscribe(r=>{
      if(r.success)
        $('body').toggleClass('toggled');                
      else
      this.alert.showAlertVW(r.message,this.alert.alertTypes.danger);  
    },e=>{
      this.alert.showAlertVW(e.message,this.alert.alertTypes.danger);
    })
  }

  showPage(){
    if((((this.page-1)*this.selectedLength)+this.selectedLength)>this.totalLength){
      return `Showing ${((this.page-1)*this.selectedLength)+1}-${this.totalLength} of ${this.totalLength} results`
    }else{
      return `Showing ${((this.page-1)*this.selectedLength)+1}-${((this.page-1)*this.selectedLength)+this.selectedLength} of ${this.totalLength} results`;
    }
  }

  getRequestBody():any{
    delete this.requestBody .title;

    if(this.searchTxt)
      this.requestBody .searchTxt=this.searchTxt;
    this.requestBody .size=parseInt(this.requestBody .size);
    
    return this.requestBody ;
  }

  getProducts(){
    this.getTotalLength();
      this.pdService.setRequestBody(this.requestBody);

      this.loading=true;
      this.pdService.searchProduct(this.getRequestBody()).subscribe(r=>{
          if(!r.success)
            this.alert.showAlertVW(r.message,this.alert.alertTypes.danger);
          this.products=r.payload.products && r.payload.products.length!=0?r.payload.products:[];
          this.loading=false;
          setTimeout(() => {
            this.productsListColumnSetup();
          }, 0)
      },err=>{
          this.products=[];
          this.loading=false;
          this.alert.showAlertVW(err.message,this.alert.alertTypes.danger);
      });
  }

  changeWishList(p,i){
    if(!this.pfService.isUserLoggedIn()){
      $('#login-signup-open-btn').click();
      return;
    }
    if(!p.isFavourite){
      this.wlService.addToWishList(p._id).subscribe(r=>{
        if(r.code=='ECM003')
          this.products[i].isFavourite=true;
      });
    }else{
      this.wlService.removeFromWishList(p._id).subscribe(r=>{
        if(r.code=='ECM009')
          this.products[i].isFavourite=false;
      });
    }  
  }

  onSortBy(e):void{
    this.selectedDdFilter=this.dropDownFilters[e];
    Object.assign(this.requestBody, this.selectedDdFilter);
    // console.log(this.requestBody);
    this.getProducts();
  }

  selectLength(e):void{
    //console.log("Selected Length-->",e);
    this.selectedLength=parseInt(e);
    this.requestBody.size = parseInt(e);
    this.requestBody.page=1;
    this.page=1;
   // this.pageOffSet=0;
    this.getProducts();
  }

  changeView(val){
      this.prodViewType=val;      
  }

  setPage(opt){
      //console.log(opt);
      if(opt=='INC'){
        this.page=this.page+1;
      }else{
        this.page=this.page-1;
      }
      this.requestBody.page=this.page;
      this.getProducts();
  }
  
  setCurrentPage(p){
    //console.log(p);
    this.page=p;
    this.requestBody.page=p;
    this.getProducts();
  }

  isNew(createdAt):boolean{
    return Date.now() - new Date(createdAt).getTime() <=24*60*60*30*1000;
  }

  getPageArray():any[]{
    let pa=[];
    let lp=Math.ceil(this.totalLength/this.selectedLength);
    let fp=1;
    if(Math.abs(this.page-fp)<3){
      if(lp<=6){
        for(let i=1;i<=lp;i++){
          pa.push(i);
        }  
      }else{
        pa=[1,2,3,4,'...',lp];
      }
    }else if(Math.abs(this.page-lp)<3){
      pa=[1,'...',lp-3,lp-2,lp-1,lp];
    }else if(lp<=6){
      for(let i=1;i<=lp;i++){
        pa.push(i);
      }
    }else{
      pa=[1,'...',this.page-1,this.page,this.page+1,'...',lp];
    }
    return pa;
  }
  
  isLastPage():boolean{
    return this.page==Math.ceil(this.totalLength/this.selectedLength)
  }

  hasPaginate():boolean{
    return Math.ceil(this.totalLength/this.selectedLength)>1;
  }

}
    