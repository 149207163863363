<section class="shop-single section-padding pt-3">
    <div class="container">
        <div class="row" *ngIf="product && product != null">
            <div class="col-md-6">
                <div class="shop-detail-left">
                    <div class="shop-detail-slider">
                        <!-- <div class="favourite-icon">
                            <a class="fav-btn" title="" data-placement="bottom" data-toggle="tooltip" href="#" data-original-title="59% OFF"><i class="mdi mdi-tag-outline"></i></a>
                        </div> -->

                        <div class="owl-carousel owl-carousel-product-ra" [ngClass]="{'loading': loading == true}">
                            <div class="item" *ngFor="let pic of product.pictures; let i = index;">
                                <img alt="product image" iLoader [src]="apiUrl + pic" class="img-fluid img-center w-auto mx-auto d-block">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 product-details-info">
                <div class="shop-detail-right">
                    <div class="product-card-actions float-right">
                        <button title="Wishlist" class="btn btn-wishlist p-0 my-2 mx-0" (click)="changeWishList()">
                            <i [class]="product.isFavourite?'mdi mdi-heart':'mdi mdi-heart-outline'"
                                [style.color]="product.isFavourite?'red':'black'"></i>
                            Wishlist
                        </button>
                    </div>

                    <!-- <span class="" [dTag]="vendor"></span> -->
                    <h2>{{product.name}}</h2>

                    <!-- <div class="product-price">
                        <span >{{(vendor.offerPrice || vendor.price) | formatPrice  }}</span>
                        <span class="previous-price" >{{vendor.retailPrice | formatPrice}}</span>
                     </div>
                     <div class="product-price" *ngIf="profile?.isElite" style="color:#458e44;">
                       <span class="las la-crown"></span>&nbsp;Price&nbsp;<span >{{vendor.elitePrice | formatPrice}}</span>
                     </div> -->

                    <h6 class="my-3"><strong><span class="mdi mdi-approval mr-1"></span> Available in</strong> - {{product.unit}} {{product.scale}}</h6>
                    <p class="regular-price"><i class="mdi mdi-tag-outline"></i> MRP : {{vendor.retailPrice | currency:"INR"}}
                    </p>
                    <p class="offer-price mb-0 w-100" *ngIf="loggedIn">
                        <span>Selling price : </span>
                        <span style="font-size: 23px; font-weight: 500; color: #001c4e!important;">{{vendor.offerPrice |
                            currency:"INR"}}
                        </span>

                        <ng-container *ngIf="profile.isElite">
                            <br>
                            <label class="h5 mt-2">Elite price : </label>
                            <span class="mb-2" style="font-size: 23px; font-weight: 500; color: #28a745!important;">{{vendor.elitePrice
                                | currency:"INR"}}
                            </span>
                        </ng-container>
                    </p>
                    <p class="offer-price mb-0 w-100" *ngIf="!loggedIn">
                        <span>Selling price : </span>
                        <span style="font-size: 23px; font-weight: 500; color: #001c4e!important;">
                            {{vendor.offerPrice | currency:"INR"}}
                        </span>
                    </p>

                    <div class="product-variants mt-4 accordion" id="accordionExample">
                        <!-- <div class="form-group variant-check">
                        </div>  -->
                        <div class="form-group variant-custom-selection">
                            <div class="row">
                                <div class="col-xl-4 col-lg-6">
                                    <p class="offer-price">
                                        Best Sellers
                                    </p>
                                </div>
                                <div class="col-xl-10 col-lg-12">
                                    <ul class="custom-selection d-sm-flex flex-wrap form-custom-radio">
                                        <li type="button" *ngFor="let p of product.vendors | slice:0:1;let i=index;" class="batch-rectangle border flex-fill mx-1 p-1 active" (click)="setCurrentVendor(i);">
                                            <span class="batch-text">{{vendor.vendorDetail.shopName | titlecase}} </span>
                                            <span class="batch-text" style="font-weight: 900;">
                                                {{(profile && profile.isElite)?vendor.elitePrice:(vendor.offerPrice ||vendor.retailPrice) | currency:"INR"}}/-</span>
                                        </li>
                                        <li type="button" *ngIf="product.vendors.length > 1" class="collapsed text-center text-info batch-rectangle border flex-fill mx-1 p-1" data-toggle="collapse" data-target="#collapseVendor" aria-expanded="false" aria-controls="collapseVendor">
                                            <span class="d-block">Show More Sellers..</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="mx-3 w-100" style="max-height: 200px;overflow: overlay;">
                                    <div id="collapseVendor" class="border border-top-0 collapse" aria-labelledby="headingVendor" data-parent="#accordionExample">
                                        <div class="px-2">
                                            <div class="batch-rectangle border-bottom mt-2 py-2 d-flex flex-wrap justify-content-end" type="button" *ngFor="let v of product.vendors; let i=index;" [class.active]="v.vendorId==vendor.vendorId && v.pinCode==vendor.pinCode" (click)="setCurrentVendor(i);">
                                                <h6 class="mx-3">{{v.vendorDetail.shopName | titlecase}}</h6>
                                                <!-- <span class="mr-2">{{v.pinCode}}</span> -->
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="number-picker m-4" [class.d-none]="qlimit < 1">
                        <label for="qty">Quantity</label>
                        <div class="input-group-quantity">
                            <input type="number" min="1" [attr.max]="qlimit" id="qty" class="form-control input-number input-quantity" [value]="quantity" (input)="setQuantity($event)">
                            <span class="btn-wrapper">
                                <button type="button" data-type="plus" class="btn btn-number btn-plus m-0"
                                    (click)="quantityUpdate('INC')" [disabled]="quantity==qlimit"> + </button>
                                <button type="button" data-type="minus" [disabled]="quantity==1"
                                    class="btn btn-number btn-minus m-0" (click)="quantityUpdate('DEC')"> - </button>
                            </span>
                        </div>
                    </div>

                    <a [class.btn-loading]="loading" [class.d-none]="qlimit < 1" (click)="addToCart()" class="btn-link" href="javascript:void(0)">
                        <button type="button" class="btn btn-secondary btn-lg"
                            [disabled]="loading || (qlimit <= 0) || quantity > qlimit || quantity == 0">
                            <i class="mdi mdi-cart-outline"></i> Add To Cart
                        </button>
                    </a>
                    <div class="short-description">
                        <h5>
                            Quick Overview
                            <p class="float-right">Availability:
                                <span class="badge-primary p-1" *ngIf="qlimit < 1">No Stock</span>
                                <span class="badge-success p-1" *ngIf="qlimit > 0">In Stock</span>
                            </p>
                        </h5>
                        <p>{{product.shortDetails}}</p>
                        <p class="mb-0">{{product.description}}</p>
                    </div>
                    <h6 class="mb-3 mt-4">Why shop from Tokoshope?</h6>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="feature-box">
                                <i class="mdi mdi-truck-fast"></i>
                                <h6 class="text-info">Cost Effective</h6>
                                <p>Best and affortable price in the market.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="feature-box">
                                <i class="mdi mdi-basket"></i>
                                <h6 class="text-info">100% Fresh</h6>
                                <p>Fresh from our store directly to you.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="" *ngIf="product && product == null">
            <div class="empty-message">
                <lottie-player src="https://assets6.lottiefiles.com/packages/lf20_CWcCII.json" background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay>
                </lottie-player>
                <h2>Oops! No product found.</h2>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" tabindex="-1" role="dialog" id="vendorListModal">
    <div class="modal-dialog modal-right" role="document">
        <div class="modal-content">
            <div class="modal-header bg-dark">
                <h5>
                    <!-- My Cart <span class="text-success">({{vendorCount}} item)</span>
            <a data-toggle="offcanvas" class="float-right" href="javascript:void(0)"><i class="mdi mdi-close"></i>
            </a> -->
                </h5>
            </div>
            <div class="modal-body bg-white">

            </div>
        </div>
    </div>
</div>