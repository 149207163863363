<section class="shop-list section-padding" [class.loading]="loading">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 col-lg-3">
                <app-category-filter></app-category-filter>
                <!-- <app-category-banner></app-category-banner> -->
            </div>
            <div class="col-md-8 col-lg-9">
                <!-- <a href="#"><img class="img-fluid mb-3" src="assets/img/shop.jpg" alt=""></a> -->
                <app-product-list></app-product-list>
            </div>
        </div>
    </div>
</section>
