import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare var $:any;
@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public alert:BehaviorSubject<any>=new BehaviorSubject<any>({message:"Hi",type:"success"});
  public alertTypes={
    success:"success",
    danger:"danger",
    secondary:"secondary",
    warning:"warning",
    light:"light",
    dark:"dark",
    primary:"primary"
  }

  constructor(){}
  setAlertMessage(msg,alertType){
    this.alert.next({message:msg,type:alertType?alertType:"success"});
    $('.alert').show();
  }

  showAlertVW(msg:string,alertType:string){
    this.alert.next({message:msg,type:alertType?alertType:"success"});
    $('.alert').show();
    setTimeout(()=>{
      $('.alert').hide();
    },3000);
  }

  showAlert(){
    $('.alert').show();
  }

  hideAlert(){
    $('.alert').hide();
  }
}
