import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { ProductsService } from 'src/app/services/products.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import {getApiUrl} from 'src/app/utils';
declare var $: any;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  apiUrl=getApiUrl();

  constructor(
    private router:Router,
    private prodService:ProductsService,
    private dtService:DataService
  ) { }

  cats:any[];
  loading: boolean = true;

  ngOnInit(): void {
    this.prodService.getCats().subscribe(r=>{
      if(r.code=='ECM005'){
        // this.cats=r.payload.categories.filter(e=> e.totalProds > 0);
        this.cats=r.payload.categories;
        this.startCarousel();        
      }
    });
  }

  startCarousel(){
    // ===========Category Owl Carousel============
      setTimeout(()=>{
          this.loading = false;
          var objowlcarousel = $(".owl-carousel-category-ra");
          if (objowlcarousel.length > 0) {
            objowlcarousel.owlCarousel({
                responsive: {
                    0: {
                        items: 3,
                    },
                    600: {
                        items: 5,
                        nav: false
                    },
                    1000: {
                        items: 8,
                    },
                    1200: {
                        items: 8,
                    },
                },
                items: 8,
                lazyLoad: true,
                pagination: false,
                loop: true,
                dots: false,
                autoPlay: 2000,
                navigation: true,
                stopOnHover: true,
                nav: true,
                navigationText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"]
            });
        }
      }, 500)

  }

  setCat(category:any):void{
    // console.log("ITEM",category);
    this.prodService
    this.dtService.setSearchItem(category);
    ////console.log(this.router.getCurrentNavigation());
    localStorage.setItem('currentCategory', JSON.stringify(category));
    this.router.navigate(['/category/'+category._id]);
  }

}
