<section class="order-details-wrap card py-3" *ngIf="order && profile">
    <div class="container">
        <div class="order-details-top">
            <h5 class="section-title">View Order</h5>
            <div class="row">
                <div [ngClass]="(order.orderStatus=='SHIPPED' || order.orderStatus=='DELIVERED')?'col-md-8':'col-md-8'">
                    <div class="order-information">
                        <h6>Order Information</h6>
                        <ul class="list-inline order-information-list">
                            <li>
                                <label>Reference No:</label>
                                <span>{{order.referenceNo}}</span>
                            </li>
                            <!-- <li>
                                <label>Phone:</label> 
                                <span>{{profile.mobile}}</span>
                            </li> -->
                            <!-- <li>
                                <label>Email:</label> 
                                <span>{{profile.email}}</span>
                            </li> -->
                            <li>
                                <label>Date:</label>
                                <span>{{getLocalDate(order.createdAt)}}</span>
                            </li>
                            <li>
                                <label>Payment Method:</label>
                                <span>{{getPaymentMethod(order.paymentMethod)}}</span>
                            </li>
                            <li>
                                <label>Order:</label>
                                <span style="color: red;">{{order.orderStatus}}</span>
                            </li>
                            <li>
                                <label>Delivery Method:</label>
                                <span class="text-success"> {{order.quickDelivery?'Quick Delivery' : 'Normal Delivery'}}</span>
                            </li>
                            <li *ngIf="!order.quickDelivery">
                                <label>Delivery Time:</label>
                                <span class="text-danger"> {{order.timeSlot.time}} ({{order.timeSlot.date}})</span>
                            </li>

                        </ul>
                    </div>
                </div>
                <!-- <div class="col-lg-6 col-sm-9">
                    <div class="order-billing-details">
                        <h5>Billing Address</h5>
                        <address>
                            ABCD XZY
                            <br>
                            as
                            <br>

                            as
                            <br>

                            as, Jamalpur 625005
                            <br>
                            Bangladesh
                        </address>
                    </div>
                </div> -->
                <div [ngClass]="(order.orderStatus=='SHIPPED' || order.orderStatus=='DELIVERED')?'col-md-4':'col-md-4'">
                    <div class="order-shipping-details">
                        <h6>Shipping Address</h6>
                        <address>
                            {{order.deliveryAddress.addressLine | uppercase}}
                            <br>
                            {{order.deliveryAddress.locality}}
                            <br>
                            {{order.deliveryAddress.landMark}}
                            <br>
                            {{order.deliveryAddress.district | uppercase}},{{order.deliveryAddress.state | uppercase}},{{order.deliveryAddress.pinCode | uppercase}}
                            <br>
                            India
                        </address>
                    </div>
                </div>

                <div class="col-md-3" *ngIf="(order.orderStatus=='SHIPPED' || order.orderStatus=='DELIVERED') && order.shippingDetails&& order.shippingDetails.trackingId">
                    <div class="order-shipping-details">
                        <h6>Shipping Details</h6>
                        <ul class="list-inline order-information-list">
                            <li>
                                <label>Courier Name:&nbsp;</label>
                                <span style="color: red;">{{order?.shippingDetails?.courierName | titlecase}}</span>
                            </li>
                            <li>
                                <label>Tracking No:&nbsp;</label>
                                <span style="color: red;">{{order?.shippingDetails?.trackingId | titlecase}}</span>
                                <!-- <a [routerLink]="['/ct']" [queryParams]="{tid:order?.shippingDetails?.trackingId}" style="text-decoration: underline;">{{order?.shippingDetails?.trackingId}}</a> -->
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="order-details-middle">
            <div class="table-responsive">
                <table class="table table-borderless order-details-table text-right">
                    <thead>
                        <tr>
                            <th class="text-left">PRODUCT NAME</th>
                            <th>UNIT PRICE</th>
                            <th>QUANTITY</th>
                            <th>LINE TOTAL</th>
                            <th *ngIf="order.orderStatus=='DELIVERED'">Return Eligible</th>
                            <th *ngIf="order.orderStatus=='DELIVERED'">Replace Eligible</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let p of order.items;">
                            <td class="text-left">
                                <a [routerLink]="['/prod-detail']" [queryParams]="{_id:p.productId}" class="product-name">
                                {{(p.name || "Sample Product") | titlecase}}
                            </a>
                            </td>
                            <td><label>UNIT PRICE</label> <span class="product-price" formatPrice>
                                {{p.pricePerQuantity}}
                            </span></td>
                            <td><label>QUANTITY</label> <span class="quantity">
                                {{p.quantity}}
                            </span></td>
                            <td>
                                <label>LINE TOTAL</label> <span class="product-price" formatPrice>
                                {{(p.pricePerQuantity*p.quantity)}}
                            </span></td>
                            <td *ngIf="order.orderStatus=='DELIVERED'">
                                <label>Replace Eligible</label>
                                <!-- <a class="product-name" [style.color]="p.replaceElibile?'green':'red'">{{p.returnElibile?'Yes':'No'}}</a> -->
                                <!-- <a [routerLink]="['/prod-detail']" [queryParams]="{_id:p.productId}" -->
                                <a href="javascript:void(0);" class="product-name" [style.color]="p.returnEligible?'green':'red'" (click)="returnOrReplace(p)">
                                {{p.returnEligible?'Yes':'No'}}
                             </a>
                            </td>
                            <td *ngIf="order.orderStatus=='DELIVERED'">
                                <label>Replace Eligible</label>
                                <!-- <a class="product-name" [style.color]="p.replaceElibile?'green':'red'">{{p.replaceElibile?'Yes':'No'}}</a> -->
                                <a href="javascript:void(0);" class="product-name" [style.color]="p.replacementEligible?'green':'red'" (click)="returnOrReplace(p)">
                                {{p.replacementEligible?'Yes':'No'}}
                            </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="order-details-bottom px-2">
            <ul class="list-inline order-summary-list">
                <li><label>Subtotal</label> <span class="price-amount" formatPrice>
                        {{order.subTotal}}
                    </span></li>
                <li>
                    <label>Delivery Charge</label>
                    <span class="price-amount" formatPrice>
                        {{order.deliveryCharge}}
                    </span>
                </li>
            </ul>
            <div class="order-summary-total">
                <label>Total</label>
                <span class="total-price" formatPrice>
                    {{order.grantTotal}}
                </span>
            </div>
        </div>
    </div>
</section>
<return-product></return-product>