import { AfterViewChecked, AfterViewInit, Component, HostListener, OnChanges, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { ProfileService } from 'src/app/services/profile.service';
import {OrderService} from 'src/app/services/order.service';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralService } from 'src/app/services/general.service';
import { FormGroup } from '@angular/forms';
declare var $:any;
declare var window:any;
declare var Razorpay:any;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit,OnChanges,AfterViewInit,AfterViewChecked {

  @HostListener("window:beforeunload", ["$event"]) beforeUnloadHandler(event: Event) {
    console.log("window:beforeunload");
    event.returnValue = '.' as any;
  }

  @HostListener("window:unload", ["$event"]) 
  unloadHandler(event: Event) {  
      // console.log("window:unload");
  }

  isAddressChecked:boolean=false;
  isTimeSelected:boolean=false;
  isPaymentMethodChecked:boolean=false;
  isOrderPlaced:boolean=false;

  submitted:boolean=false;
  error:string;
  registerForm:FormGroup;

  orderLoading:boolean=false;

  loading:boolean=true;
  profile:any;
  currentAddress:any;
  paymentMethod:string="CASH_ON_DELIVERY";
 
  vendorCart:any=null;
  carts:any[];
  subTotal;
  timeSlots:any[]=[];
  quickDelivery:boolean=true;
  slotIndex:number=0;
  url:string="https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/";
  txnDetails:any=null;
  config:any;
  deliveryCharge:number=0;
  vendor:any;
  pm:any;

  walletLoading: boolean;
  isWalletApplicable: boolean;
  applyToWallet: number = null;
  walletError: string;

  constructor(
    private pfService:ProfileService,
    private ctService:CartService,
    private odService:OrderService,
    private alert:AlertService,
    private gs:GeneralService
  ){
    window.onbeforeunload = function(e) {
      return 'Dialog text here.';
    };
    
    this.quickDelivery=false;
    this.onRazorSuccess=this.onRazorSuccess.bind(this);
    this.onRazorFailed=this.onRazorFailed.bind(this);
    this.onPaymentModalClosed=this.onPaymentModalClosed.bind(this);

    this.ctService.getVendorWiseCarts().subscribe(res=> console.log(res));
  }

  orderFailed:string=null;
  orderStatus:string=null;
  referenceNo:string=null;

  currentOrderId:string=null;

  onRazorSuccess(response:any){
    // console.log(response,this.currentOrderId);
    alert(this.profile.name+" Order is Completed");
    this.odService.completeRazorPayment(response,this.currentOrderId).subscribe(r=>{
      if(r.success){
        this.orderStatus=r.payload.order.orderStatus;
        this.referenceNo=r.payload.order.referenceNo;
        this.ctService.getMyCarts();

        this.isOrderPlaced = true;
        // this.odService.orderStatus=r.payload.order.orderStatus;
        // this.odService.orderRefNo=r.payload.order.referenceNo;
        // this.router.navigate(['order-placed']);
      }else{
        this.orderFailed='Transaction Failed!!';
        this.isOrderPlaced=false;
        this.alert.showAlertVW('Transaction Failed',this.alert.alertTypes.danger);
        //remove order restore cart;
      }
    },e=>{
      this.orderFailed='Transaction Failed '+JSON.stringify(e);
      this.isOrderPlaced=false;
      this.alert.showAlertVW('Transaction Failed',this.alert.alertTypes.danger);
    });
  }


  deletePendingOrder():any{    
    this.orderLoading=true;

    if(this.currentOrderId != null && this.currentOrderId != ''){

      this.odService.deletePendingOrder(this.currentOrderId).subscribe(r=>{      
        this.orderLoading=false;
        console.log(r);
        console.log(r.message);
        this.currentOrderId = null;
        this.isOrderPlaced=false;
        this.referenceNo = null;
        
        return true;
      },e=>{
        this.orderLoading=false;
        this.isOrderPlaced=false;
        
        return true;
      });

    }
    
  }

  onRazorFailed(response:any):void{    
    this.deletePendingOrder();
    this.orderFailed='Failed to Make orders!!'
    this.isOrderPlaced=false;

    //remove Order Restore Cart;
  }

  ngOnInit(): void {
    this.gs.getSettings().subscribe(r=>{
      this.pm=r.payload && r.payload.settings.paymentMethod;

      if(this.pm && typeof this.pm=='object'){
        if(this.pm.razorPay){
          this.paymentMethod='razor';
        }
        else if(this.pm.paytm){ 
          this.paymentMethod='paytm';
        }
      }      
    })

    this.odService.getSlots().subscribe(r=>{
      console.log("Time Slots",r);
      this.timeSlots=r.payload.timeSlots || [];
    });

    this.vendorCart=this.ctService.currentVendorCart;
    
    this.deliveryCharge=this.vendorCart && this.vendorCart.deliveryCharge;
    this.ctService.cartCount.subscribe(v=>{
      let {carts,subTotal}=this.ctService.getLocalCarts();
      this.carts=carts;
      this.subTotal=subTotal;
      this.vendorCart=this.ctService.currentVendorCart;
      this.deliveryCharge=this.vendorCart && this.vendorCart.deliveryCharge;      
    });

    this.pfService.onPChange.subscribe(v=>{
      this.profile=this.pfService.getLocalProfile();
      console.log(this.profile);
      
      this.pfService.getCurrentAddress().subscribe(response => {
        console.log(response);
        this.currentAddress = response;
      })
   
      this.getMyVendor();
    });

    this.loading = false;
  }

  applyWallet(){
    this.walletLoading = true;
    this.walletError = undefined;

    if(this.applyToWallet == null || this.applyToWallet == 0){
      this.alert.showAlertVW('Amount require for applying wallet..', this.alert.alertTypes.danger);
      this.walletLoading = false;
      return;
    }
    else if(this.applyToWallet < 0){
      this.alert.showAlertVW('Invalid Amount for applying wallet..', this.alert.alertTypes.danger);
      this.walletLoading = false;
      return;
    }

    this.odService.checkWallet({walletAmount: this.applyToWallet, vendorId: this.vendorCart.vendorDetails.vendorId}).subscribe(res=>{
      if(res.success){
        this.isWalletApplicable = true;
        this.alert.showAlertVW(res.message, this.alert.alertTypes.success);
      }
      else{
        this.isWalletApplicable = false;
        this.walletError = res.message;
        this.alert.showAlertVW(res.message, this.alert.alertTypes.danger);
      }    
      this.walletLoading = false;
    })
  }

  onSubmit(){
    // //console.log(this.registerForm.value);
    // //console.log(this.registerForm.invalid);
   // //console.log(this.fMessage.password.errors);
    
   
   //console.log(this.aMsg);
    //console.log(this.fMessage.confirmPassword);
    this.submitted=true;

    if(this.loading)
      return;

    if(!this.registerForm.invalid){
      this.loading=true;
      this.pfService.register(this.registerForm.value).subscribe(r=>{
        this.loading=false;
        if(r.success)
          { 
            localStorage.setItem('session_user',r.payload.renewedToken);
            localStorage.setItem('service-available',r.payload.isServiceAvailable?'Y':'N');
            localStorage.setItem('userFound','FOUND');
            localStorage.setItem('notify','YES');
            document.location.href='/';
          }
        else
          this.error=r.message;          
      });
    }else{
      this.error="Please Fill All The Required Fields...";
    }
  }

  getMyVendor():void{
  
    this.pfService.getMyVendor().subscribe(r=>{
      if(r.success)
        this.vendor=r.payload.vendor ||  null;
      else
        this.alert.showAlertVW(r.message,this.alert.alertTypes.danger);

    },e=>{
      console.log(e);
    });

  }

  onPaymentModalClosed():void{
    
    this.deletePendingOrder();
  }

  placeOrder(){
    this.orderFailed = null;
    this.isOrderPlaced=false;

    let body:any={
      paymentMethod:this.paymentMethod,
      quickDelivery:this.quickDelivery,
      vendorId:this.vendorCart.vendorDetails.vendorId
    };

    if(this.isWalletApplicable == true){
      body.walletAmount = this.applyToWallet;
    }

    if(!this.quickDelivery){
      body.timeSlot=this.timeSlots[this.slotIndex];
    
      delete body.timeSlot.timeStamp;
    }

    // this.loading=true;
    this.odService.placeOrder(body).subscribe(r=>{
      this.loading=false;
      if(r.code=="ECM011"){
          console.log(this.paymentMethod);
          if(this.paymentMethod=="CASH_ON_DELIVERY"){
            this.isOrderPlaced = true;
            this.orderStatus=r.payload.order.orderStatus;
            this.referenceNo=r.payload.order.referenceNo;
            this.ctService.getMyCarts();
            
            this.alert.showAlertVW(r.message,this.alert.alertTypes.success);

            // this.odService.orderStatus=r.payload.order.orderStatus;
            // this.odService.orderRefNo=r.payload.order.referenceNo;
            // this.router.navigate(['app/order-placed']);
          }else if(this.paymentMethod=='razor'){
            this.txnDetails=r.payload.txnDetails;
          this.currentOrderId=r.payload._id;
          delete this.txnDetails.src;
          this.txnDetails.handler=this.onRazorSuccess;
          this.txnDetails.modal={
            ondismiss:this.onPaymentModalClosed
          }
          let rzp=new Razorpay(this.txnDetails);
          rzp.on('payment.failed', this.onRazorFailed);
          rzp.open();
          }
          else{
            console.log(r.payload);
            this.txnDetails=r.payload.txnDetails;
            let s=this;
            $(".checkout-wrap").addClass("loading");
            this.loadScript().then(()=>{
              s.config={
                "root":"",
                "flow":"DEFAULT",
                "data":{
                  "orderId":s.txnDetails.orderId,
                  "token":s.txnDetails.txnToken,
                  "tokenType":"TXN_TOKEN",
                  "amount":s.txnDetails.txnAmount.value
                },
                "handler":{
                  "notifyMerchant":function(eventName,data){
                    if(eventName=='APP_CLOSED') 
                    {	
                    }
                    s.removePaymentScripts();
                  }
                }
              };	
              window.Paytm.CheckoutJS.onLoad(this.check.bind(this));						
            });
          }
      }else{
          this.alert.showAlertVW(r.message,this.alert.alertTypes.danger);
          this.orderFailed='Failed To Make Order!!'
        this.isOrderPlaced=false;

          console.log(r.message);
          
      }
    });
  }

  onChange(e):void{
    this.paymentMethod=e.target.value;
  }

  setDeliveryMethod(e):void{
    if(e.target.id=='nd'){
      this.deliveryCharge=this.vendorCart.deliveryCharge+0;
      this.quickDelivery=false;
    }else{
      this.deliveryCharge=this.vendorCart.deliveryCharge+(this.profile.isElite?0:50);
      this.quickDelivery=true;
    }
    console.log(this.deliveryCharge);
  }

  setSlotIndex(e):void{
    this.slotIndex=parseInt(e.target.value);
  }

  ngAfterViewInit():void{
    
    // $('select').niceSelect();
  }
  ngAfterViewChecked():void{
    //console.log("AFTER VIEW CHECKED");
    // $("select").niceSelect('update');   
  }
  ngOnChanges():void{
    //$('select').niceSelect();
    //console.log("ON DATA CHANGE");
    setTimeout(()=>{
 
    },100);
  }

  setCurrentAddress(i):void{
      this.currentAddress=this.profile.address[i];
  }


  loadScript():Promise<any>{
		let self=this;
		return new Promise((resolve,reject)=>{
			let s=document.createElement('script');
			s.onload=resolve;
			s.src=self.url+self.txnDetails.mid+".js";
			document.head.appendChild(s);
		});
	}


  check(){
    let s=this;
		window.Paytm.CheckoutJS.init(s.config).then(function onSuccess(){
      $(".checkout-wrap").removeClass("loading");
      console.log("SUBAM");
      window.Paytm.CheckoutJS.invoke();
		}).catch(function onError(error){
      console.log(error);
      $(".checkout-wrap").removeClass("loading");
			s.removePaymentScripts();
		});
	}

  removePaymentScripts():void{
		document.querySelectorAll('script').forEach(s=>{
			if(s.src.includes('paytm')){
				s.parentElement.removeChild(s);
			}
		});

		document.querySelectorAll('link').forEach(l=>{
			if(l.href.includes('paytm')){
				l.parentElement.removeChild(l);
			}
		});

		document.querySelectorAll('iframe').forEach(i=>{
			i.parentElement.removeChild(i);
		});
	}
}
