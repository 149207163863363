import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  
  private productsArray = new BehaviorSubject<number>(0);
  private requestBody = new BehaviorSubject<any>(0);

  setProducts(data: any) {
     this.productsArray.next(data);
  }
  getProducts(): Observable<any> {
     return this.productsArray.asObservable()
  }

  setRequestBody(data: any) {
      this.requestBody.next(data);
  }
  getRequestBody(): Observable<any> {
      return this.requestBody.asObservable()
  }

  constructor(private http:HttpClient){
    console.log('prod service constructor');
    
    this.setRequestBody({
      size:10,
      page:1,
      title:"New To Old",
      sortBy:"createdAt",
      order:-1
    })
  }

  getCats():Observable<any>{
    return this.http.get(NetworkService.getCats(),{});
  }

  getSubCats(catId):Observable<any>{
    return this.http.get(NetworkService.getSubCats()+`?categoryId=${catId}`,{});
  }

  getCatsAndSubs():Observable<any>{
    return this.http.get(NetworkService.getCatsAndSubs(),{});
  }

  searchProduct(body):Observable<any>{
    return this.http.post(NetworkService.searchProduct(),body,{headers:NetworkService.getHeaderIfUserExists()});
  }

  totalProduct(body):Observable<any>{
    return this.http.post(NetworkService.getTotalLengthProduct(),body,{headers:NetworkService.getHeaderIfUserExists()});
  }

  expandProduct(productId):Observable<any>{
    return this.http.get(NetworkService.expandProduct()+`?_id=${productId}`,{headers:NetworkService.getHeaderIfUserExists()});
  }

  expandProductWith(productId):Observable<any>{
    return this.http.get(NetworkService.expandProductText()+`?_id=${productId}`,{headers:NetworkService.getHeaderIfUserExists()});
  }

  getBrands():Observable<any>{
    return this.http.get(NetworkService.getBrands(),{});
  }

  getProductSubWise(categoryId):Observable<any>{
    return this.http.get(NetworkService.getProductSubWise(),{params:{categoryId}});
  }
  
  // getExclusives(type:string):Observable<any>{
  //   return this.http.post(NetworkService.getExclusiveProducts,{type},{headers:NetworkService.getHeaderIfUserExists()});
  // }

  // getTodayDeals():Observable<any>{
  //   return this.http.get(NetworkService.TodayDeals,{headers:NetworkService.getHeaderIfUserExists()});
  // }

}
