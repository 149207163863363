import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NetworkService } from './network.service';
import { SocialAuthService } from 'angularx-social-login';
import { MessagingService } from './messaging.service';
@Injectable({
  providedIn: 'root'
})
export class ProfileService{
  profile:any;
  public currentAddress:BehaviorSubject<any>=new BehaviorSubject<any>({});
  public onPChange:BehaviorSubject<any>=new BehaviorSubject<any>(true);
  public isLoggedIn:BehaviorSubject<boolean>=new BehaviorSubject<any>(false);

  constructor(
    private http:HttpClient,
    private auth:SocialAuthService
  ){}
  checkForUser(mobile:string):Observable<any>{
    return this.http.post<any>(NetworkService.searchUser(),{mobile});
  }


  isTokenExists():boolean{
    return  localStorage.getItem('session_user') && localStorage.getItem('session_user') !='undefined' ? true: false;
   //return false;
  }

  isUserLoggedIn():boolean{
    return localStorage.getItem('session_user') && localStorage.getItem('userFound') && localStorage.getItem('userFound')=='FOUND'?true:false;
  }

  isUserRegistered():boolean{
    return localStorage.getItem('session_user') && localStorage.getItem('userFound') && localStorage.getItem('userFound')=='NOT_FOUND'?true:false;
  }

  getOtp(mobile):Observable<any>{
    return this.http.post<any>(NetworkService.getOtp(),{mobile});
  }

  login(obj):Observable<any>{
    return this.http.post<any>(NetworkService.login(),obj);
  }

  loginWithGoogle(obj):Observable<any>{
    return this.http.post<any>(NetworkService.loginGoogle(),obj);
  }

  sendNotificationToken(token):Observable<any>{
    return this.http.post<any>(NetworkService.sendToken(),{token});
  }

  logout():void{
    
    localStorage.removeItem('session_user');
    localStorage.removeItem('userFound');
    this.auth.signOut();
    //location.reload();
  }

  register(obj):Observable<any>{
    return this.http.post<any>(NetworkService.register(),obj,{headers:NetworkService.getAuthHeader()});
  }

  getMyProfile(){
    return this.http.get<any>(NetworkService.myProfile(),{headers:NetworkService.getAuthHeader()}).pipe(map(r=>{
      this.profile=r.payload.profile?r.payload.profile:null;
      this.onPChange.next(!this.onPChange.value);
    }));
  }

  getCurrentAddress(){
    return this.currentAddress;
  }

  getLocalProfile(){
    return this.profile;
  }

  clearProfile(){
    this.profile=null;
  }

  addAddress(obj):Observable<any>{
    return this.http.post(NetworkService.addAddress(),obj,{headers:NetworkService.getAuthHeader()}).pipe(map((r:any)=>{
      if(r.success)
        this.getMyProfile().subscribe(re=>{});
      return r;
    }));
  }

  editAddress(obj):Observable<any>{
    return this.http.post(NetworkService.editAddress(),obj,{headers:NetworkService.getAuthHeader()}).pipe(map((r:any)=>{
      if(r.success)
        this.getMyProfile().subscribe(re=>{});
      return r;
    }));
  }

  deleteAddress(addressId):Observable<any>{
    return this.http.post(NetworkService.deleteAddress(),{addressId},{headers:NetworkService.getAuthHeader()}).pipe(map((r:any)=>{
      if(r.success)
        this.getMyProfile().subscribe(re=>{});
      return r;
    }));;
  }

  updateProfile(obj):Observable<any>{
    return this.http.post(NetworkService.updateProfile(),obj,{headers:NetworkService.getAuthHeader()}).pipe(map((r:any)=>{
      if(r.success)
        this.getMyProfile().subscribe(re=>{});
      return r;
    }));
  }

  getAddress():Observable<any>{
    return this.http.get(NetworkService.getAddress(),{headers:NetworkService.getAuthHeader()});
  }

  selectAddress(data):Observable<any>{
    let _id=data._id;
    return this.http.post(NetworkService.selectAddress,{_id},{headers:NetworkService.getAuthHeader()}).pipe(map((r:any)=>{
      if(r.success){
       localStorage.setItem('session_user',r.payload.renewedToken);
       if(r.payload.isServiceAvailable)
         localStorage.setItem('service-available',"Y");
       else
          localStorage.setItem('service-available',"N");
        
        this.currentAddress.next(data);
       //location.reload();
      }

      return r;
    }));
  }

  isServiceAvailable():Observable<any>{
    return this.http.get(NetworkService.isServiceAvailable,{headers:NetworkService.getAuthHeader()});
  }

  getElitePlans():Observable<any>{
    return this.http.get(NetworkService.getElitePlans,{headers:NetworkService.getAuthHeader()});
  }

  subscribeElite(_id:string):Observable<any>{
    return this.http.post(NetworkService.subscribeElite,{_id},{headers:NetworkService.getAuthHeader()});
  }

  getMyVendor():Observable<any>{
    return this.http.get(NetworkService.getMyVendor,{headers:NetworkService.getAuthHeader()});
  }

  subscribeUser():Observable<any>{
    return this.http.post(NetworkService.subscribeUser,{},{headers:NetworkService.getAuthHeader()});
  }

  completeSubscription(transactionDetails:any,_id:string):Observable<any>{
    return this.http.post(NetworkService.completeSubscription,{_id,transactionDetails},{headers:NetworkService.getAuthHeader()})
  }

  completeEliteSubscription(transactionDetails:any,_id:string):Observable<any>{
    return this.http.post(NetworkService.completeEliteSubscription,{_id,transactionDetails},{headers:NetworkService.getAuthHeader()})
  }
  }
