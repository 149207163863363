import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class RouteAuthGuard implements CanActivate {
  constructor(private route:Router){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree { 
      if(localStorage.getItem('session_user') && localStorage.getItem('session_user')!='undefined' && localStorage.getItem('userFound')=='FOUND')
      {
          return true;  
      }
      $('#login-signup-open-btn').click();
      return false;
  }
  
}
