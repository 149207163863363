<div class="navbar-top bg-success pt-2 pb-2">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 text-center">
                <!-- <a href="shop.html" class="mb-0 text-white">
                50% cashback for new users | Code: <strong><span class="text-light">OGOFERS13 <span class="mdi mdi-tag-faces"></span></span> </strong>
                </a> -->
            </div>
        </div>
    </div>
</div>