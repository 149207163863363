<div class="carousel-slider-main text-center border-top border-bottom bg-white">
    <div class="owl-carousel owl-carousel-slider-ra">
        <div class="item" *ngFor="let s of slider.sliders; let i=index;">
            <a href="shop.html">
                <iframe *ngIf="s.type=='video'" class="slider-video" [src]="s.url" frameborder="0" gesture="media" style="width: 963px; height: 500px;" allowfullscreen [class.d-none]="s.type != 'video'"></iframe>
                <img [src]="apiUrl + s.url" alt="{{i}} slide" class="img-fluid w-auto" [class.d-none]="s.type != 'image'">
                <div class="slide-content" [class]="s.contentAlign" *ngIf="s.caption1 && s.caption2 && s.type=='image'">
                    <div class="captions">
                        <span *ngIf="s.animation=='fadeInLeft'" data-animation-in='fadeInLeft' data-delay-in="" class="caption caption-1" style="opacity: 0;"><b>{{s.caption1}}</b>
                        </span>
                        <span *ngIf="s.animation=='fadeInLeft'" data-animation-in='fadeInLeft' data-delay-in="0.3" class="caption caption-2" style="opacity: 0;">
                            {{s.caption2}}
                        </span>
                        <span *ngIf="s.animation=='fadeInRight'" data-animation-in='fadeInRight' data-delay-in="" class="caption caption-1" style="opacity: 0;"><b>{{s.caption1}}</b>
                        </span>
                        <span *ngIf="s.animation=='fadeInRight'" data-animation-in='fadeInRight' data-delay-in="0.3" class="caption caption-2" style="opacity: 0;">
                            {{s.caption2}}
                        </span>
                        <a routerLink="/shop" data-animation-in="fadeInUp" data-delay-in="0.7" target="_blank" class="btn btn-primary btn-slider" tabindex="-1" style="opacity: 0;">
                            SHOP NOW
                        </a>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
<!-- <div class="carousel-slider-main text-center border-top border-bottom bg-white">
    <div class="owl-carousel owl-carousel-slider-ra">
        <div class="item" *ngFor="let s of [1,2]; let i=index;">
            <a href="shop.html">
                <img [src]="apiUrl + slider['banner'+s]" alt="{{s}} slide" class="img-fluid" style="height: 500px;">
            </a>
        </div>
    </div>
</div> -->