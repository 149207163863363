<div class="card card-body account-right container w-75 my-2" [class.loading]="loading">
    <div class="widget">
        <div class="section-header">
            <h2>Register</h2>
        </div>
        <form id="register-form" [formGroup]="registerForm" (ngSubmit)="onSubmit()" *ngIf="1loading">
            <div class="form-group">
                <label for="mobile">Mobile<span>*</span></label>
                <input type="text" formControlName="mobile" name="mobile" value="" id="mobile" class="form-control" required>
                <div *ngIf="submitted && fMessage.mobile.errors">
                    <span class="error-message" *ngIf="fMessage.mobile.errors.required">Mobile Number is Required.</span>
                    <span class="error-message" *ngIf="fMessage.mobile.errors.pattern">Invalid Mobile Number.</span>
                </div>
            </div>

            <div formGroupName="address">
                <div class="form-group">
                    <label for="type">Address Type<span>*</span></label>
                    <select class="form-control" formControlName="type" value="" required>
                        <option value="" selected>Select Type</option>
                        <option value="Home" >Home</option>
                        <option value="Business" >Business</option>
                    </select>
                    <!-- <input type="text" name="type" formControlName="type" value="" id="addressType" class="form-control" required> -->
                    <div *ngIf="submitted && aMsg.type.errors">
                        <span class="error-message" *ngIf="aMsg.type.errors.required">Address Type Required.</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="addressLine">Address Line<span>*</span></label>
                    <textarea name="addressLine" value="" formControlName="addressLine" id="addressLine" class="form-control" required></textarea>
                    <div *ngIf="submitted && aMsg.addressLine.errors">
                        <span class="error-message" *ngIf="aMsg.addressLine.errors.required">Address Line Required.</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="landMark">Land Mark<span>*</span></label>
                    <input type="text" name="landMark" formControlName="landMark" value="" id="landMark" class="form-control" required>
                    <div *ngIf="submitted && aMsg.landMark.errors">
                        <span class="error-message" *ngIf="aMsg.landMark.errors.required">Address Line Required.</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="locality">Locality<span>*</span></label>
                    <input type="text" name="locality" formControlName="locality" value="" id="locality" class="form-control" required>
                    <div *ngIf="submitted && aMsg.locality.errors">
                        <span class="error-message" *ngIf="aMsg.locality.errors.required">Locality Required.</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="district">District<span>*</span></label>
                    <input type="text" name="district" formControlName="district" value="" id="district" class="form-control" required>
                    <div *ngIf="submitted && aMsg.district.errors">
                        <span class="error-message" *ngIf="aMsg.district.errors.required">District Required.</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="state">State<span>*</span></label>
                    <input type="text" name="state" value="" id="state" formControlName="state" class="form-control" required>
                    <div *ngIf="submitted && aMsg.state.errors">
                        <span class="error-message" *ngIf="aMsg.state.errors.required">State Required.</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="pinCode">Pin Code<span>*</span></label>
                    <input type="text" name="pinCode" value="" id="pinCode" formControlName="pinCode" class="form-control" required>
                    <div *ngIf="submitted && aMsg.pinCode.errors">
                        <span class="error-message" *ngIf="aMsg.pinCode.errors.required">Pincode Required.</span>
                        <span class="error-message" *ngIf="aMsg.pinCode.errors.pattern">Invalid PinCode.</span>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="referralCode">Referral Code</label>
                <input type="text" formControlName="referralCode" name="referralCode" value="" id="referralCode" class="form-control">
            </div>

            <button type="submit" data-loading="" class="btn btn-success btn-create-account" [class.btn-loading]="loading">
                Register
             </button>
            <!-- <button type="button" data-loading="" (click)="skipRegistration()" href="javascript:void(0)" class="bg-secondary btn mx-1 text-white" [class.btn-loading]="loading">
                Skip
            </button> -->
            <div class="error-message" *ngIf="error">{{error}}</div>
        </form>
    </div>
</div>