<section class="section-padding bg-dark inner-header">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="mt-0 mb-3 text-white">About Us</h1>
                <div class="breadcrumbs">
                    <p class="mb-0 text-white"><a class="text-white" href="#">Home</a> / <span class="" style="color: var(--color-primary-transparent) !important;">About Us</span></p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-padding bg-white">
    <div class="container">
        <div class="row">
            <div class="pl-4 col-lg-5 col-md-5 pr-4">
                <img class="rounded img-fluid" src="assets/img/about.jpg" alt="Card image cap">
            </div>
            <div class="col-lg-6 col-md-6 pl-5 pr-5">
                <h2 class="mt-5 mb-5 text-secondary">Save more with GO! We give you the lowest prices on all your grocery needs.</h2>
                <h5 class="mt-2">Our Vision</h5>
                <p>The simple web app that makes your Grocery purchase easy and buy good quality products at lowest price, place your orders in tokoshope Groceries, and get your delivery faster.</p>
                <h5 class="mt-4">Our Goal</h5>
                <p>We are delivering groceries and fresh veggies and fruits from our vendors nearest to your location. so you can get your products as fresh and on-time.</p>
            </div>
        </div>
    </div>
</section>