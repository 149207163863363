import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { OrderService } from 'src/app/services/order.service';
import { ProfileService } from 'src/app/services/profile.service';

declare var $:any;
@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit,OnDestroy {
  _id:string="";
  order:any=null;
  profile:any=null;
  constructor(private route:ActivatedRoute,private odS:OrderService,private pfS:ProfileService) { }

  ngOnInit(): void {
    let str = document.URL;      
    // console.log('constructor called ', str.substring(str.lastIndexOf('/') + 1));

    this.odS.item.next(null);
    this._id=str.substring(str.lastIndexOf('/') + 1);
    this.viewOrder();  

    // this.route.queryParams.subscribe(p=>{
    //   this._id=p['_id'];
    //   this.viewOrder();  
    // });
    this.profile=this.pfS.getLocalProfile();
  }

  viewOrder(){
    this.odS.viewOrder(this._id).subscribe(r=>{
        this.order=r.payload.order?r.payload.order:null;
        console.log(this.order);
    });
  }

  getLocalDate(dt:Date){
    return new Date(dt).toLocaleDateString();
  }

  getPaymentMethod(pm){
    return pm=='CASH_ON_DELIVERY'?"CASH ON DELIVERY" : "ONLINE PAYMENT";
  }

  returnOrReplace(product){

    if(product.returnEligible || product.replaceEligible){
      product.referenceNo=this.order.referenceNo;
      this.odS.item.next(product);
      $('#return-product').modal("toggle");
    }
  }

  ngOnDestroy():void{
    this.odS.item.next(null);
  }

}
