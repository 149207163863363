<div class="cart-sidebar">
    <div class="cart-sidebar-header">
        <h5>
            My Cart <span class="" style="color: #88b3ff!important" *ngIf="profile && cartCount" >({{cartCount}} item)</span>
            <a data-toggle="offcanvas" class="float-right" href="javascript:void(0)"><i class="mdi mdi-close"></i>
            </a>
        </h5>
    </div>
    <div class="sidebar-cart-middle empty cart-sidebar-body  m-4" *ngIf="profile && !carts || carts.length==0">
        <div class="sidebar-cart-items-wrap"></div>
        <div class="empty-message text-center">
            <trash-svg></trash-svg>
            <h4>Your cart is empty</h4>
        </div>
    </div>
    <div class="cart-sidebar-body" *ngIf="profile && carts && carts.length>0">
        <!-- <div class="cart-list-product">
            <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
            <img class="img-fluid" src="assets/img/item/11.jpg" alt="">
            <span class="badge badge-success">50% OFF</span>
            <h5><a href="#">Product Title Here</a></h5>
            <h6><strong><span class="mdi mdi-approval mr-1"></span> Available in</strong> - 500 gm</h6>
            <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i> <span class="regular-price">$800.99</span></p>
        </div> -->
        <a class="d-block cart-list-product" (click)="closeSideBar()" *ngFor="let cart of carts; let i=index;" [routerLink]="['/category/pv/'+cart.productId]">
            <button type="button" class="float-right remove-cart btn" (click)="removeFromCart(cart)"><i class="mdi mdi-close"></i></button>
            <img class="img-fluid" [src]="cart.productPicture && cart.productPicture[0] ? apiUrl+cart.productPicture[0] : 'assets/img/item/11.jpg' " alt="">
            <!-- <img class="img-fluid" src="assets/img/item/11.jpg" alt=""> -->
            <!-- <span class="badge badge-success">50% OFF</span> -->
            <h5 class="mt-3"><a>{{cart.productName | titlecase}}</a></h5>
            <h6><strong><span class="mdi mdi-approval mr-1"></span></strong>{{cart.productUnit}} {{cart.productScale}}</h6>
            <p class="d-flex flex-fill mb-0 mx-1 my-auto offer-price">{{( profile.isElite ? cart.elitePrice : cart.offerPrice) | currency:"INR"}}<span class="regular-price ml-2">{{cart.retailPrice | currency:"INR"}}</span></p>
        </a>
        <div class="cart-sidebar-footer">
            <div class="cart-store-details">
                <h6>Sub Total <strong class="float-right">{{subTotal | currency:"INR"}}</strong></h6>
                <!-- <p>Delivery Charges <strong class="float-right text-danger">+ $29.69</strong></p>
            <h6>Your total savings <strong class="float-right text-danger">$55 (42.31%)</strong></h6> -->
            </div>
            <!-- <a href="javascript:void(0)"><button class="btn btn-secondary btn-lg btn-block text-left" type="button"><span class="float-left"><i class="mdi mdi-cart-outline"></i> Proceed to Checkout </span><span class="float-right"><strong>{{subTotal | currency:"INR"}}</strong> <span class="mdi mdi-chevron-right"></span></span></button></a> -->
            <div class="sidebar-cart-actions mt-2">
                <a routerLink="/cart" (click)="closeSideBar()" class="btn btn-default btn-view-cart p-2 h-100">
                VIEW CART
            </a>
                <a href="javascript:void(0);" (click)="clearCart()" class="btn btn-primary btn-checkout p-2 h-100">
                CLEAR CART
            </a>
            </div>

        </div>
    </div>
</div>