import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertService } from './services/alert.service';
import { ProfileService } from './services/profile.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceCheckerGuard implements CanActivate {
  constructor(private route:Router,private ps:ProfileService, private altService: AlertService){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.ps.isUserLoggedIn()){
        return true;
      }
      if(localStorage.getItem('service-available')!='Y'){
        this.altService.showAlertVW('Service Not Available', this.altService.alertTypes.danger)
        // alert('Service Not Available');
        // this.route.navigate(['app/snf']);  
        return false;
      }
    return true;
  }
  
}
