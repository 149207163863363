<div>
    <div class="card card-body account-right">
        <div class="widget">
            <div class="section-header">
                <h5 class="heading-design-h5">
                    Wishlist
                </h5>
            </div>
            <div class="empty-message" *ngIf="!wishList || wishList.length==0">
                <h3>Looks Like You Don't Have Any Wishlist.</h3>
            </div>

            <div class="d-flex flex-wrap row-cols-2 row-cols-xl-3" id="my-product-list" *ngIf="wishList && wishList.length!=0">
                <!-- <div class="col-sm-6z flex-fillz" *ngFor="let wl of wishList;let i=index;"> -->
                    <div class="product  flex-fill" *ngFor="let wl of wishList;let i=index;">
                        <a href="javascript:void(0)">
                            <div class="product-header">
                                <!-- <span class="badge badge-success">50% OFF</span> -->
                                <!-- <img src="assets/img/item/1.jpg" alt="product image" class="img-fluid"> -->
                                <img [src]="wl.pictures && wl.pictures[0] ? apiUrl+wl.pictures[0] : 'assets/img/item/1.jpg'" alt="product image" class="img-fluid">
                                <span class="text-danger mdi mdi-heart-off wishlistbtn border-0" (click)="removeFromFavourites(wl._id)"></span>
                            </div>
                            <div class="product-body" [routerLink]="['/category/'+wl.categoryId+'/'+wl._id]">
                                <h5>{{wl.name}}</h5>
                                <h6><strong><span class="mdi mdi-approval mr-1"></span></strong>{{wl.unit}} {{wl.scale}} </h6>
                            </div>
                        </a>

                        <div class="d-sm-flex justify-content-between product-footer text-center">
                            <p class="d-flex mb-0 mx-1 my-auto offer-price">{{( profile.isElite ? wl.elitePrice : wl.offerPrice) | currency:"INR"}} <span class="regular-price ml-2">{{wl.retailPrice | currency:"INR"}}</span></p>

                            <!-- <div class="product-card-actions">
                                <button title="Remove" class="btn btn-wishlist" (click)="removeFromFavourites(wl._id)">
                                    <i class="mdi mdi-heart-off text-danger text-red" ></i>
                                    </button>
                            </div> -->

                            <button type="button" class="btn btn-secondary btn-sm flex-fill  flex-lg-grow-0 mx-1 my-2"
                            [disabled]="(wl.quantity - wl.lowQuantity) < 1" (click)="addToCart(wl._id,wl.priceId)">
                            <span class=" d-none d-md-block"><i class="mdi mdi-cart-outline"></i> Add To Cart</span>
                            <span class=" d-block d-md-none"><i class="mdi mdi-cart-plus"></i> Add</span>
                        </button>
    
                        </div>

                    </div>
                <!-- </div> -->
            </div>
            <!-- <nav>
                <ul class="pagination justify-content-center mt-4">
                    <li class="page-item disabled">
                        <span class="page-link">Previous</span>
                    </li>
                    <li class="page-item"><a href="#" class="page-link">1</a></li>
                    <li class="page-item active">
                        <span class="page-link">
                   2
                   <span class="sr-only">(current)</span>
                        </span>
                    </li>
                    <li class="page-item"><a href="#" class="page-link">3</a></li>
                    <li class="page-item">
                        <a href="#" class="page-link">Next</a>
                    </li>
                </ul>
            </nav> -->
        </div>
    </div>
</div>