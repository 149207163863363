<section class="custom-page-wrap clearfix">
    <div class="container">
        <div class="custom-page-content clearfix">


            <div class="WordSection1">

                <p class="MsoBodyText" style="margin-top:.05pt"><span style="font-size:7.5pt">&nbsp;</span></p>

                <p class="MsoNormal" style="margin-top:3.5pt;margin-right:0in;margin-bottom:0in;
            margin-left:5.0pt;margin-bottom:.0001pt">
                    <a name="Payment_Information"></a><span style="font-size:36.0pt;color:#666666">Payment Information</span></p>

                <p class="MsoNormal" style="margin-top:7.4pt;margin-right:0in;margin-bottom:0in;
            margin-left:5.0pt;margin-bottom:.0001pt">
                    <a name="Payouts"></a><span style="font-size:25.5pt;color:#666666">Payouts</span></p>

                <p class="MsoListParagraph" style="margin-top:14.05pt"><span style="font-size:
            12.0pt;color:#666666;letter-spacing:-.2pt">1.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style="font-size:12.0pt;color:#666666">How much can I earn
            via the Tokoshope affiliate<span style="letter-spacing:.05pt"> </span>program?</span>
                </p>

                <p class="MsoBodyText" style="margin-top:.15pt">&nbsp;</p>

                <p class="MsoBodyText" style="margin-left:41.05pt"><span style="color:#666666">https://tokoshope.com
            pays commission to affiliates depending on category of product sold via their
            website, The referral rates are stated at: </span><a href="https://affiliate.flipkart.com/commissions"><span style="color:#3379B7;
            text-decoration:none">commissions</span></a></p>

                <p class="MsoBodyText" style="margin-top:.1pt">&nbsp;</p>

                <p class="MsoListParagraph"><span style="font-size:12.0pt;color:#666666;
            letter-spacing:-.2pt">2.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style="font-size:12.0pt;color:#666666">What are the payment<span style="letter-spacing:-.2pt"> </span>methods?</span>
                </p>

                <p class="MsoBodyText" style="margin-top:.45pt">&nbsp;</p>

                <p class="MsoBodyText" style="margin-left:41.05pt"><span style="color:#666666">Affiliates
            have an option of receiving payment via</span></p>

                <p class="MsoListParagraph" style="margin-top:7.45pt;margin-right:0in;margin-bottom:
            0in;margin-left:52.25pt;margin-bottom:.0001pt;text-indent:-11.25pt"><span style="font-size:12.0pt;color:#666666;letter-spacing:-.2pt">a.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span style="font-size:12.0pt;color:#666666">Gift<span style="letter-spacing:-.15pt">
            </span>Voucher</span>
                </p>

                <p class="MsoListParagraph" style="margin-top:7.45pt;margin-right:0in;margin-bottom:
            0in;margin-left:53.05pt;margin-bottom:.0001pt;text-indent:-12.0pt"><span style="font-size:12.0pt;color:#666666;letter-spacing:-.2pt">b.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span style="font-size:12.0pt;color:#666666">Electronic Fund Transfer<span style="letter-spacing:.1pt"> </span>(EFT)</span>
                </p>

                <p class="MsoBodyText" style="margin-top:7.45pt;margin-right:10.75pt;margin-bottom:
            0in;margin-left:41.05pt;margin-bottom:.0001pt"><span style="color:#666666">If
            affiliates select payment via gift voucher, Tokoshope will accrue
            and withhold referral fees until the total amount due is at least Rs 2500.00.
            If affiliates select payment via Electronic Fund Transfer (EFT), Tokoshope will
            accrue and withhold referral fees until the total amount due is at least Rs
            5000.00. Gift vouchers may be redeemed against purchase from </span>https://tokoshope.com</p>

                <p class="MsoBodyText" style="margin-top:7.55pt;margin-right:1.1pt;margin-bottom:
            0in;margin-left:41.05pt;margin-bottom:.0001pt"><span style="color:#666666">Please
            note that the referral fees will be processed as per the payment mode selected
            by you in your affiliate account ('My Account' section) as on the 1st of every
            month. In case you change your payment mode after the 1st of the month, the new
            payment mode will be applicable from next month's payment cycle.</span></p>

                <p class="MsoBodyText" style="margin-top:7.55pt;margin-right:0in;margin-bottom:
            0in;margin-left:41.05pt;margin-bottom:.0001pt"><span style="color:#666666">In
            case of bank account closure (EFT payment mode), it is the sole responsibility
            of the affiliate to inform us within 15 working days along with the bank's
            attested closure letter. Affiliates are required to update the new bank account
            details in My Account section of their affiliate account. No queries will be
            entertained by Tokoshope in case of failure to intimate us regarding
            bank account closure within the specified timeframe.</span></p>

                <p class="MsoBodyText" style="margin-top:.25pt">&nbsp;</p>

                <p class="MsoListParagraph"><span style="font-size:12.0pt;color:#666666;
            letter-spacing:-.2pt">3.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style="font-size:12.0pt;color:#666666">Why can't I see my
            earnings for recent<span style="letter-spacing:-.1pt"> </span>orders?</span>
                </p>

                <p class="MsoBodyText" style="margin-top:.15pt">&nbsp;</p>

                <p class="MsoBodyText" style="margin-top:0in;margin-right:10.05pt;margin-bottom:
            0in;margin-left:41.05pt;margin-bottom:.0001pt;text-align:justify;line-height:
            100%"><span style="color:#666666">Your earnings for a product will reflect in
            your account only when the order is<span style="letter-spacing:-1.1pt"> </span>delivered. Usually, you should be able to see your earnings for a product approx. 3-5 days after the order is placed</span>
                </p>

                <p class="MsoBodyText" style="margin-top:.5pt"><span style="font-size:11.5pt">&nbsp;</span></p>

                <p class="MsoListParagraph"><span style="font-size:12.0pt;color:#666666;
            letter-spacing:-.2pt">4.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style="font-size:12.0pt;color:#666666">When is the payment<span style="letter-spacing:-.2pt"> </span>made?</span>
                </p>

                <p class="MsoBodyText" style="margin-top:.15pt">&nbsp;</p>

                <p class="MsoBodyText" style="margin-left:41.05pt"><span style="color:#666666">https://tokoshope.com
            has a 30 day cancellation/return policy. Hence products ordered through your
            affiliate links might get cancelled or returned within 30 days of ordering.</span></p>

            </div>

            <span style="font-size:11.0pt;font-family:&quot;Times New Roman&quot;,serif"><br clear="all" style="page-break-before:always">
            </span>

            <div class="WordSection2">

                <p class="MsoBodyText" style="margin-top:3.05pt;margin-right:0in;margin-bottom:
            0in;margin-left:41.05pt;margin-bottom:.0001pt"><span style="color:#666666">We
            make payments to the affiliates only for products which are not returned in
            these 30 days.</span></p>

                <p class="MsoBodyText" style="margin-top:7.45pt;margin-right:0in;margin-bottom:
            0in;margin-left:41.05pt;margin-bottom:.0001pt;line-height:100%"><span style="color:#666666">Hence the payments are made after 30-60 days from the day
            when an order was made through your affiliate account.</span></p>

                <p class="MsoBodyText" style="margin-top:.05pt">&nbsp;</p>

                <p class="MsoListParagraph"><span style="font-size:12.0pt;color:#666666;
            letter-spacing:-.2pt">5.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style="font-size:12.0pt;color:#666666">What are the details
            I have to provide to be able to receive<span style="letter-spacing:-.85pt"> </span>payments?</span>
                </p>

                <p class="MsoBodyText" style="margin-top:.15pt">&nbsp;</p>

                <p class="MsoBodyText" style="margin-left:41.05pt"><span style="color:#666666">To
            receive payments, you will have to submit the following details:</span></p>

                <p class="MsoListParagraph" style="margin-top:7.5pt;margin-right:0in;margin-bottom:
            0in;margin-left:52.25pt;margin-bottom:.0001pt;text-indent:-11.25pt"><span style="font-size:12.0pt;color:#666666;letter-spacing:-.2pt">a.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span style="font-size:12.0pt;color:#666666">Payee Name (Same as<span style="letter-spacing:-.3pt"> </span>PAN)</span>
                </p>

                <p class="MsoListParagraph" style="margin-top:7.45pt;margin-right:0in;margin-bottom:
            0in;margin-left:53.05pt;margin-bottom:.0001pt;text-indent:-12.0pt"><span style="font-size:12.0pt;color:#666666;letter-spacing:-.2pt">b.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span style="font-size:12.0pt;color:#666666">PAN Details</span></p>

                <p class="MsoListParagraph" style="margin-top:7.7pt;margin-right:0in;margin-bottom:
            0in;margin-left:52.25pt;margin-bottom:.0001pt;text-indent:-11.25pt"><span style="font-size:12.0pt;color:#666666;letter-spacing:-.2pt">c.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span style="font-size:12.0pt;color:#666666">IFSC<span style="letter-spacing:-.05pt">
            </span>Code</span>
                </p>

                <p class="MsoListParagraph" style="margin-top:7.45pt;margin-right:0in;margin-bottom:
            0in;margin-left:53.05pt;margin-bottom:.0001pt;text-indent:-12.0pt"><span style="font-size:12.0pt;color:#666666;letter-spacing:-.2pt">d.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span style="font-size:12.0pt;color:#666666">Bank Account<span style="letter-spacing:
            -.15pt"> </span>Number</span>
                </p>

                <p class="MsoListParagraph" style="margin-top:7.45pt;margin-right:0in;margin-bottom:
            0in;margin-left:52.25pt;margin-bottom:.0001pt;text-indent:-11.25pt"><span style="font-size:12.0pt;color:#666666;letter-spacing:-.2pt">e.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span style="font-size:12.0pt;color:#666666">Mailing<span style="letter-spacing:-.05pt">
            </span>Address</span>
                </p>

                <p class="MsoListParagraph" style="margin-top:7.45pt;margin-right:0in;margin-bottom:
            0in;margin-left:51.0pt;margin-bottom:.0001pt;text-indent:-10.0pt"><span style="font-size:12.0pt;color:#666666;letter-spacing:-.2pt">f.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span style="font-size:12.0pt;color:#666666">Contact Number (preferably<span style="letter-spacing:-.15pt"> </span>mobile)</span>
                </p>

                <p class="MsoBodyText" style="margin-top:.15pt">&nbsp;</p>

                <p class="MsoListParagraph"><span style="font-size:12.0pt;color:#666666;
            letter-spacing:-.2pt">6.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style="font-size:12.0pt;color:#666666">Do I have to pay tax
            on my earnings through this<span style="letter-spacing:-.1pt"> </span>program?</span>
                </p>

                <p class="MsoBodyText" style="margin-top:.45pt">&nbsp;</p>

                <p class="MsoBodyText" style="margin-top:0in;margin-right:10.75pt;margin-bottom:
            0in;margin-left:41.05pt;margin-bottom:.0001pt"><span style="color:#666666">As
            per applicable Indian tax laws, we deduct 5% TDS on all payments made through Electronic
            Fund Transfer (EFT) as well as in the form of Gift Vouchers (EGV). Our payments
            to you are referral fees in the form of commission which is covered under
            Section 194H of the Income Tax Act and deduct TDS at the rate of 5% (if PAN is
            provided to us). Please note that we require all your necessary details (PAN,
            Payee Name, Mailing Address, etc) to process the payment. In case of Gift
            Voucher (EGV) payments, if PAN is not provided to us, referral fees would be
            subjected to 20% income tax (TDS) withholding.</span></p>

                <p class="MsoBodyText" style="margin-top:.1pt">&nbsp;</p>

                <p class="MsoListParagraph"><span style="font-size:12.0pt;color:#666666;
            letter-spacing:-.2pt">7.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style="font-size:12.0pt;color:#666666">What happens to my
            referral fees if they do not meet the minimum payment<span style="letter-spacing:
            -1.1pt"> </span>threshold?</span>
                </p>

                <p class="MsoBodyText" style="margin-top:.15pt">&nbsp;</p>

                <p class="MsoBodyText" style="margin-top:0in;margin-right:19.85pt;margin-bottom:
            0in;margin-left:41.05pt;margin-bottom:.0001pt"><span style="color:#666666">If
            we owe you less than Rs. 2500 (if you're being paid by gift voucher) or less
            than Rs. 5000 (if you're being paid by EFT), your fees will be rolled into the next
            month's total.</span></p>

                <p class="MsoBodyText" style="margin-top:.35pt">&nbsp;</p>

                <p class="MsoListParagraph"><span style="font-size:12.0pt;color:#666666;
            letter-spacing:-.2pt">8.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style="font-size:12.0pt;color:#666666">What are the charges
            for the different payment<span style="letter-spacing:-.6pt"> </span>methods?</span>
                </p>

                <p class="MsoBodyText" style="margin-top:.15pt">&nbsp;</p>

                <p class="MsoBodyText" style="margin-top:0in;margin-right:5.1pt;margin-bottom:
            0in;margin-left:41.05pt;margin-bottom:.0001pt"><span style="color:#666666">There
            are no charges applicable on either payment method. However, we deduct 5% TDS
            for all payments made through Electronic Fund Transfer (EFT) as well as in the
            form of Gift Vouchers (EGV). In case of Gift Voucher (EGV) payments, if PAN is
            not provided to us, referral fees would be subjected to 20% income tax (TDS)
            withholding.</span></p>

                <p class="MsoBodyText" style="margin-top:.3pt">&nbsp;</p>

                <p class="MsoListParagraph"><span style="font-size:12.0pt;color:#666666;
            letter-spacing:-.2pt">9.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style="font-size:12.0pt;color:#666666">How do I keep track
            of my earnings and sales?</span></p>

                <p class="MsoBodyText" style="margin-top:.15pt">&nbsp;</p>

                <p class="MsoBodyText" style="margin-left:41.05pt"><span style="color:#666666">An
            extensive earnings report is available with every Affiliate of
            https://tokoshope.com. The report has exhaustive statistics on purchases made and
            commission earned.</span></p>

                <p class="MsoBodyText" style="margin-left:41.05pt;line-height:13.7pt"><span style="color:#666666">Summarized reports may be viewed once an Affiliate is
            signed up.</span></p>

            </div>

            <span style="font-size:11.0pt;font-family:&quot;Times New Roman&quot;,serif"><br clear="all" style="page-break-before:always">
            </span>

            <div class="WordSection3">

                <p class="MsoListParagraph" style="margin-top:3.05pt;margin-right:.55in;
            margin-bottom:0in;margin-left:41.05pt;margin-bottom:.0001pt"><span style="font-size:12.0pt;color:#666666;letter-spacing:-.2pt">10.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span style="font-size:12.0pt;color:#666666">What if a referred sale is cancelled by
            the customer, or is not available anymore<span style="letter-spacing:-1.45pt"> </span>on https://tokoshope.com?
                    </span>
                </p>

                <p class="MsoBodyText" style="margin-top:.15pt">&nbsp;</p>

                <p class="MsoBodyText" style="margin-top:0in;margin-right:10.75pt;margin-bottom:
            0in;margin-left:41.05pt;margin-bottom:.0001pt"><span style="color:#666666">If a
            user returns a product for a refund, or if credit card charges are reversed due
            to a dispute or credit card fraud, the Affiliate's account will be debited for
            any commission earned on that transaction. However, If a product is returned in
            exchange for new merchandise, or if goods are lost or damaged and
            Https://tokoshope.com ships replacement goods to the customer, the Affiliate's
            commission will not be affected.</span></p>

                <p class="MsoBodyText"><span style="font-size:13.0pt">&nbsp;</span></p>

                <p class="MsoBodyText" style="margin-top:.4pt"><span style="font-size:13.0pt">&nbsp;</span></p>

                <p class="MsoNormal" style="margin-left:5.0pt">
                    <a name="PRICING_AND_PAYMENTS_FOR_SELLER"></a><span style="font-size:24.0pt;
            color:#005286">PRICING AND PAYMENTS FOR SELLER</span></p>

                <h1 style="margin-top:15.35pt">
                    <a name="Who_decides_the_price_of_the_product?"></a><span style="color:#333333">Who decides the price of the product?</span></h1>

                <p class="MsoBodyText" style="margin-top:.4pt"><b><span style="font-size:19.0pt">&nbsp;</span></b></p>

                <p class="MsoBodyText" style="margin-left:5.0pt"><span style="color:#333333">As a
            seller, you will set the price of your products.</span></p>

                <p class="MsoBodyText"><span style="font-size:13.0pt">&nbsp;</span></p>

                <p class="MsoBodyText" style="margin-top:.2pt">&nbsp;</p>

                <h1>
                    <a name="What_are_the_fees_charged?"></a><span style="color:#333333">What
            are the fees charged?</span></h1>

                <p class="MsoBodyText" style="margin-top:.35pt"><b><span style="font-size:19.0pt">&nbsp;</span></b></p>

                <p class="MsoBodyText" style="margin-top:.05pt;margin-right:0in;margin-bottom:
            0in;margin-left:5.0pt;margin-bottom:.0001pt"><span style="color:#333333">The
            following deductions are made from the order item value:</span></p>

                <p class="MsoListParagraph" style="margin-top:7.45pt;margin-right:43.7pt;
            margin-bottom:0in;margin-left:41.05pt;margin-bottom:.0001pt"><span style="font-size:10.0pt;font-family:Symbol;color:#333333">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style="font-size:12.0pt;color:#333333">Commission fee: A
            percentage of the order item value vary based on<span style="letter-spacing:
            -.95pt"> </span>vertical/sub- category</span>
                </p>

                <p class="MsoListParagraph" style="margin-top:7.4pt"><span style="font-size:10.0pt;
            font-family:Symbol;color:#333333">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style="font-size:12.0pt;color:#333333">Shipping fee
            (calculated on the basis of the product weight, shipping location and<span style="letter-spacing:-.6pt"> </span>)</span>
                </p>

                <p class="MsoListParagraph" style="margin-top:7.7pt;margin-right:27.45pt;
            margin-bottom:0in;margin-left:41.05pt;margin-bottom:.0001pt"><span style="font-size:10.0pt;font-family:Symbol;color:#333333">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style="font-size:12.0pt;color:#333333">Collection fee: This
            will vary based on order item value and customer payment<span style="letter-spacing:
            -1.0pt"> </span>mode (Prepaid/Cash on<span style="letter-spacing:-.05pt"> </span>Delivery)</span>
                </p>

                <p class="MsoListParagraph" style="margin-top:7.4pt"><span style="font-size:10.0pt;
            font-family:Symbol;color:#333333">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style="font-size:12.0pt;color:#333333">Fixed fee: A slab
            wise Fixed fee. This vary based on Order item value</span></p>

                <p class="MsoListParagraph" style="margin-top:7.45pt"><span style="font-size:
            10.0pt;font-family:Symbol;color:#333333">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style="font-size:12.0pt;color:#333333">GST (applicable on
            all of the above<span style="letter-spacing:-.35pt"> </span>components)</span>
                </p>

                <p class="MsoBodyText"><span style="font-size:13.0pt">&nbsp;</span></p>

                <p class="MsoBodyText"><span style="font-size:13.0pt">&nbsp;</span></p>

                <p class="MsoBodyText" style="margin-top:.3pt">&nbsp;</p>

                <h1>
                    <a name="What_is_Commission_fee_and_how_much_comm"></a><span style="color:#333333">What is Commission fee and how much commission is
            charged?</span></h1>

                <p class="MsoBodyText" style="margin-top:.35pt"><b><span style="font-size:19.0pt">&nbsp;</span></b></p>

                <p class="MsoBodyText" style="margin-top:0in;margin-right:13.15pt;margin-bottom:
            0in;margin-left:5.0pt;margin-bottom:.0001pt"><span style="color:#333333">Commission
            fee is a certain percentage of the order item value of your product. It differs
            across categories and vertical/sub-categories.</span></p>

            </div>

            <span style="font-size:11.0pt;font-family:&quot;Times New Roman&quot;,serif"><br clear="all" style="page-break-before:always">
            </span>

            <div class="WordSection4">

                <p class="MsoNormal" style="margin-top:3.3pt;margin-right:0in;margin-bottom:0in;
            margin-left:5.0pt;margin-bottom:.0001pt">
                    <a name="Please_give_an_example_to_show_the_cost_"></a><i><span style="font-size:
            12.0pt;color:#333333">Please give an example to show the cost calculation.</span></i></p>

                <p class="MsoNormal" style="margin-top:8.7pt;margin-right:0in;margin-bottom:0in;
            margin-left:5.0pt;margin-bottom:.0001pt">
                    <a name="Here’s_an_easy_example,_which_illustrate"></a><i><span style="font-size:
            12.0pt;color:#333333">Here’s an easy example, which illustrates a sample the
            above calculation:</span></i></p>

                <p class="MsoBodyText"><i><span style="font-size:10.0pt">&nbsp;</span></i></p>

                <p class="MsoBodyText"><i><span style="font-size:10.0pt">&nbsp;</span></i></p>

                <p class="MsoBodyText"><i><span style="font-size:13.0pt">&nbsp;</span></i></p>

                <table class="MsoNormalTable" border="1" cellspacing="0" cellpadding="0" style="margin-left:5.75pt;border-collapse:collapse;border:none">
                    <tbody>
                        <tr style="height:23.0pt">
                            <td width="371" valign="top" style="width:277.9pt;border:solid black 1.0pt;
              background:#EBFBFF;padding:0in 0in 0in 0in;height:23.0pt">
                                <p class="TableParagraph" style="margin-top:.3pt;margin-right:16.25pt;
              margin-bottom:0in;margin-left:17.0pt;margin-bottom:.0001pt"><b><span style="font-size:12.0pt">ITEM</span></b></p>
                            </td>
                            <td width="130" valign="top" style="width:97.3pt;border:solid black 1.0pt;
              border-left:none;background:#EBFBFF;padding:0in 0in 0in 0in;height:23.0pt">
                                <p class="TableParagraph" style="margin-left:5.65pt"><b><span style="font-size:
              12.0pt;color:black">AMOUNT (RS.)</span></b></p>
                            </td>
                        </tr>
                        <tr style="height:27.0pt">
                            <td width="371" valign="top" style="width:277.9pt;border-top:none;border-left:
              solid black 1.0pt;border-bottom:solid black 1.5pt;border-right:solid black 1.0pt;
              background:#EBFBFF;padding:0in 0in 0in 0in;height:27.0pt">
                                <p class="TableParagraph" style="margin-top:2.3pt;margin-right:16.5pt;
              margin-bottom:0in;margin-left:17.0pt;margin-bottom:.0001pt"><b><span style="font-size:12.0pt;color:black">Selling Price (decided by you)</span></b></p>
                            </td>
                            <td width="130" valign="top" style="width:97.3pt;border-top:none;border-left:
              none;border-bottom:solid black 1.5pt;border-right:solid black 1.0pt;
              background:#EBFBFF;padding:0in 0in 0in 0in;height:27.0pt">
                                <p class="TableParagraph" style="margin-top:2.3pt"><b><span style="font-size:
              12.0pt;color:black">1500</span></b></p>
                            </td>
                        </tr>
                        <tr style="height:23.0pt">
                            <td width="371" valign="top" style="width:277.9pt;border:solid black 1.0pt;
              border-top:none;background:#EBFBFF;padding:0in 0in 0in 0in;height:23.0pt">
                                <p class="TableParagraph" style="margin-top:1.05pt;margin-right:16.8pt;
              margin-bottom:0in;margin-left:17.0pt;margin-bottom:.0001pt"><span style="font-size:12.0pt;color:black">Shipping Fee (Local shipping , weight
              500 grams)</span></p>
                            </td>
                            <td width="130" valign="top" style="width:97.3pt;border-top:none;border-left:
              none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
              background:#EBFBFF;padding:0in 0in 0in 0in;height:23.0pt">
                                <p class="TableParagraph"><span style="font-size:12.0pt;color:black">35</span></p>
                            </td>
                        </tr>
                        <tr style="height:23.0pt">
                            <td width="371" valign="top" style="width:277.9pt;border:solid black 1.0pt;
              border-top:none;background:#EBFBFF;padding:0in 0in 0in 0in;height:23.0pt">
                                <p class="TableParagraph" style="margin-top:.8pt;margin-right:16.8pt;
              margin-bottom:0in;margin-left:16.8pt;margin-bottom:.0001pt"><span style="font-size:12.0pt;color:black">Collection Fee ( 2 % on the Order item
              value)</span></p>
                            </td>
                            <td width="130" valign="top" style="width:97.3pt;border-top:none;border-left:
              none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
              background:#EBFBFF;padding:0in 0in 0in 0in;height:23.0pt">
                                <p class="TableParagraph"><span style="font-size:12.0pt;color:black">30</span></p>
                            </td>
                        </tr>
                        <tr style="height:22.75pt">
                            <td width="371" valign="top" style="width:277.9pt;border:solid black 1.0pt;
              border-top:none;background:#EBFBFF;padding:0in 0in 0in 0in;height:22.75pt">
                                <p class="TableParagraph" style="margin-top:.3pt;margin-right:16.75pt;
              margin-bottom:0in;margin-left:17.0pt;margin-bottom:.0001pt"><span style="font-size:12.0pt;color:black">Fixed Fee</span></p>
                            </td>
                            <td width="130" valign="top" style="width:97.3pt;border-top:none;border-left:
              none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
              background:#EBFBFF;padding:0in 0in 0in 0in;height:22.75pt">
                                <p class="TableParagraph"><span style="font-size:12.0pt;color:black">40</span></p>
                            </td>
                        </tr>
                        <tr style="height:23.0pt">
                            <td width="371" valign="top" style="width:277.9pt;border:solid black 1.0pt;
              border-top:none;background:#EBFBFF;padding:0in 0in 0in 0in;height:23.0pt">
                                <p class="TableParagraph" style="margin-top:.3pt;margin-right:16.6pt;
              margin-bottom:0in;margin-left:17.0pt;margin-bottom:.0001pt"><span style="font-size:12.0pt;color:black">Total Marketplace Fee</span></p>
                            </td>
                            <td width="130" valign="top" style="width:97.3pt;border-top:none;border-left:
              none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
              background:#EBFBFF;padding:0in 0in 0in 0in;height:23.0pt">
                                <p class="TableParagraph"><span style="font-size:12.0pt;color:black">105</span></p>
                            </td>
                        </tr>
                        <tr style="height:22.75pt">
                            <td width="371" valign="top" style="width:277.9pt;border:solid black 1.0pt;
              border-top:none;background:#EBFBFF;padding:0in 0in 0in 0in;height:22.75pt">
                                <p class="TableParagraph" style="margin-top:.8pt;margin-right:16.7pt;
              margin-bottom:0in;margin-left:17.0pt;margin-bottom:.0001pt"><span style="font-size:12.0pt;color:black">GST ( 18% on Marketplace fee deduction)</span></p>
                            </td>
                            <td width="130" valign="top" style="width:97.3pt;border-top:none;border-left:
              none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
              background:#EBFBFF;padding:0in 0in 0in 0in;height:22.75pt">
                                <p class="TableParagraph"><span style="font-size:12.0pt;color:black">18.9</span></p>
                            </td>
                        </tr>
                        <tr style="height:23.0pt">
                            <td width="371" valign="top" style="width:277.9pt;border:solid black 1.0pt;
              border-top:none;background:#EBFBFF;padding:0in 0in 0in 0in;height:23.0pt">
                                <p class="TableParagraph" style="margin-top:.3pt;margin-right:16.7pt;
              margin-bottom:0in;margin-left:17.0pt;margin-bottom:.0001pt"><b><span style="font-size:12.0pt;color:black">Total deductions</span></b></p>
                            </td>
                            <td width="130" valign="top" style="width:97.3pt;border-top:none;border-left:
              none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
              background:#EBFBFF;padding:0in 0in 0in 0in;height:23.0pt">
                                <p class="TableParagraph"><b><span style="font-size:12.0pt;color:black">123.9</span></b></p>
                            </td>
                        </tr>
                        <tr style="height:22.75pt">
                            <td width="371" valign="top" style="width:277.9pt;border:solid black 1.0pt;
              border-top:none;background:#EBFBFF;padding:0in 0in 0in 0in;height:22.75pt">
                                <p class="TableParagraph" style="margin-top:.3pt;margin-right:16.75pt;
              margin-bottom:0in;margin-left:17.0pt;margin-bottom:.0001pt"><b><span style="font-size:12.0pt;color:black">Settlement Value (Amount credited to
              you)</span></b></p>
                            </td>
                            <td width="130" valign="top" style="width:97.3pt;border-top:none;border-left:
              none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
              background:#EBFBFF;padding:0in 0in 0in 0in;height:22.75pt">
                                <p class="TableParagraph"><b><span style="font-size:12.0pt;color:black">1376.1</span></b></p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p class="MsoBodyText"><i><span style="font-size:10.0pt">&nbsp;</span></i></p>

                <h1 style="margin-top:10.3pt">
                    <a name="How_and_when_do_I_get_paid?"></a><span style="color:#333333">How and when do I get paid?</span></h1>

                <p class="MsoBodyText"><b><span style="font-size:13.0pt">&nbsp;</span></b></p>

                <p class="MsoBodyText" style="margin-top:8.25pt;margin-right:1.1pt;margin-bottom:
            0in;margin-left:5.0pt;margin-bottom:.0001pt"><span style="color:#333333">All
            payments are made through NEFT transactions (online banking). The payment is
            made directly to your bank account within the next 7-15 business days from the
            date of order dispatch. It's 7 business days for Gold Sellers, 10 business days
            for Silver Sellers and 15 business days for Bronze sellers.</span></p>

            </div>

            <span style="font-size:11.0pt;font-family:&quot;Times New Roman&quot;,serif"><br clear="all" style="page-break-before:always">
            </span>

            <div class="WordSection5">

                <p class="MsoBodyText" style="margin-top:.2pt"><span style="font-size:8.5pt">&nbsp;</span></p>

            </div>


        </div>
    </div>
</section>