<section class="top-category section-padding">
    <div class="container">
        <h4>Categories</h4>

        <div class="owl-carousel owl-carousel-category-ra" [ngClass]="{'loading': loading == true}">
            <div class="item" *ngFor="let cat of cats; let i = index;">
                <div class="category-item">
                    <a href="javascript:void(0);" type="button" (click)="setCat(cat)">
                        <img class="img-fluid" [src]="cat.picture ? apiUrl+cat.picture : 'assets/img/item/11.jpg' " alt="category image">
                        <!-- <img class="img-fluid" [src]="cat.picture ? apiUrl+cat.picture : 'assets/img/item/11.jpg' " alt="category image" [class.bluri]="cat.totalProds < 1"> -->
                        <h6>{{cat.name}}</h6>
                        <!-- <p *ngIf="cat.totalProds">{{cat.totalProds}}</p> -->
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>