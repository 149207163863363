import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './components/account/account.component';
import { AddressComponent } from './components/account/address/address.component';
import { ElitePlanComponent } from './components/account/elite-plan/elite-plan.component';
import { MyOrdersComponent } from './components/account/my-orders/my-orders.component';
import { OrderDetailComponent } from './components/account/order-detail/order-detail.component';
import { ProfileComponent } from './components/account/profile/profile.component';
import { RegisterUserComponent } from './components/account/register-user/register-user.component';
import { WishlistComponent } from './components/account/wishlist/wishlist.component';
import { AuthComponent } from './components/auth/auth.component';
import { CartSidebarComponent } from './components/cart-sidebar/cart-sidebar.component';
import { CategoryListComponent } from './components/category/category-list/category-list.component';
import { CategoryComponent } from './components/category/category.component';
import { HomeComponent } from './components/home/home.component';
import { CheckoutComponent } from './components/orders/checkout/checkout.component';
import { MyCartComponent } from './components/orders/my-cart/my-cart.component';
import { AboutusComponent } from './components/pages/aboutus/aboutus.component';
import { ContactusComponent } from './components/pages/contactus/contactus.component';
import { ErrorpageComponent } from './components/pages/errorpage/errorpage.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { PaymentComponent } from './components/pages/payment/payment.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';
import { ReturnComponent } from './components/pages/return/return.component';
import { TermsComponent } from './components/pages/terms/terms.component';
import { ProductComponent } from './components/product/product.component';
import { RegisterUserGuard } from './register-user.guard';
import { CartsResolverService } from './resolvers/carts-resolver.service';
import { ProfileResolverService } from './resolvers/profile-resolver.service';
import { WishlistResolverService } from './resolvers/wishlist-resolver.service';
import { RouteAuthGuard } from './route-auth.guard';
import { ServiceCheckerGuard } from './service-checker.guard';

const routes: Routes = [
	{
		path:'register',
		component:RegisterUserComponent
	},
	{
		path: '',
		component: HomeComponent,
		canActivate:[RegisterUserGuard],
		resolve:{
		  w:WishlistResolverService,
		  c:CartsResolverService,
		  p:ProfileResolverService
		}
	},
	{
		path: 'category-list',
		component: CategoryListComponent,
		canActivate:[RegisterUserGuard, ServiceCheckerGuard],
		resolve:{
		  w:WishlistResolverService,
		  c:CartsResolverService,
		  p:ProfileResolverService
		}
	},
	{
		path: 'category',
		canActivate:[RegisterUserGuard, ServiceCheckerGuard],
		resolve:{
		  w:WishlistResolverService,
		  c:CartsResolverService,
		  p:ProfileResolverService
		},
		children: [
			{
				path: ':id',
				component: CategoryComponent
			},
			{
				path: ':id/:prod_id',
				component: ProductComponent
			},
			{
				path:"**",
				component:ErrorpageComponent
			}
		]
	},
	{
		path: 'aboutus',
		component: AboutusComponent
	},
	{
		path: 'privacy',
		component: PrivacyComponent
	},
	{
		path: 'terms',
		component: TermsComponent
	},
	{
		path: 'payment',
		component: PaymentComponent
	},
	{
		path: 'return',
		component: ReturnComponent
	},
	{
		path: 'checkout',
		component: CheckoutComponent,
		canActivate:[RouteAuthGuard],
		resolve:{
		  w:WishlistResolverService,
		  c:CartsResolverService,
		  p:ProfileResolverService
		}
	},
	{
		path: 'cart',
		component: MyCartComponent,
		canActivate:[RouteAuthGuard,ServiceCheckerGuard],
		resolve:{
		  w:WishlistResolverService,
		  c:CartsResolverService,
		  p:ProfileResolverService
		}
	},
	{
		path: 'contact',
		component: ContactusComponent,
		resolve:{
			w:WishlistResolverService,
			c:CartsResolverService,
			p:ProfileResolverService
		},  
	},
	{
		path: 'account',
		component: AccountComponent,
        canActivate:[RegisterUserGuard, RouteAuthGuard],
		resolve:{
		  w:WishlistResolverService,
		  c:CartsResolverService,
		  p:ProfileResolverService
		},
		children:[
			// {
			// 	path: 'profile',
			// 	component: ProfileComponent
			// },
			{
				path: 'address',
				component: AddressComponent
			},
			{
				path: 'elite-plans',
				component: ElitePlanComponent
			},
			{
				path: 'wishlist',
				component: WishlistComponent
			},
			{
				path: 'orders',
				// canActivate:[ServiceCheckerGuard],
				component: MyOrdersComponent
			},
			{
				path: 'order-detail/:id',
				// canActivate:[ServiceCheckerGuard],
				component: OrderDetailComponent
			},
			{
				path: '',
				component: AddressComponent
			},
			{
				path:"**",
				component:ErrorpageComponent
			}
		]
	},
	{
		path: 'faq',
		component: FaqComponent
	},
	{
		path: '404error',
		component: ErrorpageComponent
	},
	{
        path:"**",
        component:ErrorpageComponent
	}

];

@NgModule({
	imports: [RouterModule.forRoot(routes,{
		scrollPositionRestoration: 'enabled'
	})],
	exports: [RouterModule],
})
export class AppRoutingModule {}
