import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ProfileService } from '../services/profile.service';
import { WishlistService } from '../services/wishlist.service';

@Injectable({
  providedIn: 'root'
})
export class WishlistResolverService implements Resolve<any> {
  constructor(private pfService:ProfileService,private wlService:WishlistService){}
  resolve(){
      if(this.pfService.isUserLoggedIn()){
          this.wlService.getMyWishList();
      }
  }
}
