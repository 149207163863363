<section class="offer-product my-4">
    <div class="container">

        <div class="flex-wrap no-gutters d-flex card-group">
            <div class="align-self-center card flex-fillz" *ngFor="let s of [1,2,3];">
                <div class="carousel-slider-main text-center border-top border-bottom bg-white">
                    <div class="owl-carousel owl-carousel-slider-ra">
                        <div class="item" *ngFor="let bb1 of slider['bottomBanners'+s]; let i=index;">
                            <!-- <a href="javascript:void(0)"> -->
                            <img [src]="apiUrl + bb1" alt="{{i}} bb" class="d-block img-fluid mx-auto" style="height: 300px;">
                            <!-- </a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>