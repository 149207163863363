import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
 import {getApiUrl} from '../utils/';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  constructor(){}


  static get subscribeUser():string{
    return getApiUrl(true)+'/cust/subscribe';
  }

  static get completeSubscription():string{
    return getApiUrl(true)+'/cust/completeSubs';
  }

  static getAuthHeader():HttpHeaders{
    return new HttpHeaders().set('authorization','Bearer '+localStorage.getItem('session_user'));
  }

  static getHeaderIfUserExists():HttpHeaders{
    if(localStorage.getItem('session_user')&& localStorage.getItem('userFound')=='FOUND'){
        return NetworkService.getAuthHeader();
    }else{
      return new HttpHeaders();
    }
  }

  static get removeOrderAndRestoreCart():string{
    return getApiUrl(true)+'/order/deleteOrderAndRestoreCart';
  }

  static get deletePendingOrder():string{
    return getApiUrl(true)+'/order/deletePendingOrder';
  }

  static get completeRazorPayment():string{
    return getApiUrl(true)+'/order/completeRazorPayment';
  }

  static getSessionToken():string{
    return localStorage.getItem('session_user');
  }  

  //-->Product
  static getCats():string{
    return getApiUrl(true)+'/prod/getCats';
  }

  static getSubCats():string{
    return getApiUrl(true)+'/prod/getSubCats';
  }

  static getCatsAndSubs():string{
    return getApiUrl(true)+'/prod/getCatAndSubs';
  }

  static expandProduct():string{
    return getApiUrl(true)+'/prod/viewProd';
  }

  static expandProductText():string{
    return getApiUrl(true)+"/prod/viewProdTxture";
  }

  static searchProduct():string{
    return getApiUrl(true)+'/prod/getProdsWeb';
  }

  static getTotalLengthProduct():string{
    return getApiUrl(true)+'/prod/getProdsWebCount';
  }

  static getBrands():string{
    return getApiUrl(true)+'/prod/getBrands';
  }

  //-->Profile
  static searchUser():string{
    return getApiUrl(true)+'/cust/checkUser';
  }

  static getOtp():string{
    return getApiUrl(true)+'/cust/getOtp';
  }

  static login():string{
    return getApiUrl(true)+'/cust/auth';
  }

  static loginGoogle():string{
    return getApiUrl(true)+'/cust/authenticate';
  }

  static register():string{
    return getApiUrl(true)+'/cust/register';
  }

  static sendToken():string{
    return getApiUrl(true)+'/cust/sendFbToken';
  }

  static myProfile():string{
    return getApiUrl(true)+'/cust/getProfile';
  }

  static updateProfile():string{
    return getApiUrl(true)+'/cust/updateCustProfile'
  }

  static get sendMessage():string{
    return getApiUrl(true)+'/cust/sendMessage';
  }

  //-->Wish List
  static myWishLists():string{
    return getApiUrl(true)+'/prod/myWishLists';
  }

  static addToFavourites():string{
    return getApiUrl(true)+'/prod/addToWishList';
  }

  static removeFromFavourites():string{
    return getApiUrl(true)+'/prod/removeFromWishList';
  }

  static clearFavourites():string{
    return getApiUrl(true)+'/prod/clearWishlist';
  }


  //-->Cart
  static addToCart():string{
    return getApiUrl(true)+'/order/addToCart';
  }

  static removeFromCart():string{
    return getApiUrl(true)+'/order/deleteACart';
  }

  static updateCart():string{
    return getApiUrl(true)+'/order/updateQtyCart';
  }

  static myCarts():string{
    return getApiUrl(true)+'/order/getMyCarts';
  }

  static vendorWiseCarts():string{
    return getApiUrl(true)+'/order/getMyCartsVendorWise';
  }
  
  static clearCart():string{
    return getApiUrl(true)+'/order/clearCart';
  }

  //-->Order
  static checkout():string{
    return getApiUrl(true)+"/order/checkout";
  }

  static checkWallet():string{
    return getApiUrl(true)+"/order/checkWallet";
  }

  static setAddress():string{
    return getApiUrl(true)+"/order/setOrderAddress";
  }

  static setPayment():string{
    return getApiUrl(true)+"/order/setPaymentMethod";
  }

  static get myOrders():string{
    return getApiUrl(true)+"/order/getMyOrders";
  }

  static cancelOrder():string{
    return getApiUrl(true)+"/order/cancelOrder";
  }

  static placeOrder():string{
    return getApiUrl(true)+"/order/makeOrderAllInOne";
  }

  static getProductReviews():string{
    return getApiUrl(true)+"/prod/getProductReviews";
  }

  static addReview():string{
    return getApiUrl(true)+"/prod/addReview";
  }

  static editReview():string{
    return getApiUrl(true)+"/prod/editReview";
  }

  static deleteReview():string{
    return getApiUrl(true)+"/prod/deleteReview";
  }

  static myReviews():string{
    return getApiUrl(true)+"/prod/myReviews";
  }

  static getCollections():string{
    return getApiUrl(true)+"/prod/getCollectionCust";
  }

  static getCollectionProd():string{
    return getApiUrl(true)+"/prod/getCollectionProductsCust";
  }

  static getProductSubWise():string{
    return getApiUrl(true)+"/prod/getProdsSubWise";
  }

  static addAddress():string{
    return getApiUrl(true)+"/cust/addAddress";
  }

  static deleteAddress():string{
    return getApiUrl(true)+"/cust/deleteAddress";
  }

  static getAddress():string{
    return getApiUrl(true)+"/cust/getAddress";
  }

  static editAddress():string{
    return getApiUrl(true)+"/cust/updateAddress";
  }

  static viewOrder():string{
    return getApiUrl(true)+"/order/expandOrder";
  }

  static get getSlider():string{
    return getApiUrl(true)+"/settings/getSlider";
  }

  static get getProcessed():string{
    return getApiUrl(true)+'/order/getProcessed';
  }

  static get returnProduct():string{
    return getApiUrl(true)+'/order/returnProduct';
  }

  static get replaceProduct():string{
    return getApiUrl(true)+'/order/replaceProduct';
  }

  static get myReturns():string{
    return getApiUrl(true)+'/order/myReturns';
  }

  static get myReplaces():string{
    return getApiUrl(true)+'/order/myReplaces';
  }

  static get getSiblings():string{
    return getApiUrl(true)+'/prod/getSiblings';
  }

  static get getTimeSlots():string{
    return getApiUrl(true)+'/order/getOrderTimeSlots';
  }

  static get selectAddress():string{
    return getApiUrl(true)+'/cust/selectAddress';
  }
  
  static get removeAddress():string{
    return getApiUrl(true)+'/cust/deleteAddress';
  }

  static get isServiceAvailable():string{
    return getApiUrl(true)+'/cust/isServiceAvailable';
  }


  static get getSettings():string{
    return getApiUrl(true)+'/settings/get';
  }

  static get getElitePlans():string{
    return getApiUrl(true)+'/elite/get';
  }

  static get subscribeElite():string{
    return getApiUrl(true)+'/elite/subscribe';
  }
  static get completeEliteSubscription():string{
    return getApiUrl(true)+'/elite/paymentCallback';
  }

  static get getMyVendor():string{
    return getApiUrl(true)+'/vendor/getMyVendor';
  }

}
