import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ProfileService } from '../services/profile.service';
import { CartService } from '../services/cart.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartsResolverService implements Resolve<any>{

  constructor(private pfService:ProfileService,private ctService:CartService) { }
  
  // resolve(){
  //   if(this.pfService.isUserLoggedIn()){
  //       this.ctService.getMyCarts().subscribe(result=>//console.log("CARTS",result));
  //   }
  // }

  resolve( route: ActivatedRouteSnapshot,
           state: RouterStateSnapshot):Observable<any>|Promise<any>|any
    {
          return this.ctService.getMyCarts();
    }

  // constructor(private pfService:ProfileService,private wlService:WishlistService){}
  // resolve(){
  //     if(this.pfService.isUserLoggedIn()){
  //         this.wlService.getMyWishList();
  //     }
  // }
}
