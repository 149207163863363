import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faBeer, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { AlertService } from 'src/app/services/alert.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { ProfileService } from 'src/app/services/profile.service';
declare var $: any;

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  faSignin=faSignInAlt;
  faBeer=faBeer;
  faGoogle:any;
  email:string;
  password:string;
  login:FormGroup;
  submitted:boolean=false;
  socialUser:SocialUser;
  //socialUser:any;
  emailError:boolean=false;
  passwordError:boolean=false;
  loading:boolean=false;
  loggedIn:boolean;

  constructor(
    private auth:SocialAuthService,
    private pfService:ProfileService,
    private altService:AlertService,
    private router:Router,
    private msg:MessagingService,
    private fAuth:AngularFireAuth
  ) {
    // console.log('auth contstructor called');
    
   }  

  ngOnInit(): void {
    // console.log('auth ngOnInit called');

    if(this.pfService.isUserLoggedIn())
    {
        this.loggedIn = true;
        return;
    }
    else{
      this.loggedIn = false;
      // console.log('auth isUserLoggedIn false');
    }
  }

  signInWithGoogle():void{   
    // console.log('auth signInWithGoogle called');

    this.loading=true;
    
    this.auth.signIn(GoogleLoginProvider.PROVIDER_ID).then(user=>{
      this.socialUser=user;
      console.log('signInWithGoogle user ', user);
      
      this.loginInWithGoogle();
    }).catch(err=>{
      console.log('signInWithGoogle err ', err);

      this.loading=false;
      this.altService.showAlertVW(err.message,this.altService.alertTypes.danger);
    })
  }

  loginInWithGoogle():void{
    // console.log('loginInWithGoogle ', this.socialUser);

    this.pfService.loginWithGoogle({'oauth-token':this.socialUser.idToken}).subscribe(r=>{
      console.log(r);
      this.loading=false;
      if(r.success=true){
        console.log('if ', r.success);
        
        localStorage.setItem('session_user',r.payload.token);
        if(r.payload.isFirstTime){
  
          console.log('r.payload.isFirstTime ', r.payload.isFirstTime);

          localStorage.setItem('userFound','NOT_FOUND');
          localStorage.setItem('service-available','N');
          // $('#register-tab-show-btn').click();
          this.altService.showAlertVW('User Not Found, Kindly register user details ',this.altService.alertTypes.danger);
          $('#login-signup-open-btn').click();
          // $('#login-signup-close-btn').click();

          setTimeout(() => {
            this.router.navigate(['/register']);            
          }, 100);
          // this.auth.signOut();
        }else{
          console.log('r.payload.isFirstTime not ', r.payload.isFirstTime);

          localStorage.setItem('userFound','FOUND');
          localStorage.setItem('service-available',r.payload.isServiceAvailable ?'Y':'N');
          localStorage.setItem('notify','YES');
          this.altService.showAlertVW('Login Successfully',this.altService.alertTypes.success);
          $('#login-signup-close-btn').click();
          location.reload();
          // document.location.href='/';
        }
      }else{
        console.log('else ', r.success);

        this.altService.showAlertVW(r.message,this.altService.alertTypes.danger);
        return;
      }
    },
    err=>{
      console.log('err ', err);

      this.loading=false;
      this.altService.showAlertVW(err.message,this.altService.alertTypes.danger);
    });
  }
  
  logout():void{
    this.pfService.logout();
    this.loggedIn=false;
    $('#login-signup-close-btn').click();    
    document.location.href="/";
  }

  normalSignIn():void{
    // console.log('auth normalSignIn called');

    this.submitted=true;
    if(this.login.invalid){
      return;
    }
    $(".btn-sign-in").addClass("btn-loading");

    this.pfService.login(this.login.value).subscribe(r=>{
        if(r.success){
          localStorage.setItem('session_user',r.payload.authToken);
          localStorage.setItem('userFound','FOUND');
          localStorage.setItem('notify','YES');
         // this.msg.requestPermission();
          document.location.href='/';
          this.router.navigate(['/']);
        }else{
          this.altService.showAlertVW(r.message,this.altService.alertTypes.danger);
        }

        $(".btn-sign-in").removeClass("btn-loading");
    });
  }

  setValue(e){
    // console.log('auth setValue called');

    //console.log(e.target.value);
    this.submitted=false;
    this[e.target.name]=e.target.value;
  }

}
