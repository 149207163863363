import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { CartService } from 'src/app/services/cart.service';
import { DataService } from 'src/app/services/data.service';
import { GeneralService } from 'src/app/services/general.service';
import { ProductsService } from 'src/app/services/products.service';
import { ProfileService } from 'src/app/services/profile.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import {getApiUrl} from '../../utils/';
declare var $:any;  

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  loggedIn:boolean = false;
  registeredUser:boolean = false;

  h="<em>Hello</em>";
  wlCount:number=0;
  getApiUrl:any=getApiUrl();
  cartCount:number=0;
  subTotal:any=0;
  carts:any[]=[];
  cat:any=null;
  cats:any=[];
  searchTxt:string="";
  productSuggestions:any=[];
  balanceResult:number=0;
  searching:boolean=false;
  profile:any=null;
  currentAddress:any=null;

  constructor(
    private router:Router,
    private wlService:WishlistService,
    private ctService:CartService,
    private pfService:ProfileService,
    private pdService:ProductsService,
    private dtService:DataService,
    private altService:AlertService
  ) {

    this.loggedIn=this.pfService.isUserLoggedIn();
    this.registeredUser=this.pfService.isUserRegistered();

    if(this.loggedIn == true){
      this.pfService.onPChange.subscribe(v=>{
        this.profile=this.pfService.getLocalProfile();              
      });
      
      this.wlService.wishlistCount.subscribe(v=>{
        this.wlCount=v;
      });
    
      this.ctService.cartCount.subscribe(v=>{
        this.cartCount=v[0]?v[0]:0;
        this.subTotal=v[1]?v[1]:0;
        let {carts,subTotal}=this.ctService.getLocalCarts();
        this.carts=carts;
        this.subTotal=subTotal;
      });  
    }
   }

  scroller():void{
    let offset=$('.header-wrap-inner')[0].offsetTop;
    ////console.log(window.pageYOffset,offset);
    if(window.pageYOffset>offset){
      $('.header-wrap-inner').addClass("sticky");
      $('.header-wrap-inner').addClass("show");
    }else{
      $('.header-wrap-inner').removeClass("sticky");
      $('.header-wrap-inner').removeClass("show");
    }   
    //this.wlService.getMyWishList();
  }

  ngOnInit(): void {
    // window.onscroll=this.scroller;

  
  }

  ngAfterViewInit():void{
    setTimeout(() => {
      // console.log(this.profile.address);
      this.pfService.getCurrentAddress().subscribe(response => {
        // console.log(response);
        this.currentAddress = response;
      })
  
      if(this.profile != null){
        this.currentAddress = this.profile.address.find(a=>a._id==this.profile.selectedAddress);
        this.pfService.currentAddress.next(this.currentAddress);
      }
          
    }, 1000);


  }

  showSideCart(){
    if(this.pfService.isUserLoggedIn()){
      let obj:any=this.ctService.getLocalCarts();
      this.carts=obj.carts;
      this.subTotal=obj.subTotal;
      $('body').toggleClass('toggled');
    }else{
      $('#login-signup-open-btn').click();
    }
  }

  get defaultLocation():string{
    return localStorage.getItem("default-location");
  }

  setVal(e){
    this.searchTxt=e.target.value;
    this.searchProducts();
  }

  searchProducts(){
    if(!this.searchTxt){
      this.productSuggestions=[];
      return;
    }
    
    this.searching=true;
    var requestBody:any={searchTxt:this.searchTxt};
  
  
    if(this.cat && this.cat._id)
      requestBody.categoryId=this.cat._id;
  
    this.pdService.searchProduct(requestBody).subscribe(r=>{
      this.searching=false;
     
      if(r.success){
        this.productSuggestions=r.payload.products || [];
        // if(this.productSuggestions.length!=0)
          // $(".search-suggestions").css("display","block");
        setTimeout(()=>{
          // $(".suggestions").mark(this.searchTxt,{element:"em"});
        },100);  
      }else{
        this.productSuggestions=[];
        this.productSuggestions.length=0;
        this.altService.showAlertVW(r.message, this.altService.alertTypes.danger)
      }
    });
  }

  search(){
    let self=this;
  
    if(self.cat){
      this.dtService.setSearchItem({
        _id:self.cat._id,
        searchType:"categoryId",
        picture:self.cat.picture,
        name:self.cat.name
      });
    }else{
      this.dtService.clearSearchItem();
    }
    
    this.dtService.searchTxt=self.searchTxt;
  
    if(this.router.url=='/app/shop')
      this.dtService.search.next(true);
    else
      this.router.navigate(['/app/shop']);  
  }

  clearSuggestions(){
    this.productSuggestions=[];

    // $(".search-suggestions").css("display","none");
  }
  
  scrollTo(){
    
  }
  
  showSearch(){
    // $('.header-search-sm-form').addClass('active');
  }

  hideSearch(){
    // $('.header-search-sm-form').removeClass('active');
  }

  get getProdSuggestions(){
    return this.productSuggestions;

    // if(this.productSuggestions.length<=20)  
    // this.balanceResult=this.productSuggestions-20;
    // return this.productSuggestions.splice(0,20);
  }
  
  logout():void{
    this.pfService.logout();
    this.loggedIn=false;
    document.location.href="/";
  }
}
