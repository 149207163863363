<nav class="osahan-menu navbar navbar-light navbar-expand-lg bg-faded osahan-menu sticky-top" style="background-color: #001c4e;">
    <div class="container-fluid">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/logo.png" *ngIf="!profile || !profile.isElite" alt="logo" height="50" width="80">
            <img src="assets/img/logoPrime2.png" *ngIf="profile && profile.isElite" alt="logo" height="50" width="80">
        </a>

        <div class="navbar-collapse d-flex flex-wrap mb-2 mb-md-0" id="navbarNavDropdown">
            <!-- pincode location start-->
            <div class="d-none headerPincode1">
                <a class="location-top" routerLink="/account/address"><i
                        class="mdi mdi-map-marker-circle" aria-hidden="true"></i>
                    <span class="d-none"  *ngIf="currentAddress != null">{{(loggedIn && profile && currentAddress.pinCode) ? currentAddress.pinCode : defaultLocation}}</span></a>
            </div>
            <!-- pincode location end-->


            <!-- search input start -->
            <div class="navbar-nav mx-lg-5 mx-1 flex-fill">
                <div class="top-categories-search mb-md-2 mb-0 w-100 d-block position-relative">
                    <div class="input-group">
                        <input type="text" name="query" autocomplete="off" placeholder="Search products in Your Location" aria-label="Search products in Your Location" class="form-control search-input" (input)="setVal($event)">
                    </div>
                    <!-- Suggestions Start -->
                    <div class="search-suggestions w-100 " *ngIf="searchTxt && productSuggestions">
                        <div class="search-suggestions-inner custom-scrollbar">
                            <div class="product-suggestion" *ngIf="productSuggestions.length!=0; else noResultsFound">
                                <h6 class="title">Product Suggestions <span class="float-right">{{productSuggestions.length}} product(s) found</span></h6>
                                <ul class="list-inline product-suggestion-list">
                                    <li class="list-item" *ngFor="let ps of getProdSuggestions;">
                                        <a href="javascript:void(0);" class="single-item" [routerLink]="['/category/'+ps.categoryId+'/'+ps._id]" (click)="clearSuggestions()">
                                            <div class="product-image">
                                                <img [src]="ps.pictures && ps.pictures[0] ? getApiUrl+ps.pictures[0] : 'assets/img/item/11.jpg'" alt="product image" class="">
                                            </div>
                                            <div class="product-info">
                                                <div class="product-info-top">
                                                    <h6 class="product-name suggestions">{{ps.name}}</h6>
                                                    <!---->
                                                </div>
                                                <div class="product-price" *ngIf="profile == null">{{ps.price | currency:"INR"}}
                                                </div>
                                                <div class="product-price" *ngIf="profile && profile.isElite==true">
                                                    {{ps.elitePrice | currency:"INR"}}</div>
                                                <div class="product-price" *ngIf="profile && profile.isElite==false">
                                                    {{ps.offerPrice | currency:"INR"}}</div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                                <!-- <a href="javascript:void(0);" *ngIf="balanceResult" class="more-results">
                                    {{balanceResult+" more results"}}
                                </a> -->
                            </div>
                            <ng-template #noResultsFound>
                                <p class="m-2 text-center m-0">No Results Found</p>
                            </ng-template>
                        </div>
                    </div>
                    <!-- Suggestions End -->
                </div>

            </div>
            <!-- search input end -->


            <!-- action button start -->
            <div class=" my-2 my-lg-0">
                <ul class="list-inline main-nav-right text-center">
                    <!-- <li class="list-inline-item cart-btn headerPincode2 d-sm-none">
                        <a class="location-top" class="btn btn-link" routerLink="/account/address">
                            <i class="mdi mdi-map-marker-circle" aria-hidden="true"></i>
                                <span class="" *ngIf="currentAddress != null">{{(loggedIn && profile && currentAddress.pinCode) ? currentAddress.pinCode : defaultLocation}}</span>
                            </a>
                    </li> -->

                    <li class="list-inline-item cart-btn d-md-inline d-none">
                        <a *ngIf="!loggedIn" href="javascript:void(0)" data-target="#bd-example-modal" id="login-signup-open-btn" data-toggle="modal" class="btn btn-link"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABKUlEQVRIS8VVwVHDMBDcrYCUQAdABSGVAB1ARYRKSCqAdAIVLLPMOePIF8lOxuOb0cc6a3W3eyti5uDM52N5AEmPAJ4A3Mdy0d+xPkjual04W4GkWwDvAAxQCwO8kTToIFIASb7tJ4DVSI5+AGwykAFA3PxrwuHdHVKQDMAlr0fevEzbkdz0P54ABKFuTRYvwUkL2606El8CbEMxQ7JIBjf++aaCYmU9d/slgJVwl6qB/M+VZOINkuZZviQfUoBW7f19SdVqlwGQNLZFFoJnJYsDyePeJSS3BrBKsm1hPpmGSq4ZtD3JE+86ZxXmoqb1rPe/NsbSj2pm1xqoPkh6uBNadm2tt3xpD+B1kl0XA+WeevQtvW56D/HgbC9+cKZMdS13+Tf52kr+ALUpdBlvarUkAAAAAElFTkSuQmCC"
                            /> Login</a>
                        <a *ngIf="loggedIn" href="Javascript:void(0)" data-target="#bd-example-modal-logout" id="login-signup-close-btn" data-toggle="modal" class="btn btn-link"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABIklEQVRIS8WV0Y3CMBBEZyo4OoGrAKgE6ICrBDoAKgE6gE7uKlg0p3UUHDvERhEr5SdO9tkzu2ti5ODI+fF5gJktAKwAzPzRoW/+nEhe+lTInsDMlHAHQIC+EOCHpKCdSAI8+RnAZKBHvwCWKUgHUJE87CEJSQF05PnAncefXUgu2y+fAG6opAmxAXAohEmqxvgYcPSK+ZO50tTMrBCgylqHf2JAqAQll6aoANxIfucAxzb91c5zcJLNxjsSjQ2QRNJcRtVKdCepJv2PnMlNTVd40GuyxsJ4ZepV806jXUk+za7cqBDk61UVRetN72Q7OSz4PCqBJJN3TG6THbIfMJeuALZF4zoCSVO1vkpv6mt3v3DUmHUXTqH+2c8/fye/e5IH7EODGeaF4IkAAAAASUVORK5CYII="
                            /> Logout</a>
                    </li>
                    <li class="list-inline-item cart-btn mr-3 d-none d-sm-inline">
                        <a href="javascript:void(0)" routerLink="/account/wishlist" class="btn btn-link border-none"><i
                                class="mdi mdi-heart"></i>Wish<small class="cart-value">{{wlCount}}</small></a>
                    </li>
                    <li class="list-inline-item cart-btn">
                        <a href="javascript:void(0)" (click)="showSideCart()" class="btn btn-link border-none"><i
                                class="mdi mdi-cart"></i> {{subTotal | currency:"INR"}} <small
                                class="cart-value">{{cartCount}}</small></a>
                    </li>

                    <li class="list-inline-item cart-btn">
                        <!-- toggle button start -->
                        <button class="navbar-toggler navbar-toggler-white" (click)="scrollTo();" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon" style="height: 1em !important; width: 1em !important;"></span>
                        </button>
                        <!-- toggle button end -->

                        <!-- <a href="javascript:void(0)" (click)="showSideCart()" class="btn btn-link border-none"><i class="mdi mdi-cart"></i> {{subTotal | currency:"INR"}} <small class="cart-value">{{cartCount}}</small></a> -->
                    </li>

                </ul>


            </div>
            <!-- action button end -->

        </div>
    </div>
</nav>
<app-cart-sidebar [carts]="carts" [cartCount]="cartCount" [profile]="profile" [subTotal]="subTotal"></app-cart-sidebar>