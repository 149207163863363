<section class="custom-page-wrap clearfix">
    <div class="container">
        <div class="custom-page-content clearfix">
            <p style="margin-left: 0.25in; margin-bottom: 0.11in; line-height: 0.17in" align="CENTER">
                <font color="#00000a">
                    <font face="Arial, serif">
                        <font size="3"><b><span style="background: #ffffff">Refund
                                    and Cancellation Policy</span></b></font>
                    </font>
                </font>
            </p>
            <p style="margin-bottom: 0.11in; line-height: 0.17in" align="LEFT">
                <font color="#00000a">
                    <font face="Arial, serif">
                        <font size="2"><span style="background: #ffffff"><br>
                                Our focus is complete customer satisfaction. In the event, if you
                                are displeased with the services provided, we will refund back the
                                money. Provided the reasons are genuine and proved after
                                investigation. Please read the fine prints of each deal before buying
                                it, it provides all the details about the services or the product you
                                purchase.<br><br> In case of dissatisfaction from our services,
                                clients have the liberty to cancel their projects and request a
                                refund from us. Our Policy for the cancellation and refund will be as
                                follows:</span></font>
                    </font>
                </font>
            </p>
            <p style="margin-bottom: 0.11in; line-height: 0.17in" align="LEFT">
                <font color="#00000a">
                    <font face="Arial, serif">
                        <font size="2"><b><span style="background: #ffffff">Cancellation
                                    Policy</span></b></font>
                    </font>
                </font>
                <font color="#00000a">
                    <font face="Arial, serif">
                        <font size="2"><span style="background: #ffffff"><br><br>
                                For Cancellations please contact us via phone call to the number
                                shown in customer support.&nbsp;</span></font>
                    </font>
                </font>
            </p>
            <p style="margin-bottom: 0.11in; line-height: 0.17in" align="LEFT">
                <font color="#00000a"><span style="background: #ffffff">
                        <font face="Arial, serif">
                            <font size="2">You can cancel your order
                                only in the event of any of the following circumstances:</font>
                        </font>
                    </span></font>
            </p>
            <ul>
                <li>
                    <p style="margin-bottom: 0.11in; line-height: 0.17in" align="LEFT">
                        <font color="#00000a">
                            <font face="Arial, serif">
                                <font size="2"><span style="background: #ffffff">Your
                                        order packaging has been damaged or out dated products at the time
                                        of delivery</span></font>
                            </font>
                        </font>
                    </p>
                </li>
            </ul>
            <p style="margin-bottom: 0.11in; line-height: 0.17in" align="LEFT">
                <font color="#00000a">
                    <font face="Arial, serif">
                        <font size="2"><b><span style="background: #ffffff">Refund
                                    Policy</span></b></font>
                    </font>
                </font>
                <font color="#00000a">
                    <font face="Arial, serif">
                        <font size="2"><span style="background: #ffffff"><br><br>
                                We will try our best to create the suitable design concepts for
                                our clients.<br><br> In case any client is not completely
                                satisfied with our products we can provide a refund.&nbsp;</span></font>
                    </font>
                </font>
            </p>
            <p style="margin-bottom: 0.11in; line-height: 0.17in" align="LEFT">
                <font color="#00000a"><span style="background: #ffffff">
                        <font face="Arial, serif">
                            <font size="2">You shall be entitled to a
                                refund only (A) if you pre-pay for your order at the time of placing
                                your order on the Platform or (b) in the event of cancellation of
                                your order after delivery, you can claim your refunt of the money
                                paid on delivery.</font>
                        </font>
                    </span></font>
            </p>
            <p style="margin-bottom: 0.11in; line-height: 0.17in" align="LEFT">
                <font color="#00000a"><span style="background: #ffffff">
                        <font face="Arial, serif">
                            <font size="2">We will refund your pre-pay
                                amount in the event of any of the following circumstances:</font>
                        </font>
                    </span></font>
            </p>
            <ul>
                <li>
                    <p style="margin-bottom: 0.11in; line-height: 0.17in" align="LEFT">
                        <font color="#00000a">
                            <font face="Arial, serif">
                                <font size="2"><span style="background: #ffffff">We
                                        cancelling your order due to (A) your delivery location following
                                        outside our designated delivery zones; (B) failure to contact you by
                                        phone or email at the time of delivery.</span></font>
                            </font>
                        </font>
                    </p>
                </li>
                <li>
                    <p style="margin-bottom: 0.11in; line-height: 0.17in" align="LEFT">
                        <font color="#00000a">
                            <font face="Arial, serif">
                                <font size="2"><span style="background: #ffffff">You
                                        cancelling the order at the time of confirmation due to
                                        unavailability of the items you ordered for at the time of booking.</span>
                                </font>
                            </font>
                        </font>
                    </p>
                </li>
            </ul>
            <p style="margin-bottom: 0.11in; line-height: 0.17in" align="LEFT"><br><br>
            </p>
            <p style="margin-bottom: 0.11in; line-height: 0.17in" align="LEFT">
                <font color="#00000a">
                    <span style="background: #ffffff">
                        <font face="Arial, serif">
                            <font size="2">Our decision on refunds
                                shall be at our sole discretion and shall be final and binding.</font>
                        </font>
                    </span></font>
            </p>
            <p style="margin-bottom: 0.11in; line-height: 0.18in" align="LEFT"><br><br>
            </p>

        </div>
    </div>
</section>