import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
  currentProductId:string=null;
  currentProdReviews:any[]=[];
  avgRating:number=0
  public reviewCount:BehaviorSubject<number>=new BehaviorSubject(this.currentProdReviews.length);

  constructor(private http:HttpClient){}
  setCurrentProductId(pid){
    this.currentProductId=pid;
    this.getCurrentProductReviews().subscribe(r=>{
      //console.log("Product Reviews Retrieved")
    });
  }

  getCurrentProductId(){
    return this.currentProductId;
  }

  setCurrentProdReviews(reviews){
    this.currentProdReviews=reviews;
    this.reviewCount.next(this.currentProdReviews.length);
  }

  getLocalProductReviews(){
    return this.currentProdReviews;
  } 

  getAvgRating(){
    return this.avgRating;
  }

  addReview(obj):Observable<any>{
    obj.productId=this.currentProductId;
    return this.http.post(NetworkService.addReview(),obj,{headers:NetworkService.getAuthHeader()}).pipe(map((r:any)=>{
      return r;
    }))
  }

  getCurrentProductReviews():Observable<any>{
    //console.log("GETTING CURRENT PRODUCT REVIEWS");
    //console.log("CURRENT PRODUCT ID-->",this.currentProductId);
    return this.http.get(NetworkService.getProductReviews(),{params:{productId:this.currentProductId}}).pipe(map((r:any)=>{
      //console.log("NOT WORKED");
      //console.log(r.message);
      this.currentProdReviews=r.payload.reviews?r.payload.reviews:[];
      this.avgRating=r.payload.avgRating?r.payload.avgRating:0;
      //console.log(r.payload.avgRating,this.avgRating);
      this.reviewCount.next(this.currentProdReviews.length);
      return r;
    }));
  }

}
