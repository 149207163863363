<section class="py-3 footer-bottom">
    <div class="container mb-1">
        <div class=" no-gutters">
            <div class="w-100 text-center">
                <p class="mt-1 mb-0">&copy; Copyright 2021 <strong class="text-dark">Tokoshope</strong>. All Rights Reserved<br>
                    <!-- <small class="mt-0 mb-0">Made with <i class="mdi mdi-heart text-danger"></i> by <a href="https://tokoshope.com/" target="_blank" class="text-primary">Tokoshope</a> </small>-->
                </p>
            </div>
            <!-- <div class="col-lg-6 col-sm-6 text-right">
                <img alt="osahan logo" src="assets/img/payment_methods.png" style="width: 349px;">
            </div> -->
        </div>
    </div>
    <!-- <div class="d-sm-none mobile-footer">
        <ul class="d-flex flex-wrap align-content-center">
            <li class="flex-fill">
                <a routerLink="/" routerLinkActive="router-link-active">
                    <span class="mdi mdi-store"></span>
                </a>
            </li>
            <li class="flex-fill">
                <a routerLink="/category-list">
                    <span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAbUlEQVRIS+2UQQqAMAwEMy9S//+J+qKVFhSktuQSDyW9dknIZDdY8CO4vv3bQFIxs20wVQGO+ufVVe1rAkmaIQOa3qvLBkOaN8o1Ec1segK7w6aPrkMUkepM8ifVtW2ax64tfbbkTHKXi/BTcQFnk4gZVS3hmAAAAABJRU5ErkJggg=="/></span>
                </a>
            </li>
            <li class="flex-fill cart-btn">
                <a routerLink="/account/wishlist">
                    <span class="mdi mdi-heart"></span>
                    <small class="cart-value" style="left: auto !important;">{{wlCount}}</small>
                </a>
            </li>
            <li class="flex-fill cart-btn">
                <a href="javascript:void(0)" (click)="showSideCart()" class="">
                    <span class="mdi mdi-cart"></span> <small class="cart-value" style="left: auto !important;">{{cartCount}}</small>
                </a>
            </li>
        </ul>
    </div> -->
</section>