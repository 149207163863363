import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GeneralService } from 'src/app/services/general.service';
import { ProfileService } from 'src/app/services/profile.service';
import {getApiUrl} from '../../utils';

declare var $:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  apiUrl:string;
  slider:any=null;
  loading:boolean=true;
  constructor(private gnService:GeneralService,private sanitizer:DomSanitizer, private pfService: ProfileService){
    this.apiUrl=getApiUrl();
    this.openBottomUrl=this.openBottomUrl.bind(this);
  }

  ngOnInit(): void {
    setTimeout(()=>{
      this.loading=false;
    },3000);
    this.gnService.getSlider().subscribe(r=>{
      this.slider = (r.payload && r.payload.slider != null) ? r.payload.slider : null;

      if(this.slider.sliders[0]){
        this.slider.sliders.forEach(s=>{
          if(s.type=='video')
            s.url=this.sanitizer.bypassSecurityTrustResourceUrl(s.url);
        });  
      }

      // setTimeout(()=>{
        // this.startSlick();
      // },300);
    });
  }

  openBottomUrl(n?:any):void{
    
    if(n==2)
      {
        window.open("profile/profile");
        return;
      }


    if(this.slider.bottomBannerUrl){
      window.open(this.slider.bottomBannerUrl);
    }else{
      window.open("wi");
    }
  }

  startSlick():void{
    $(".bottom-banners-inner").slick({
      infinite: true,
      speed: 500,
      cssEase: 'linear',
      rows: true,
      dots: false,
      arrows: false,
      autoplay:true,
      centerMode:true,
      autoplaySpeed:1000,
      slidesToShow:1,
      variableWidth:true,
      rtl: false,
    })
  }
}
