import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SliderComponent } from './components/home/slider/slider.component';
import { QuickTopBarComponent } from './components/quick-top-bar/quick-top-bar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { QuickInfoComponent } from './components/quick-info/quick-info.component';
import { CategoriesComponent } from './components/home/categories/categories.component';
import { QuickbuyComponent } from './components/home/quickbuy/quickbuy.component';
import { HighsaveComponent } from './components/home/highsave/highsave.component';
import { OfferBannerComponent } from './components/home/offer-banner/offer-banner.component';
import { AuthComponent } from './components/auth/auth.component';
import { CartSidebarComponent } from './components/cart-sidebar/cart-sidebar.component';
import { HomeComponent } from './components/home/home.component';
import { PathComponent } from './components/path/path.component';
import { CategoryFilterComponent } from './components/category/category-filter/category-filter.component';
import { CategoryComponent } from './components/category/category.component';
import { ProductListComponent } from './components/category/product-list/product-list.component';
import { CategoryBannerComponent } from './components/category/category-banner/category-banner.component';
import { ProductComponent } from './components/product/product.component';
import { AboutusComponent } from './components/pages/aboutus/aboutus.component';
import { CheckoutComponent } from './components/orders/checkout/checkout.component';
import { MyCartComponent } from './components/orders/my-cart/my-cart.component';
import { ContactusComponent } from './components/pages/contactus/contactus.component';
import { ErrorpageComponent } from './components/pages/errorpage/errorpage.component';
import { ProfileComponent } from './components/account/profile/profile.component';
import { MyOrdersComponent } from './components/account/my-orders/my-orders.component';
import { AddressComponent } from './components/account/address/address.component';
import { WishlistComponent } from './components/account/wishlist/wishlist.component';
import { AccountComponent } from './components/account/account.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ShortStringPipe } from './pipes/short-string.pipe';
import { BlurImageDirective } from './directives/blur-image.directive';
import { ImgLoaderDirective } from './directives/img-loader.directive';
import { NiceSelectDirective } from './directives/nice-select.directive';
import { ProductPriceDirective } from './directives/product-price.directive';
import { ProductTagDirective } from './directives/product-tag.directive';
import { SlickHandlerDirective } from './directives/slick-handler.directive';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireAuthModule } from '@angular/fire/auth';


import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider
} from 'angularx-social-login';
import { AngularFireModule } from '@angular/fire';
import {firebase} from '../environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { OfferBanner2Component } from './components/home/offer-banner2/offer-banner2.component';
import { OrderDetailComponent } from './components/account/order-detail/order-detail.component';
import { TermsComponent } from './components/pages/terms/terms.component';
import { PaymentComponent } from './components/pages/payment/payment.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';
import { ReturnComponent } from './components/pages/return/return.component';
import { RegisterUserComponent } from './components/account/register-user/register-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ElitePlanComponent } from './components/account/elite-plan/elite-plan.component';
import { AlertComponent } from './components/general/alert/alert.component';
import { CategoryListComponent } from './components/category/category-list/category-list.component';
import { DiscountTagDirective } from './directives/discount-tag.directive';

@NgModule({
	declarations: [
		AppComponent,
		SliderComponent,
		QuickTopBarComponent,
		NavbarComponent,
		HeaderComponent,
		FooterComponent,
		QuickInfoComponent,
		CategoriesComponent,
		QuickbuyComponent,
		HighsaveComponent,
		OfferBannerComponent,
		AuthComponent,
		CartSidebarComponent,
		HomeComponent,
		PathComponent,
		CategoryFilterComponent,
		CategoryComponent,
		ProductListComponent,
		CategoryBannerComponent,
		ProductComponent,
		AboutusComponent,
		CheckoutComponent,
		MyCartComponent,
		ContactusComponent,
		ErrorpageComponent,
		ProfileComponent,
		MyOrdersComponent,
		AddressComponent,
		WishlistComponent,
		AccountComponent,
		FaqComponent,
		ShortStringPipe,
		BlurImageDirective,
		ImgLoaderDirective,
		NiceSelectDirective,
		ProductPriceDirective,
		ProductTagDirective,
		SlickHandlerDirective,
		OfferBanner2Component,
		OrderDetailComponent,
		TermsComponent,
		PaymentComponent,
		PrivacyComponent,
		ReturnComponent,
		RegisterUserComponent,
		ElitePlanComponent,
		AlertComponent,
		CategoryListComponent,
		DiscountTagDirective,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		SocialLoginModule,
		AngularFireModule.initializeApp(firebase),
		AngularFireMessagingModule,
		AngularFireAuthModule
	],
	providers: [
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
				{
					id: GoogleLoginProvider.PROVIDER_ID,
					provider: new GoogleLoginProvider(
					// '309352092367-clsi30qoarttnn2pnq7pahr4f2bifool.apps.googleusercontent.com', // new
					// '48013822452-fqcinkevl2qq1k5ji98uhf3kdd35knrm.apps.googleusercontent.com' // old
					'573737460083-ab8pf7l6sa8ntp7aorba1esb7a83h087.apps.googleusercontent.com' // very_old
					),
				},
				],
			} as SocialAuthServiceConfig,
		}
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent],
})
export class AppModule {}
