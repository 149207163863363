import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from './services/general.service';
import { MessagingService } from './services/messaging.service';
import { ProfileService } from './services/profile.service';
declare var $:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Tokoshope';
  isAskPermission:boolean=false;
  isServiceAvailable:boolean=true;
  constructor(
    private ms:MessagingService,
    private router:Router,
    private ps:ProfileService,
    private gs:GeneralService
  ){}

  ngAfterViewInit(){
    // this.router.events.subscribe(events=>{
    //   if(events instanceof NavigationEnd){
    //     if(this.router.url=="/login" || this.router.url=="/register")
    //     {
    //       $('.header-footer').css("display","none");
    //     }else{
    //       $('.header-foooter').css("display","block");
    //     }
    // }
    // })   
  }

  ngOnInit(){
      // this.isServiceAvailable=localStorage.getItem('service-available')==='Y' || false;

      // if(!this.isServiceAvailable){
      //   this.router.navigate(['/snf'])
      // }

      if(this.ps.isUserLoggedIn()){
        this.ps.isServiceAvailable().subscribe(r=>{
          localStorage.setItem('service-available',r.success ?'Y':'N') 
        });
      }else{
        this.gs.getSettings().subscribe(r=>{
          if(r.success){
            localStorage.setItem('service-available','Y');
            if(r.payload.settings != null && r.payload.settings.defaultPinLocation){
              localStorage.setItem('default-location',r.payload.settings.defaultPinLocation)
            }
            this.gs.settings=r.payload.settings;
          }
        });
      }

      
      
  }

  ngOnChanges(){
    console.log("ON CHANGE");
  }

  ngAfterViewChecked(){
    $('img').bind('contextmenu', function(e) {
      return false;
    });
  }

  askPermission(){
    this.ms.requestPermission(); 
    this.isAskPermission=false;
  }
}
