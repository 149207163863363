<section class="section-padding bg-dark inner-header">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="mt-0 mb-3 text-white">Contact Us</h1>
                <div class="breadcrumbs">
                    <p class="mb-0 text-white"><a class="text-white" href="#">Home</a> / <span class="text-success" style="color: #88b3ff!important">Contact Us</span></p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <h3 class="mt-1 mb-5">Get In Touch</h3>
                <h6 class="text-dark"><i class="mdi mdi-home-map-marker"></i> Address :</h6>
                <p> Karaikudi, Tamilnadu</p>
                <h6 class="text-dark"><i class="mdi mdi-phone"></i> Phone :</h6>
                <p>+91 12345-67890, (+91) 123 456 7890</p>
                <h6 class="text-dark"><i class="mdi mdi-deskphone"></i> Mobile :</h6>
                <p> +919787243214</p>
                <h6 class="text-dark"><i class="mdi mdi-email"></i> Email :</h6>
                <p> contact@tokoshope.com</p>
                <h6 class="text-dark"><i class="mdi mdi-link"></i> Website :</h6>
                <p>www.tokoshope.com</p>
                <div class="footer-social"><span>Follow : </span>
                    <a class="btn-facebook mx-1" href="https://www.facebook.com/tokoshopeapp/"><i class="mdi mdi-facebook"></i></a>
                    <a class="btn-twitter mx-1 " href="https://twitter.com/ShopeToko"><i class="mdi mdi-twitter"></i></a>
                    <a class="btn-instagram mx-1" href="javascript:void(0)"><i class="mdi mdi-instagram"></i></a>
                    <a class="btn-whatsapp mx-1" href="javascript:void(0)"><i class="mdi mdi-whatsapp"></i></a>
                    <!-- <a class="btn-messenger" href="#"><i class="mdi mdi-facebook-messenger"></i></a> -->
                    <a class="btn-google mx-1" href="javascript:void(0)"><i class="mdi mdi-youtube-tv"></i></a>
                </div>
            </div>
            <div class="col-lg-8 col-md-8">
                <div class="card">
                    <div class="card-body">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.5455341846!2d80.18892031482157!3d12.936904990878649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTLCsDU2JzEyLjkiTiA4MMKwMTEnMjguMCJF!5e0!3m2!1sen!2sin!4v1615207558148!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-padding  bg-white">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 section-title text-left mb-4">
                <h2>Contact Us</h2>
            </div>
            <form (ngSubmit)="sendMessage()" [formGroup]="messageForm" class="col-lg-12 col-md-12" name="sentMessage" id="contactForm" novalidate="">
                <div class="control-group form-group">
                    <div class="controls">
                        <label>Full Name <span class="text-danger">*</span></label>
                        <input type="text" formControlName="name" placeholder="Full Name" class="form-control" id="name" required="" data-validation-required-message="Please enter your name.">
                        <p class="help-block" *ngIf="submitted && vd.name.errors">
                            <span class="error-message" *ngIf="vd.name.errors.required">Full Name is Required.</span>
                        </p>

                    </div>
                </div>
                <div class="row">
                    <div class="control-group form-group col-md-6">
                        <label>Phone Number <span class="text-danger">*</span></label>
                        <div class="controls d-flex flex-wrap">
                            <span class="mx-1 my-auto">+ 91</span>
                            <div class="flex-fill">
                            <input type="tel" formControlName="mobile" placeholder="Phone Number" class="form-control" id="phone" required="" data-validation-required-message="Please enter your phone number.">
                            <div class="help-block" *ngIf="submitted && vd.mobile.errors">
                                <span class="error-message" *ngIf="vd.mobile.errors.required">Mobile Number is Required.</span>
                                <span class="error-message" *ngIf="vd.mobile.errors.pattern">Invalid Mobile Number.</span>
                        </div>
                    </div>


                        </div>
                    </div>
                    <div class="control-group form-group col-md-6">
                        <div class="controls">
                            <label>Email Address <span class="text-danger">*</span></label>
                            <input type="email" formControlName="email" placeholder="Email Address" class="form-control" id="email" required="" data-validation-required-message="Please enter your email address.">
                                <div class="help-block" *ngIf="submitted && vd.email.errors">
                                    <span class="error-message" *ngIf="vd.email.errors.required">Email Id is Required.</span>
                                    <span class="error-message" *ngIf="vd.email.errors.email">Invalid Email Address.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="control-group form-group">
                    <div class="controls">
                        <label>Subject<span class="text-danger">*</span></label>
                        <input type="text" formControlName="subject" placeholder="Subject" class="form-control" id="subject" required="" data-validation-required-message="Please enter subject.">
                        <p class="help-block" *ngIf="submitted && vd.subject.errors">
                            <span class="error-message" *ngIf="vd.subject.errors.required">Subject is Required.</span>
                        </p>

                    </div>
                </div>
                <div class="control-group form-group">
                    <div class="controls">
                        <label>Message <span class="text-danger">*</span></label>
                        <textarea formControlName="message" rows="4" cols="100" placeholder="Message" class="form-control" id="message" required="" data-validation-required-message="Please enter your message" maxlength="999" style="resize:none"></textarea>
                        <div class="help-block" *ngIf="submitted && vd.message.errors">
                            <span class="error-message" *ngIf="vd.message.errors.required">Message is Required.</span>
                    </div>

                    </div>
                </div>
                <div id="success"></div>
                <!-- For success/fail messages -->
                <button type="submit" class="btn btn-success">Send Message</button>
            </form>
        </div>
    </div>
</section>