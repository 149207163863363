import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css']
})
export class MyOrdersComponent implements OnInit {
  orders:any[]=[]
  constructor(private odService:OrderService) { }
  loading:boolean=false;

  ngOnInit(): void {
    this.loading=true;
    this.odService.getMyOrders().subscribe(r=>{
      console.log("Orders",r.payload);
      this.loading=false;
      this.orders=r.payload.orders?r.payload.orders:[];
    });
  }
  getBatch(b){
    if(b=="PENDING" )
      return "badge-warning";
    else if(b=="CANCELED")
      return "badge-danger";
    else if(b=="PLACED" || b=="PROCESSING")
      return "badge-info";
    else if(b=="DELIVERED")
      return "badge-success";
  }

  getDate(d){
    return new Date(d).toLocaleDateString();
  }
  clearItem(){
    this.odService.item.next(null);
  }
}
