import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';
import { ProfileService } from '../services/profile.service';
@Directive({
  selector: '[dTag]'
})
export class DiscountTagDirective implements AfterContentInit {
  @Input('dTag') prod:any;
  p:any=null;

  constructor(private ele:ElementRef,private pfs:ProfileService) {
    this.p=this.pfs.getLocalProfile();
  }

  private isNew(createdAt){
    return Date.now() - new Date(createdAt).getTime() <=24*60*60*30*1000;
  }

  ngAfterContentInit():void{
    let l=[];
    
console.log(this.prod);
    
    // if(this.isNew(this.prod.createdAt)){
    //   l.push(
    //     `<li class="badge badge-primary">New</li>`
    //   );
    // }

    if(!this.p || !this.p.isElite){
      if(this.prod.offerPrice!=0){
        let dis=(((this.prod.retailPrice-this.prod.offerPrice)/this.prod.retailPrice)*100);
        l.push(
          `<li class="badge badge-success">${Math.round(dis)}%</li>`
        )
      }
    }
    else if(this.p || !this.p.isElite){
      let dis=(((this.prod.retailPrice-this.prod.elitePrice)/this.prod.retailPrice)*100);
      l.push(
        `<li class="badge badge-success">${Math.round(dis)}%</li>`
      )
    }

    // if(this.prod.lowQuantity>=this.prod.quantity){
    //   l.push(
    //     `<li class="badge badge-danger">
    //       Out of Stock
    //     </li>`
    //   )
    // }
    this.ele.nativeElement.innerHTML=l.join('');
  }
}
