import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteAuthGuard } from 'src/app/route-auth.guard';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  catId: any = null;
  loading: boolean = true;
  currentType: any = null;

  constructor(private router: Router) { 
    let str = document.URL;    
    this.catId = str.substring(str.lastIndexOf('/') + 1);
    this.currentType = JSON.parse(localStorage.getItem('currentCategory'));
    // console.log(this.currentType);

    if(this.catId == '' || this.catId == null || this.currentType == null || this.currentType._id != this.catId){
      this.loading = false;
      this.router.navigate(['/']);
      return;
    } 
    // console.log('called');

    this.loading = false;

  }

  ngOnInit(): void {
  }

}
