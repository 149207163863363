import { Directive } from '@angular/core';

@Directive({
  selector: '[appImgLoader]'
})
export class ImgLoaderDirective {

  constructor() { }

}
