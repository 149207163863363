import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quickbuy',
  templateUrl: './quickbuy.component.html',
  styleUrls: ['./quickbuy.component.css']
})
export class QuickbuyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
