import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profile:any;
  loading:boolean=true;
  recentOrders:any[];
  constructor(private pfService:ProfileService,private odService:OrderService) { }
  ngOnInit(): void {
    this.loading=true;
    this.profile=this.pfService.getLocalProfile();
    this.loading=false;

    console.log(this.profile);
    
    // this.odService.getMyOrders({orderStatus:"PLACED"}).subscribe(r=>{
    //   this.recentOrders=r.payload.orders?r.payload.orders:[];
    //   this.loading=false;
    //   //console.log(this.recentOrders);
    //   //console.log(this.profile);
    // });
  }

  getDate(d){
    return new Date(d).toLocaleDateString();
  }

  clearItem():void{
    this.odService.item.next(null); 
  }

}
