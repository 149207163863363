<section class="offer-product">
    <div class="mx-2">
        <div class="d-flex justify-content-around" >
            <div class="align-self-center flex-fill mx-1" *ngFor="let s of [1,2,3];">
                <!-- <a href="javascript:void(0)"> -->
                <img [src]="apiUrl + slider['banner'+s]" alt="{{s}} slide" class="d-block img-fluid mx-auto w-100 my-2" style="object-fit:cover;">
                <!-- </a> -->
            </div>
        </div>
    </div>
</section>