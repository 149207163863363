<div>
    <div class="container" *ngIf="!elitePlans || elitePlans.length==0">
        <div class="empty-message">
            <trash-svg></trash-svg>
            <h2>No Plans Found...</h2>
        </div>
    </div>

    <div class="card card-body account-right" *ngIf="elitePlans && elitePlans.length!=0">
        <div class="widget">
            <div class="section-header">
                <h5 class="heading-design-h5">
                    Plans List
                </h5>
            </div>

            <div class="container elite-container" [class.loading]="loading">
                <div class="d-flex flex-wrap">
                    <div class="flex-fill mx-1" *ngFor="let ep of elitePlans;">
                        <div class="pricingTable">
                            <div class="pricingTable-header">
                                <h3 class="title">{{ep.planName}}</h3>
                            </div>
                            <div class="price-value">
                                <span class="currency"><i class="las la-rupee-sign" style="font-size: 24px;"></i></span>
                                <span class="amount">{{ep.amount}}</span>
                                <!-- <span class="duration" style="margin-left: 1px;"> /{{ep.validDays}}d</span> -->
                            </div>
                            <!-- <ul class="pricing-content">
                                <li>50GB Disk Space</li>
                                <li>50 Email Accounts</li>
                                <li>Maintenance</li>
                                <li>15 Subdomains</li>
                            </ul> -->
                            <div class="pricingTable-signup">
                                <a href="javascript:void(0);" (click)="subscribeElite(ep._id)">SUBSCRIBE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>