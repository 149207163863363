import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class RegisterUserGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if(localStorage.getItem('session_user') && localStorage.getItem('userFound') && localStorage.getItem('userFound')=='NOT_FOUND')
    { 
      localStorage.removeItem('session_user');
      localStorage.removeItem('userFound');
      localStorage.removeItem('userFound');
      // $('#login-signup-open-btn').click();

      document.location.href="/";
      return false;
    }
    else{
      return true;
    }
  }
  
}
