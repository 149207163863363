<section class="pt-3 pb-3 page-info section-padding border-bottom bg-white">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <a routerLink="/"><strong><span class="mdi mdi-home"></span> Home</strong></a> <span class="mdi mdi-chevron-right"></span> <a href="javascript:void(0)">Category list</a>
            </div>
        </div>
    </div>
</section>
<div class="shop-head">
    <!-- <a href="#"><span class="mdi mdi-home"></span> Home</a> <span class="mdi mdi-chevron-right"></span> <a href="javascript:void(0)">Category list</a> -->
    <!-- <div class="btn-group float-right">
        <button type="button" class="btn btn-dark dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Sort by Products &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </button>
        <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" href="javascript:void(0)" (click)="onChange('alphabetic')">Name (A to Z)</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="onChange('deAlphabetic')">Name (Z to A)</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="onChange('newToOld')">New To Old</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="onChange('oldToNew')">Old To New</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="onChange('priceLowToHigh')">Price (Low to High)</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="onChange('priceHighToLow')">Price (High to Low)</a>
        </div>
        <div class="ml-2" id="length-wrapper">
            <select class="form-control custom-select-option right arrow-black h-auto" id="prod-length" [value]="2" (change)="selectLength($event.target.value)">
               <option value="2" selected>
                  2
               </option>
               <option value="10">
                10
             </option>
             <option value="20">
                  20
               </option>
               <option value="30" >
                  30
               </option>
               <option value="40">
                  40
               </option>
               <option value="50">
                  50
               </option>
            </select>
        </div>

    </div>
    <h5 class="mb-3" *ngIf="currentType != null">{{currentType.name}}</h5> -->
</div>
<div [class.loading]="loading" class="m-3">
    <div class="d-flex flex-wrap justify-content-center" *ngIf="cats && cats.length!=0">

        <div class="item product" *ngFor="let cat of cats; let i = index;">
            <div class="category-list-item">
                <a href="javascript:void(0);" type="button">
                    <img class="img-fluid" [src]="cat.picture ? apiUrl+cat.picture : 'assets/img/item/11.jpg' " alt="category image">
                    <h6>{{cat.name}}</h6>
                    <!-- <p>Subcategory : {{cat.totalSubs}}</p>
                    <p>Products : {{cat.totalProds}}</p> -->
                    <!-- <p class="" *ngIf="cat.description">{{(cat.description | slice:0:25) + (cat['description'].length > 4 ? '...': '')}}</p> -->
                    <!-- <p class="">{{(cat.description | limitTo: 100) + (cat.description > 100 ? '...' : '')}}</p> -->
                    <button (click)="setCat(cat)" [disabled]="cat.totalProds < 1" type="button" class="btn text-secondary font-weight-bolder">View Products <i class="mdi mdi-chevron-right"></i></button>
                </a>
            </div>
        </div>
    </div>

    <div class="search-result-middle empty" *ngIf="!cats || cats.length==0">
        <div class="empty-message">
            <lottie-player src="https://assets6.lottiefiles.com/packages/lf20_CWcCII.json" background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay>
            </lottie-player>
            <h2>Oops! No categories found.</h2>
        </div>
    </div>
</div>

<!-- <nav *ngIf="totalLength!=0" class="search-result-bottom">
    <span class="showing-results">{{showPage()}}</span>
    <ul class="pagination justify-content-center mt-4" *ngIf="hasPaginate()">
        <li class="page-item " [class.disabled]="page==1">
            <span class="page-link" type="button" [class.disabled]="page==1" (click)="setPage('DEC')">Previous</span>
        </li>
        <li class="page-item" *ngFor="let pi of getPageArray();" [class.disabled]="pi=='...'" [class.active]="pi==page">
            <span class="page-link" type="button" (click)="setCurrentPage(pi)">{{pi}}</span>
        </li>

        <li class="page-item" [class.disabled]="isLastPage()">
            <span class="page-link" type="button" (click)="setPage('INC')" [class.disabled]="true">Next</span>
        </li>
    </ul>
</nav> -->