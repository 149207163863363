import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  loggedIn:boolean = false;
  registeredUser:boolean = false;

  constructor(
    private pfService:ProfileService
  ) { 
    this.loggedIn=this.pfService.isUserLoggedIn();
    this.registeredUser=this.pfService.isUserRegistered();
  }

  ngOnInit(): void {
  }

  logout():void{
    this.pfService.logout();
    this.loggedIn=false;
    document.location.href="/";
  }
}
