import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  submitted:boolean=false;
  messageForm:FormGroup;

  constructor(
    private gns:GeneralService,
    private fb:FormBuilder,
    private alert:AlertService
    ) { }

  ngOnInit(): void {
    this.messageForm=this.fb.group({
      name:['',[Validators.required]],
      mobile:['',[Validators.required, Validators.pattern("[0-9 ]{10}")]],
      email:['',[Validators.required,Validators.email]],
      subject:['',[Validators.required]],
      message:['',[Validators.required]]
    });
  }

  get vd(){
    return this.messageForm.controls;
  }

  sendMessage():void{
    this.submitted=true;
    if(this.messageForm.invalid)
      return;

    this.gns.sendMessage(this.messageForm.value).subscribe(r=>{
        if(r.success){
          this.alert.showAlertVW('Message Sent Successfully',this.alert.alertTypes.primary);
        }else{
          this.alert.showAlertVW("Failed To Send Message",this.alert.alertTypes.danger);  
        }
    },e=>{
        this.alert.showAlertVW("Failed To Send Message",this.alert.alertTypes.danger);
    });

    console.log(this.messageForm.value);
}
}
