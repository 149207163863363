import { Directive } from '@angular/core';

@Directive({
  selector: '[appSlickHandler]'
})
export class SlickHandlerDirective {

  constructor() { }

}
