import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NetworkService } from './network.service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  constructor(private http:HttpClient) { }
  // constructor(private http:HttpClient,private pfService:ProfileService) { }
  
  public command:BehaviorSubject<string>=new BehaviorSubject('');
  public curl:BehaviorSubject<string>=new BehaviorSubject('/');
  public collection:any=null;
  public settings:any=null;

  getArray(n:number):number[]{
    n=Math.round(n);
    return [...Array(n).keys()]
  }

  setCollection(col:any):void{
    this.collection=col;
  }

  getCollection():any{
    return this.collection;
  }

  getCurrentCollectionProduct():Observable<any>{
    if(!this.collection)
      return of(null,"Collection Not Set",null);

    return this.http.post(NetworkService.getCollectionProd(),{_id:this.collection._id},{headers:NetworkService.getHeaderIfUserExists()});
  }

  getCollections():Observable<any>{
    return this.http.post(NetworkService.getCollections(),{},{headers:NetworkService.getHeaderIfUserExists()});
  }

  getCollectionProduct(_id:string):Observable<any>{
    return this.http.post(NetworkService.getCollectionProd(),{_id},{headers:NetworkService.getHeaderIfUserExists()});
  }

  getSlider():Observable<any>{
    return this.http.get(NetworkService.getSlider);
  }

  sendMessage(obj):Observable<any>{
    return this.http.post(NetworkService.sendMessage,obj,{headers:NetworkService.getHeaderIfUserExists()});
  }
  
  getSettings():Observable<any>{
    return this.http.get(NetworkService.getSettings);
  }
}
