import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { CartService } from 'src/app/services/cart.service';
import { GeneralService } from 'src/app/services/general.service';
import { ProfileService } from 'src/app/services/profile.service';
import {getApiUrl} from 'src/app/utils';

@Component({
  selector: 'app-my-cart',
  templateUrl: './my-cart.component.html',
  styleUrls: ['./my-cart.component.css']
})
export class MyCartComponent implements OnInit {

  settings:any=null;
  apiUrl=getApiUrl();
  carts:any[];
  subTotal;
  profile:any=null;
  vendorCarts:any[];
  hasInvalidCarts:Boolean=false;

  loading: boolean = false;
  
  constructor(private router:Router,private ctService:CartService,private alertService:AlertService,private gs:GeneralService,private pfs:ProfileService) {
  }
  

  ngOnInit(): void {
    this.settings=this.gs.settings;
    this.profile=this.pfs.getLocalProfile();

    // this.carts=this.ctService.getLocalCarts().carts;
    // this.vendorCarts=this.ctService.getVendorLocalCarts();
    // console.log("CARTS",this.carts);
    // console.log("VENDOR CARTS",this.vendorCarts);
    // this.subTotal=this.ctService.getLocalCarts().subTotal;

    //console.log("Carts-->",this.carts);
    this.ctService.cartCount.subscribe(v=>{
      let {carts,subTotal}=this.ctService.getLocalCarts();
      this.carts=carts;
      this.subTotal=subTotal;
      this.hasInvalidCarts=this.ctService.hasInvalidCarts;
      this.vendorCarts=this.ctService.getVendorLocalCarts();
    });
  }

  updateQuantity(quantity:number,i:number,j:number):void{
    let cart=this.vendorCarts[j].carts[i];
    this.ctService.updateCart({_id:cart._id,quantity}).subscribe(r=>{
      if(r.success){
        this.alertService.showAlertVW(r.message, this.alertService.alertTypes.success);
      }
      else{
        this.alertService.showAlertVW(r.message, this.alertService.alertTypes.danger);
      }
    });
  }


  removeFromCart(i,j):void{
    this.loading = true;
    let cart=this.vendorCarts[j].carts[i];
    this.ctService.removeFromCart({_id:cart._id}).subscribe(r=>{
      //console.log("Remove From A Cart-->",r);
      this.loading = false;
    });
  }

  clearCart():void{
    // this.ctService.clearCart().subscribe(r=>{
    //   //console.log("Clear Cart-->",r);
    // });
  }

  goToCheckout(i:number):void{

    console.log(this.vendorCarts[i]);
    
    this.ctService.currentVendorCart=this.vendorCarts[i];
    if(this.vendorCarts[i].hasInvalidCarts){
      this.alertService.showAlertVW("Please Remove Expired And Out Of Stocks",this.alertService.alertTypes.danger);
      return;
    }
    this.router.navigate(['/checkout']);
  }

}
