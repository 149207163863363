<div class="modal fade login-modal-main" id="bd-example-modal">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="login-modal">
                    <div class="row">
                        <div class="col-lg-6 pad-right-0">
                            <div class="d-flex justify-content-center">
                                <div class="align-self-center">
                                    <img src="assets/img/logo2.png" width="200">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 pad-left-0 align-self-center">
                            <!-- <button type="button" id="login-signup-close-btn" class="close close-top-right" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i class="mdi mdi-close"></i></span>
                                <span class="sr-only">Close</span>
                            </button> -->
                            <form>
                                <div class="login-with-sites text-center">
                                    <h6>Login with your social profile:</h6>
                                    <!-- <button class="btn-facebook login-icons btn-lg" [class.disabled]="loading"><i class="mdi mdi-facebook"></i> Facebook</button> -->
                                    <button type="button" class="btn-google login-icons btn-lg" [class.disabled]="loading" [class.btn-loading]="loading" (click)="signInWithGoogle()"><i class="mdi mdi-google"></i> Google</button>
                                    <!-- <button class="btn-twitter login-icons btn-lg" [class.disabled]="loading"><i class="mdi mdi-twitter"></i> Twitter</button> -->
                                </div>

                                <!-- <div class="login-modal-right">
                                    <div class="tab-content">
                                        <div class="tab-pane active" id="login" role="tabpanel">
                                            <h5 class="heading-design-h5">Login to your account</h5>
                                            <fieldset class="form-group">
                                                <label>Enter Email/Mobile number</label>
                                                <input type="text" class="form-control" placeholder="+91 123 456 7890">
                                            </fieldset>
                                            <fieldset class="form-group">
                                                <label>Enter Password</label>
                                                <input type="password" class="form-control" placeholder="********">
                                            </fieldset>
                                            <fieldset class="form-group">
                                                <button type="submit" class="btn btn-lg btn-secondary btn-block">Enter to your account</button>
                                            </fieldset>
                                            <div class="login-with-sites text-center">
                                                <p>or Login with your social profile:</p>
                                                <button class="btn-facebook login-icons btn-lg" [class.disabled]="loading"><i class="mdi mdi-facebook"></i> Facebook</button>
                                                <button type="button" class="btn-google login-icons btn-lg" [class.disabled]="loading" [class.btn-loading]="loading" (click)="signInWithGoogle()"><i class="mdi mdi-google"></i> Google</button>
                                                <button class="btn-twitter login-icons btn-lg" [class.disabled]="loading"><i class="mdi mdi-twitter"></i> Twitter</button>
                                            </div>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="customCheck1">
                                                <label class="custom-control-label" for="customCheck1">Remember me</label>
                                            </div>
                                        </div>
                                        <div class="tab-pane" id="register" role="tabpanel">
                                            <h5 class="heading-design-h5">Register Now!</h5>
                                            <fieldset class="form-group">
                                                <label>Enter Email/Mobile number</label>
                                                <input type="text" class="form-control" placeholder="+91 123 456 7890">
                                            </fieldset>
                                            <fieldset class="form-group">
                                                <label>Enter Password</label>
                                                <input type="password" class="form-control" placeholder="********">
                                            </fieldset>
                                            <fieldset class="form-group">
                                                <label>Enter Confirm Password </label>
                                                <input type="password" class="form-control" placeholder="********">
                                            </fieldset>
                                            <fieldset class="form-group">
                                                <button type="submit" class="btn btn-lg btn-secondary btn-block">Create Your Account</button>
                                            </fieldset>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="customCheck2">
                                                <label class="custom-control-label" for="customCheck2">I Agree with <a href="#">Term and Conditions</a></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="text-center login-footer-tab">
                                        <ul class="nav nav-tabs" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" data-toggle="tab" id="login-tab-show-btn" href="#login" [class.disabled]="loading" role="tab"><i class="mdi mdi-lock"></i> LOGIN</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" data-toggle="tab" id="register-tab-show-btn" href="#register" [class.disabled]="loading" role="tab"><i class="mdi mdi-pencil"></i> REGISTER</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="clearfix"></div>
                                </div> -->
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade logout-modal-main" id="bd-example-modal-logout">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="p-3">
                <div class="logout-modal">
                    <h4>Are you sure to Logout ?</h4>
                </div>
                <div class="d-flex justify-content-end px-3">
                    <button type="button" class=" btn btn-danger font-weight-bold" (click)="logout()">Logout</button>
                </div>
            </div>
        </div>
    </div>
</div>