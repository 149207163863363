
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { ProfileService } from 'src/app/services/profile.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { CartService } from 'src/app/services/cart.service';
import { faSpinner,faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from 'src/app/services/alert.service';
import {ReviewService} from 'src/app/services/review.service';
import { GeneralService } from 'src/app/services/general.service';
import {getApiUrl,toFloat} from 'src/app/utils';
import { DataService } from 'src/app/services/data.service';


declare var $:any;
declare var window:any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  loggedIn:boolean = false;

  faRupee=faRupeeSign;
  toFloat:Function=toFloat;
  apiUrl=getApiUrl();
  avgRating:number|string;
  rvCount:number;
  url:string;
  spinner=faSpinner;
  _id:any;
  loading:boolean=true;
  product:any=null;
  products:any[]=[];
  currentSize:any;
  group:string;
  currentTab:string='description';
  quantity:number=1;
  getArray:Function;
  qlimit:number=0;
  sa:number=5;
 
  vendor:any;
  ci:number=0;

  color:string;
  siblings:[]=[];
  profile:any=null;
  settings:any={};
  rmt:number=3;

  constructor(
    private route:ActivatedRoute,
    private dService:DataService,
    private pdService:ProductsService,
    private pfService:ProfileService,
    private wlService:WishlistService,
    private router:Router,
    private ctService:CartService,
    private alert:AlertService,
    private rvService:ReviewService,
    private gnl:GeneralService
  ){
    this.loggedIn=this.pfService.isUserLoggedIn();
    console.log(this.loggedIn);     

    this.profile=this.pfService.getLocalProfile();
    console.log(this.profile);

  }

  ngOnInit(): void {
    
    let str = document.URL;      
    // console.log('constructor called ', str.substring(str.lastIndexOf('/') + 1));

    let item = {
      _id: str.substring(str.lastIndexOf('/') + 1),
    }
      
    this.dService.setSearchItem(item);

    this._id=item._id;
    this.viewProduct();  

    this.getArray=this.gnl.getArray;

    this.rvService.reviewCount.subscribe(v=>{
      this.rvCount=v;
      this.avgRating=this.rvService.getAvgRating();
    
    })
  }

  ngAfterViewInit():void{
    this.url=$(location).attr('href');
    this.loading=false;

    $(document).ready(function(){
      setTimeout(
        function(){          
        // ===========Slider============
        var mainslider = $(".owl-carousel-product-ra");
        if (mainslider.length > 0) {
            mainslider.owlCarousel({
                items: 1,
                dots: false,
                lazyLoad: true,
                pagination: true,
                autoPlay: 4000,
                loop: true,
                singleItem: true,
                navigation: true,
                stopOnHover: true,
                nav: true,
                navigationText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"]
            });
        }

      },600);
    });
  }

  ngOnDestroy():void{
    //    this.rvService.reviewCount.unsubscribe();
  }
    
  setItem(item,type):void{
     
  }
  
  viewProduct():void{
    this.loading=true;
    if(!this._id){
      //console.log("Product Id Required");
      this.loading=false;
      return;
    }
    this.pdService.expandProduct(this._id).subscribe(r=>{
      // this.unslick();
      window.scrollTo(0,0);
      this.product=r.payload.product && r.payload.product?r.payload.product : {};   
      this.setDefault(this.product);
    });
  }

  

  setDefault(p){
    // this.product=p;
    this.vendor=this.product.vendors[this.ci];

    // this.currentSize=this.product.sizes[0];
    this.qlimit=this.vendor.quantity - this.vendor.lowQuantity;
    this.group=this.product.groups[0];
    this.quantity=1;
    this.rvService.setCurrentProductId(this.product._id);
    setTimeout(() => {
      this.initializePicutureOpts();      
    }, 1000);
  }

  setCurrentVendor(i:number):void{
    this.ci=i;
    this.vendor=this.product.vendors[this.ci];
    this.qlimit=this.vendor.quantity - this.vendor.lowQuantity;
  }

  unslick(){
    try{
      $(".base-image-slide").trigger("zoom.destroy");
      $(".base-image-slide").remove();
      $(".base-image").slick("unslick");
      $('.additional-image-wrap').slick("unslick");
    }catch(e){
      //console.log("Error",e);
    }
  }

  setProduct(p,i){
    if(p._id==this.product._id)
      return;
    this.unslick();
    this.setDefault(p);
  }

  changeTab(v){
    this.currentTab=v;
  }

  currentProduct(){
    //console.log(this.product.sizes[i]);
    // this.currentSize=this.product.sizes[i];
    this.qlimit=this.product.quantity-this.product.lowQuantity;
    if(this.qlimit<0){
      this.qlimit=0;
    }
  }
  
  setGroup(c){
    //console.log(c);
    this.group=c;
  }

  quantityUpdate(v){
    
    if(v=="INC"){
      console.log(this.qlimit);
      if(this.qlimit >0 && this.quantity<this.qlimit)
        this.quantity+=1;
    }else if(v=='DEC'){
      if(this.quantity>1){
        this.quantity-=1;
      }
    }
  }

  setQuantity(e){
    this.quantity=parseInt(e.target.value);
  }

  initializePicutureOpts(){
        this.loading=false;
        // ===========Single Items Slider============   
        var sync1 = $("#sync1-ra");
        var sync2 = $("#sync2-ra");
        sync1.owlCarousel({
            singleItem: true,
            items: 1,
            slideSpeed: 1000,
            pagination: false,
            navigation: true,
            autoPlay: 2500,
        dots: false,
            nav: true,
            navigationText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
            afterAction: syncPosition,
            responsiveRefreshRate: 200,
        });
        sync2.owlCarousel({
            items: 5,
            navigation: true,
            dots: false,
            pagination: false,
            nav: true,
            navigationText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
            responsiveRefreshRate: 100,
            afterInit: function(el) {
                el.find(".owl-item").eq(0).addClass("synced");
            }
        });

        function syncPosition(el) {
          var current = this.currentItem;
          $("#sync2-ra")
              .find(".owl-item")
              .removeClass("synced")
              .eq(current)
              .addClass("synced")
          if ($("#sync2-ra").data("owlCarousel") !== undefined) {
              center(current)
          }
      }
      $("#sync2-ra").on("click", ".owl-item", function(e) {
          e.preventDefault();
          var number = $(this).data("owlItem");
          sync1.trigger("owl.goTo", number);
      });
  
      function center(number) {
          var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
          var num = number;
          var found = false;
          for (var i in sync2visible) {
              if (num === sync2visible[i]) {
                  var found = true;
              }
          }
          if (found === false) {
              if (num > sync2visible[sync2visible.length - 1]) {
                  sync2.trigger("owl.goTo", num - sync2visible.length + 2)
              } else {
                  if (num - 1 === -1) {
                      num = 0;
                  }
                  sync2.trigger("owl.goTo", num);
              }
          } else if (num === sync2visible[sync2visible.length - 1]) {
              sync2.trigger("owl.goTo", sync2visible[1])
          } else if (num === sync2visible[0]) {
              sync2.trigger("owl.goTo", num - 1)
          }
      }  

  }

  changeWishList(){
    if(!this.pfService.isUserLoggedIn()){
      $('#login-signup-open-btn').click();
      return;
    }
    if(!this.product.isFavourite){
      this.wlService.addToWishList(this.product._id).subscribe(r=>{
        if(r.code=='ECM003')
          this.product.isFavourite=true;
      });
    }else{
      this.wlService.removeFromWishList(this.product._id).subscribe(r=>{
        if(r.code=='ECM009')
          this.product.isFavourite=false;
      });
    }  
  }


  addToCart(){
    //console.log("Quantity-->",this.quantity);
    //console.log("Color-->",this.color);
    //console.log("Size-->",this.currentSize);
    //console.log("ProductId-->",this.product._id);


    this.loading=true;

    if(!this.pfService.isUserLoggedIn()){
      $('#login-signup-open-btn').click();
      this.loading=false;
      return;
    }

    this.ctService.addToCart({quantity:this.quantity,productId:this.product._id,priceId:this.vendor._id}).subscribe(r=>{
      //console.log("Cart Adding Result-->",r);
      if(r.success)
        $('body').toggleClass('toggled');        
      else
        this.alert.showAlertVW(r.message,this.alert.alertTypes.danger);
      this.loading=false;
    });
  }

  buyNow():void{
    this.loading=true;
    if(!this.pfService.isUserLoggedIn()){
      $('#login-signup-open-btn').click();
      // this.router.navigate(["login"]);
      this.loading=false;
      return;
    }
    this.ctService.addToCart({
      quantity:this.quantity,
      // group:this.group,
      // size:this.currentSize.size,
      productId:this.product._id,
      // texture:this.product.textures.value
    }).subscribe(r=>{
      //console.log("Cart Adding Result-->",r);
      // console.log(r.message);
      if(r.success)
        this.router.navigate(['my-carts']);
      else
        this.alert.showAlertVW(r.message,this.alert.alertTypes.danger);
      this.loading=false;
    });
  }
}
// this.pdService.expandProduct(this._id).subscribe(r=>{
    //     this.product=r.payload.product;
    //     this.currentSize=this.product.sizes[0];
    //     this.group=this.product.groups[0];
    //     // //console.log("result==>",r); 
    //     // //console.log(this.product);
    //     // //console.log("currentSize==>",currentSize);
    //     this.loading=false;
    //     this.initializePicutureOpts();
    // });
