import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { ProfileService } from 'src/app/services/profile.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import {getApiUrl} from '../../utils/';
declare var $:any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  getApiUrl:any=getApiUrl();
  cartCount:number=0;
  wlCount:number=0;

  subTotal:any=0;
  carts:any[]=[];
  cat:any=null;
  cats:any=[];

  constructor(private pfService: ProfileService, private ctService:CartService, private wlService:WishlistService) { 

    this.wlService.wishlistCount.subscribe(v=>{
      this.wlCount=v;
    });

    this.ctService.cartCount.subscribe(v=>{
      this.cartCount=v[0]?v[0]:0;
      this.subTotal=v[1]?v[1]:0;
      let {carts,subTotal}=this.ctService.getLocalCarts();
      this.carts=carts;
      this.subTotal=subTotal;
    });  

  }

  ngOnInit(): void {
  }


  showSideCart(){
    if(this.pfService.isUserLoggedIn()){
      let obj:any=this.ctService.getLocalCarts();
      this.carts=obj.carts;
      this.subTotal=obj.subTotal;
      $('body').toggleClass('toggled');
    }else{
      $('#login-signup-open-btn').click();
    }
  }

}
