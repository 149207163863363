import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { OrderService } from 'src/app/services/order.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  profile:any;
  loading:boolean=true;
  recentOrders:any[];
  currentRoute:string;

  constructor(private router:Router,private pfService:ProfileService,private odService:OrderService) {
    this.router.events.subscribe( (event: Event) => 
    {
      if (event instanceof NavigationEnd) {
         this.currentRoute=event.url.replace("/account","");
        //  console.log(this.currentRoute);         
      }
    });

   }
  ngOnInit(): void {
    this.loading=true;
    this.profile=this.pfService.getLocalProfile();
    this.loading=false;

    // this.odService.getMyOrders({orderStatus:"PLACED"}).subscribe(r=>{
    //   this.recentOrders=r.payload.orders?r.payload.orders:[];
    //   this.loading=false;
    //   //console.log(this.recentOrders);
    //   //console.log(this.profile);
    // });
  }

  getDate(d){
    return new Date(d).toLocaleDateString();
  }

  clearItem():void{
    this.odService.item.next(null); 
  }

}
