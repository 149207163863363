import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { ProductsService } from 'src/app/services/products.service';
import {getApiUrl} from 'src/app/utils';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {

  apiUrl=getApiUrl();

  constructor(
    private router:Router,
    private prodService:ProductsService,
    private dtService:DataService,
    private altService: AlertService
  ) { }

  cats:any[];
  loading: boolean = true;

  ngOnInit(): void {
    this.prodService.getCats().subscribe(r=>{
      if(r.code=='ECM005'){
        this.cats=r.payload.categories;
        this.loading=false;
        this.altService.showAlertVW(r.message, this.altService.alertTypes.success)
        // this.startCarousel();        
      }
      else{
        this.loading=false;

        this.altService.showAlertVW(r.message, this.altService.alertTypes.danger)        
      }
    });
  }

  setCat(category:any):void{
    // console.log("ITEM",category);
    this.prodService
    this.dtService.setSearchItem(category);
    ////console.log(this.router.getCurrentNavigation());
    localStorage.setItem('currentCategory', JSON.stringify(category));
    this.router.navigate(['/category/'+category._id]);
  }

}
