import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { CartService } from 'src/app/services/cart.service';
import { GeneralService } from 'src/app/services/general.service';
import { ProfileService } from 'src/app/services/profile.service';
import { WishlistService } from 'src/app/services/wishlist.service';
declare var $:any;

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
  addAddress:boolean=false;
  editAddress:boolean=false;
  eAddress: any = null;

  newAddress:FormGroup;
  editAddressForm:FormGroup;

  submitted:boolean=false;
  selected:string;
  address:any=[];
  loading:boolean=true;
  error:string='';
  isLoggedIn:boolean=false;

  profile:any;
  constructor(
    private ps:ProfileService,
    private alert:AlertService,
    private gs:GeneralService,
    private as:AlertService,
    private fb:FormBuilder,
    private ctService:CartService,
    private wlService:WishlistService,
    private router:Router
  ) { 
    this.profile=this.ps.getLocalProfile();
  }


  ngAfterViewInit():void{

  }

  setEAddress(address){
    // if(address._id==this.profile.selectedAddress){
    //   return;
    // }
    this.addAddress=false;
    this.editAddress=true;

    this.eAddress=address;
    this.editAddressForm=this.fb.group({
      _id:[this.eAddress._id],
      type:[this.eAddress.type,Validators.required],
      addressLine:[this.eAddress.addressLine,Validators.required],
      district:[this.eAddress.district,Validators.required],
      state:[this.eAddress.state,Validators.required],
      pinCode:[this.eAddress.pinCode,[Validators.required,Validators.pattern(/^[0-9]{6,12}$/)]],
      locality:[this.eAddress.locality,Validators.required],
      landMark:[this.eAddress.landMark,Validators.required]
    });

    // $("#e-add").modal('toggle');
  }

  changeAddAddressStatus():void{
    if(!this.ps.isUserLoggedIn()){
      this.router.navigate(['login']);
      return;
    }

    this.addAddress=true;
    
  }

  deleteAddress(_id){
    // console.log('delete address called', _id);
    
    if(this.profile.address.length==1 || _id==this.profile.selectedAddress)
      return;
      
    this.ps.deleteAddress(_id).subscribe(r=>{
        this.alert.showAlertVW(r.message,r.success?this.alert.alertTypes.success:this.alert.alertTypes.danger);
        this.getAddress();
    });
  }

  ngOnInit(): void {

    if(this.ps.isUserLoggedIn() && localStorage.getItem('service-available')=='N'){
      // $('.sidebar-address-wrap').addClass('active');
    }    

    if(this.ps.isUserLoggedIn()){
      this.isLoggedIn=true;
    }

    this.addAddress=false;
    this.newAddress=this.fb.group({
      type:['',Validators.required],
      addressLine:['',Validators.required],
      district:['',Validators.required],
      state:['',Validators.required],
      pinCode:['',[Validators.required,Validators.pattern(/^[0-9]{6,12}$/)]],
      locality:['',Validators.required],
      landMark:['',Validators.required]
    });


    this.editAddress=false;
    this.editAddressForm=this.fb.group({
      _id:['',Validators.required],
      type:['',Validators.required],
      addressLine:['',Validators.required],
      district:['',Validators.required],
      state:['',Validators.required],
      pinCode:['',[Validators.required,Validators.pattern(/^[0-9]{6,12}$/)]],
      locality:['',Validators.required],
      landMark:['',Validators.required]
    });
    
    this.selected='abcd';   
    this.getAddress();
    this.gs.command.subscribe(v=>
      {
        if(v=='getAddress'){
          this.addAddress=false;
          this.getAddress();
        }
    });
  }

  get defaultLocation():string{
    return localStorage.getItem("default-location");
  }

  get aMsg(){
    return this.newAddress.controls;
  }

  get eMsg(){
    return this.editAddressForm.controls;
  }

  submit():void{
    this.submitted=true;
    if(this.newAddress.invalid)
      return;

    this.ps.addAddress(this.newAddress.value).subscribe(r=>{
      if(r.success)
      {
          this.reset();
          this.getAddress();
          this.addAddress=false;
      }else{
          this.error=r.message;          
      }
    });
  }

  submitEditAddress():void{
    //console.log("I AM ON SUBMIT");
    this.submitted=true;
    if(this.editAddressForm.invalid)
      return;

    this.ps.editAddress(this.editAddressForm.value).subscribe(r=>{
      if(r.success)
        {
          this.alert.showAlertVW(r.message,this.alert.alertTypes.success);
          this.editAddress=false;
          // this.resetA.emit({});
          //console.log("ADDRESS CHANGED");
        }
      else{
          this.alert.showAlertVW(r.message,this.alert.alertTypes.danger);
      }
    });
      
    //console.log(this.ea.value);
  }

  reset():void{
    this.submitted=false;
    this.newAddress.reset();
  }
  

  getAddress():void{
    this.loading=true;
    this.ps.getAddress().subscribe(r=>{
      console.log(r.payload);
      this.loading=false;
      this.address=r.payload.address;
      this.selected=r.payload.selectedAddress;
      console.log(this.address);
    
    },e=>{
      this.loading=false;
      this.address=[];
      console.log(e.message);
    });
  }

  closeSideBar():void{
    $('.sidebar-address-wrap').removeClass('active');
  }

  selectAddress(x):void{
    this.loading=true;

    console.log(this.selected);
    this.ps.selectAddress(x).subscribe(r=>{
      this.loading=false;
      if(r.success){
        this.selected=x._id;
        this.gs.command.next('check-for-service');
        localStorage.setItem('session_user', r.payload.renewedToken);
        // document.location.href="/";
        this.getAddress();
        this.ps.currentAddress.next(x);

        setTimeout(() => {
          this.ctService.getMyCarts().subscribe(res => console.log(res));
          this.wlService.getMyWishList();
        }, 500);

        console.log(r.message);
        
        this.as.showAlertVW(r.message,this.as.alertTypes.success);
        // window.location.reload();
      }else{
        this.as.showAlertVW(r.message,this.as.alertTypes.danger);
      }
    },e=>{
        this.as.showAlertVW(e.message,this.as.alertTypes.danger);
    });
    //this.removeSelection();  
  }

  removeSelection():void{
    $('.card-address').each(function(){
      $(this).css('border-color','#00000');
    });
  }

  getColor(_id):string{
    return (_id==this.selected) ? 'blue':'#00000';
  }
}
