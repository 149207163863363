<div class="shop-head">
    <div class=" d-flex flex-wrap">
        <div class="flex-fill mr-5 my-2 my-sm-0">
            <a href="#"><span class="mdi mdi-home"></span> Home</a>
            <span class="mdi mdi-chevron-right"></span>
            <a href="javascript:void(0)" *ngIf="currentType != null">{{currentType.name}}</a>
        </div>
        <div class="btn-group flex-sm-grow-0 flex-fill my-2 my-sm-0 ">
            <button type="button" class="btn btn-dark dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Sort by Products &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </button>
            <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="javascript:void(0)" (click)="onSortBy('alphabetic')">Name (A to Z)</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="onSortBy('deAlphabetic')">Name (Z to A)</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="onSortBy('newToOld')">New To Old</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="onSortBy('oldToNew')">Old To New</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="onSortBy('priceLowToHigh')">Price (Low to
                    High)</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="onSortBy('priceHighToLow')">Price (High to
                    Low)</a>
                <!-- <a class="dropdown-item" href="javascript:void(0)" (click)="onSortBy('')" >Discount (High to Low)</a> -->
            </div>
            <div class="ml-2" id="length-wrapper">
                <select class="form-control custom-select-option right arrow-black h-auto" id="prod-length" [value]="10" (change)="selectLength($event.target.value)">
                    <!-- <option value="2" selected>
                  2
               </option> -->
                    <option value="10" selected>
                        10
                    </option>
                    <option value="20">
                        20
                    </option>
                    <option value="30">
                        30
                    </option>
                    <option value="40">
                        40
                    </option>
                    <option value="50">
                        50
                    </option>
                </select>
            </div>
        </div>
    </div>

    <h5 class="mb-3 mt-2" *ngIf="currentType != null">{{currentType.name}}</h5>
</div>
<div [class.loading]="loading">
    <ng-container *ngIf="!loggedIn; else customerProductsDiv">
        <div class="d-flex flex-wrap row-cols-2 row-cols-xl-3" id="my-product-list" *ngIf="products && products.length!=0">

            <div class="product flex-fill" *ngFor="let prod of products; let i = index;">
                <a href="javascript:void(0);" class="product-image">
                    <!-- <a href="javascript:void(0);" [routerLink]="['/category', { id: prod.categoryId }, '/', {prod_id: prod._id}]" class="product-image"> -->
                    <div class="product-header">
                        <!-- <span class="" [dTag]="prod"></span> -->
                        <img class="img-fluid" iLoader [src]="prod.pictures && prod.pictures[0] ?  apiUrl+prod.pictures[0] : 'assets/img/item/1.jpg'" alt="product image">
                        <span type="button" class="wishlistbtn border-0" [class]="prod.isFavourite?'mdi mdi-heart':'mdi mdi-heart-outline'" [style.color]="prod.isFavourite?'red':'black'" (click)="changeWishList(prod,i)"></span>
                    </div>
                    <div class="product-body" [routerLink]="['/category/'+prod.categoryId+'/'+prod._id]">
                        <a href="javascript:void(0)" class="product-name">
                            <h5>{{prod.name | titlecase}}</h5>
                        </a>
                        <h6><strong><span class="mdi mdi-approval mr-1"></span></strong>{{prod.unit}} {{prod.scale}}</h6>
                    </div>
                </a>

                <div class="d-flex flex-wrap justify-content-between product-footer text-center">
                    <p class="d-flex mb-0 mx-1 my-auto offer-price">
                        {{prod.offerPrice | currency:"INR"}}
                        <span class="regular-price ml-2">{{prod.retailPrice | currency:"INR"}}</span>
                    </p>

                    <!-- <div class="product-card-actions">
                        <button title="Wishlist" class="btn btn-wishlist" (click)="changeWishList(prod,i)">
                            <i [class]="prod.isFavourite?'mdi mdi-heart':'mdi mdi-heart-outline'"
                                [style.color]="prod.isFavourite?'red':'black'"></i>
                        </button>
                    </div> -->

                    <button type="button" class="btn btn-secondary btn-sm flex-fill  flex-lg-grow-0 mx-1 my-2" [disabled]="(prod.quantity - prod.lowQuantity) < 1" (click)="addToCart(prod._id,prod.priceId)">
                        <span class=" d-none d-md-block"><i class="mdi mdi-cart-outline"></i> Add To Cart</span>
                        <span class=" d-block d-md-none"><i class="mdi mdi-cart-plus"></i> Add</span>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #customerProductsDiv>
        <div class="d-flex flex-wrap row-cols-2 row-cols-xl-3" id="my-product-list" *ngIf="products && products.length!=0">
            <div class="product flex-fill" *ngFor="let prod of products; let i = index;">
                <a href="javascript:void(0);" class="product-image">
                    <!-- <a href="javascript:void(0);" [routerLink]="['/category', { id: prod.categoryId }, '/', {prod_id: prod._id}]" class="product-image"> -->
                    <div class="product-header">
                        <!-- <span class="badge badge-success">50% OFF</span> -->
                        <img class="img-fluid" iLoader [src]="prod.pictures && prod.pictures[0] ?  apiUrl+prod.pictures[0] : 'assets/img/item/1.jpg'" alt="product image">
                        <span type="button" class="wishlistbtn border-0" [class]="prod.isFavourite?'mdi mdi-heart':'mdi mdi-heart-outline'" [style.color]="prod.isFavourite?'red':'black'" (click)="changeWishList(prod,i)"></span>
                    </div>
                    <div class="product-body" [routerLink]="['/category/'+prod.categoryId+'/'+prod._id]">
                        <a href="javascript:void(0)" class="product-name">
                            <h5>{{prod.name | titlecase}}</h5>
                        </a>
                        <h6><strong><span class="mdi mdi-approval mr-1"></span></strong>{{prod.unit}} {{prod.scale}}</h6>
                    </div>
                </a>

                <div class="d-flex flex-wrap justify-content-between product-footer text-center">
                    <p class="d-flex mb-0 mx-1 my-auto offer-price">
                        {{( profile.isElite ? prod.elitePrice : prod.offerPrice) | currency:"INR"}}
                        <span class="regular-price ml-2">{{prod.retailPrice | currency:"INR"}}</span>
                    </p>

                    <!-- <div class="product-card-actions">
                        <button title="Wishlist" class="btn btn-wishlist" (click)="changeWishList(prod,i)">
                            <i [class]="prod.isFavourite?'mdi mdi-heart':'mdi mdi-heart-outline'"
                                [style.color]="prod.isFavourite?'red':'black'"></i>
                        </button>
                    </div> -->

                    <button type="button" class="btn btn-secondary btn-sm flex-fill  flex-lg-grow-0 mx-1 my-2" [disabled]="(prod.quantity - prod.lowQuantity) < 1" (click)="addToCart(prod._id,prod.priceId)">
                        <span class=" d-none d-md-block"><i class="mdi mdi-cart-outline"></i> Add To Cart</span>
                        <span class=" d-block d-md-none"><i class="mdi mdi-cart-plus"></i> Add</span>
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
    <div class="search-result-middle empty" *ngIf="!products || products.length==0">
        <div class="empty-message">
            <lottie-player src="https://assets6.lottiefiles.com/packages/lf20_CWcCII.json" background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay>
            </lottie-player>
            <h2>Oops! No product found.</h2>
        </div>
    </div>
</div>

<nav *ngIf="totalLength!=0" class="search-result-bottom">
    <span class="showing-results">{{showPage()}}</span>
    <ul class="pagination justify-content-center mt-4" *ngIf="hasPaginate()">
        <li class="page-item " [class.disabled]="page==1">
            <span class="page-link" type="button" [class.disabled]="page==1" (click)="setPage('DEC')">Previous</span>
        </li>
        <li class="page-item" *ngFor="let pi of getPageArray();" [class.disabled]="pi=='...'" [class.active]="pi==page">
            <span class="page-link" type="button" (click)="setCurrentPage(pi)">{{pi}}</span>
        </li>

        <li class="page-item" [class.disabled]="isLastPage()">
            <span class="page-link" type="button" (click)="setPage('INC')" [class.disabled]="true">Next</span>
        </li>
    </ul>
</nav>