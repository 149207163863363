<div>
    <div class="card card-body account-right">
        <div class="widget">
            <div class="section-header">
                <h5 class="heading-design-h5">
                    My Profile
                </h5>
            </div>
            <form>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label class="control-label">Name <span class="required">*</span></label>
                            <input class="form-control border-form-control" value="" placeholder="Gurdeep" type="text">
                        </div>
                    </div>
                    <!-- <div class="col-sm-6">
                   <div class="form-group">
                      <label class="control-label">Last Name <span class="required">*</span></label>
                      <input class="form-control border-form-control" value="" placeholder="Osahan" type="text">
                   </div>
                </div> -->
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="control-label">Phone <span class="required">*</span></label>
                            <input class="form-control border-form-control" value="" placeholder="123 456 7890" type="number">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="control-label">Email Address <span class="required">*</span></label>
                            <input class="form-control border-form-control " value="" placeholder="iamosahan@gmail.com" disabled="" type="email">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="control-label">Country <span class="required">*</span></label>
                            <select class="select2 form-control border-form-control select2-hidden-accessible" data-select2-id="4" tabindex="-1" aria-hidden="true">
                         <option value="" data-select2-id="6">Select Country</option>
                         <option value="AF">Afghanistan</option>

                      </select><span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="5" style="width: 248.833px;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-labelledby="select2-phep-container"><span class="select2-selection__rendered" id="select2-phep-container" role="textbox" aria-readonly="true" title="Select Country">Select Country</span>
                            <span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>
                            </span>
                            </span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="control-label">City <span class="required">*</span></label>
                            <select class="select2 form-control border-form-control select2-hidden-accessible" data-select2-id="7" tabindex="-1" aria-hidden="true">
                         <option value="" data-select2-id="9">Select City</option>
                         <option value="AF">Alaska</option>
                         <option value="AX">New Hampshire</option>
                         <option value="AL">Oregon</option>
                         <option value="DZ">Toronto</option>
                      </select><span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="8" style="width: 248.833px;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-labelledby="select2-t5e2-container"><span class="select2-selection__rendered" id="select2-t5e2-container" role="textbox" aria-readonly="true" title="Select City">Select City</span>
                            <span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>
                            </span>
                            </span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="control-label">Zip Code <span class="required">*</span></label>
                            <input class="form-control border-form-control" value="" placeholder="123456" type="number">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="control-label">State <span class="required">*</span></label>
                            <select class="select2 form-control border-form-control select2-hidden-accessible" data-select2-id="10" tabindex="-1" aria-hidden="true">
                         <option value="" data-select2-id="12">Select State</option>
                         <option value="AF">California</option>
                         <option value="AX">Florida</option>
                         <option value="AL">Georgia</option>
                         <option value="DZ">Idaho</option>
                      </select><span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="11" style="width: 248.833px;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-labelledby="select2-v6p9-container"><span class="select2-selection__rendered" id="select2-v6p9-container" role="textbox" aria-readonly="true" title="Select State">Select State</span>
                            <span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>
                            </span>
                            </span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label class="control-label">Address <span class="required">*</span></label>
                            <textarea class="form-control border-form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-danger btn-lg"> Cencel </button>
                        <button type="button" class="btn btn-success btn-lg"> Save Changes </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>