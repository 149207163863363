<nav class="navbar navbar-expand-lg navbar-light osahan-menu-2 pad-none-mobile">
    <div class="container-fluid">
        <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0 margin-auto">
                <li class="nav-item">
                    <a class="nav-link shop" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" [routerLink]="['/']" routerLinkActive="router-link-active"><span class="mdi mdi-store" style="font-size: 16px;"></span> Home</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/category-list" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" class="nav-link">Category List</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/aboutus" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" class="nav-link">About Us</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/account/address" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" class="nav-link">My Account</a>
                </li>

                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                   My Account
                   </a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" type="button"
                        data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText"
                        aria-expanded="false" aria-label="Toggle navigation" routerLink="/account/address"><i class="mdi mdi-chevron-right" aria-hidden="true"></i>  My Address</a>
                        <a class="dropdown-item" type="button"
                        data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText"
                        aria-expanded="false" aria-label="Toggle navigation" routerLink="/account/elite-plans"><i class="mdi mdi-chevron-right" aria-hidden="true"></i>  Elite Membership</a>
                        <a class="dropdown-item" type="button"
                        data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText"
                        aria-expanded="false" aria-label="Toggle navigation" routerLink="/account/wishlist"><i class="mdi mdi-chevron-right" aria-hidden="true"></i>  Wish List </a>
                        <a class="dropdown-item" type="button"
                        data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText"
                        aria-expanded="false" aria-label="Toggle navigation" routerLink="/account/orders"><i class="mdi mdi-chevron-right" aria-hidden="true"></i>  Order List</a>
                    </div>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" routerLink="/contact">Contact</a>
                </li>
                <li class="nav-item d-md-none">
                    <a class="nav-link" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" *ngIf="!loggedIn" href="javascript:void(0)" data-target="#bd-example-modal" id="login-signup-open-btn"
                        data-toggle="modal"> Login</a>

                    <a class="nav-link" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" *ngIf="loggedIn" href="Javascript:void(0)" data-target="#bd-example-modal-logout" id="login-signup-close-btn"
                        data-toggle="modal"> Logout</a>
                </li>
            </ul>
        </div>
    </div>
</nav>