import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {getApiUrl} from '../../../utils';
declare var $:any;

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  apiUrl:string;
  @Input()
    slider:any;
  constructor(public sanitizer:DomSanitizer) {
    this.apiUrl=getApiUrl();
  }

  ngOnInit(): void {
  }

  getUrl(url:string):any{
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngAfterViewInit():void{
    $(document).ready(function(){
      setTimeout(
        function(){          
        // ===========Slider============
        var mainslider = $(".owl-carousel-slider-ra");
        if (mainslider.length > 0) {
            mainslider.owlCarousel({
                items: 1,
                dots: false,
                lazyLoad: true,
                pagination: true,
                autoPlay: 4000,
                loop: true,
                singleItem: true,
                navigation: true,
                stopOnHover: true,
                nav: true,
                navigationText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"]
            });
        }

      },600);
    });
  }

}
