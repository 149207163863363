<section class="section-padding bg-white border-top">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="feature-box">
                    <i class="mdi mdi-truck-fast"></i>
                    <h6>Same Day & Next Day Delivery</h6>
                    <!-- <p>Lorem ipsum dolor sit amet, cons...</p> -->
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="feature-box">
                    <i class="mdi mdi-basket"></i>
                    <h6>100% Satisfaction Guarantee</h6>
                    <!-- <p>Rorem Ipsum Dolor sit amet, cons...</p> -->
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="feature-box">
                    <i class="mdi mdi-tag-heart"></i>
                    <h6>Great Daily Deals Discount</h6>
                    <!-- <p>Sorem Ipsum Dolor sit amet, Cons...</p> -->
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-padding footer bg-dark border-top quick-info-footer">
    <div class="container">
        <div class="d-flex flex-wrap">
            <div class="flex-fill mx-1">
                <h4 class="mt-0 mb-2">
                    <a class="logo" href="index.html"><img src="assets/img/logo.png" alt="Groci" width="140"></a>
                </h4>
                <p class="mb-0"><a class="text-white" href="tel:919787243214"><i class="mdi mdi-phone"></i> +919787243214</a></p>
                <p class="mb-0"><a class="text-white" href="javascript:void(0)"><i class="mdi mdi-home-map-marker"></i> Karaikudi, Tamilnadu</a></p>
                <p class="mb-0"><a class="text-white" href="mailto:contact@tokoshope.com"><i class="mdi mdi-email"></i> contact@tokoshope.com</a></p>
                <p class="mb-0"><a class="text-primary" href="/"><i class="mdi mdi-web"></i> www.tokoshope.com</a></p>
            </div>
            <div class="flex-fill mx-1 d-none">
                <h6 class="mb-4">TOP CITIES </h6>
                <ul>
                    <li><a href="javascript:void(0)">Chennai</a></li>
                    <li><a href="javascript:void(0)">Coimbatore</a></li>
                    <li><a href="javascript:void(0)">Madurai</a></li>
                </ul>
            </div>
            <!-- <div class="col-lg-2 col-md-2">
                <h6 class="mb-4">CATEGORIES</h6>
                <ul>
                    <li><a href="#">Vegetables</a></li>
                    <li><a href="#">Grocery & Staples</a></li>
                    <li><a href="#">Breakfast & Dairy</a></li>
                    <li><a href="#">Soft Drinks</a></li>
                    <li><a href="#">Biscuits & Cookies</a></li>
                </ul>
            </div> -->
            <div class="flex-fill mx-1">
                <h6 class="mb-4">INFORMATION</h6>
                <ul>
                    <li><a routerLink="/aboutus">About Us</a></li>
                    <li><a routerLink="/privacy">Privacy Policy</a></li>
                    <li><a routerLink="/terms">Terms and Conditions</a></li>
                    <li><a routerLink="/payment">Payment Policy</a></li>
                    <li><a routerLink="/return">Return policy</a></li>
                </ul>
            </div>
            <div class="flex-fill mx-1">
                <h6 class="mb-4">Download App</h6>
                <div class="app">
                    <a href="#"><img src="assets/img/google.png" alt=""></a>
                    <a href="#"><img src="assets/img/apple.png" alt=""></a>
                </div>
                <h6 class="mb-3 mt-4">GET IN TOUCH</h6>
                <div class="footer-social">
                    <a class="btn-facebook mx-1" href="https://www.facebook.com/tokoshopeapp/"><i class="mdi mdi-facebook"></i></a>
                    <a class="btn-twitter mx-1 " href="https://twitter.com/ShopeToko"><i class="mdi mdi-twitter"></i></a>
                    <a class="btn-instagram mx-1" href="javascript:void(0)"><i class="mdi mdi-instagram"></i></a>
                    <a class="btn-whatsapp mx-1" href="javascript:void(0)"><i class="mdi mdi-whatsapp"></i></a>
                    <!-- <a class="btn-messenger" href="#"><i class="mdi mdi-facebook-messenger"></i></a> -->
                    <a class="btn-google mx-1" href="javascript:void(0)"><i class="mdi mdi-youtube-tv"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>