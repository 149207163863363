import { HttpClient, HttpParams } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  // defaultState={
  //   _id:null,
  //   isWalletSet:null,
  //   isAddressSet:null
  // };
  // private orderStatus:any=this.defaultState;
  constructor(private http:HttpClient){}
  public item:BehaviorSubject<any>=new BehaviorSubject(null);
  public orderStatus:string;
  public orderRefNo:string="213";
  
  checkOut():Observable<any>{
    return this.http.post(NetworkService.checkout(),{},{headers:NetworkService.getAuthHeader()}).pipe(map((r:any)=>{
      if(r.code=="ECM003")
        // this.defaultState=r.payload;
      return r;
    }));
  }

  checkWallet(obj):Observable<any>{
    return this.http.post(NetworkService.checkWallet(),obj,{headers:NetworkService.getAuthHeader()});
    // return this.http.get(NetworkService.checkWallet()+`?walletAmount=${obj.walletAmount}`,{headers:NetworkService.getAuthHeader()});
  }

  placeOrder(obj):Observable<any>{
    return this.http.post(NetworkService.placeOrder(),obj,{headers:NetworkService.getAuthHeader()});
  }

  viewOrder(_id):Observable<any>{
    return this.http.get(NetworkService.viewOrder(),{headers:NetworkService.getAuthHeader(),params:{_id}});
  }

  completeRazorPayment(transactionDetails,_id):Observable<any>{
    return this.http.post(NetworkService.completeRazorPayment,{transactionDetails,_id},{headers:NetworkService.getAuthHeader()});
  }

  deletePendingOrder(_id):Observable<any>{
    return this.http.post(NetworkService.deletePendingOrder,{_id},{headers:NetworkService.getAuthHeader()});
  }


  getMyOrders(ps?:any):Observable<any>{
    // if(ps){
    //   var params=new HttpParams(ps);
    // }

    return this.http.get(NetworkService.myOrders,{headers:NetworkService.getAuthHeader(),params:ps?ps:{}});
  }

  getProcessed(obj:any):Observable<any>{
    return this.http.post(NetworkService.getProcessed,obj,{headers:NetworkService.getAuthHeader()});
  }


  returnOrder(obj):Observable<any>{
    return this.http.post(NetworkService.returnProduct,obj,{headers:NetworkService.getAuthHeader()});
  }

  replaceOrder(obj):Observable<any>{
    return this.http.post(NetworkService.replaceProduct,obj,{headers:NetworkService.getAuthHeader()});
  }

  myReturns():Observable<any>{
    return this.http.post(NetworkService.myReturns,{},{headers:NetworkService.getAuthHeader()});
  }

  myReplaces():Observable<any>{
    return this.http.post(NetworkService.myReplaces,{},{headers:NetworkService.getAuthHeader()});
  }

  getSlots():Observable<any>{
    return this.http.get(NetworkService.getTimeSlots,{headers:NetworkService.getAuthHeader()});
  }

  // getLocalOrderProcessingStatus(){
  //   return this.orderStatus;
  // }

  // setAddress(obj):Observable<any>{
  //   return this.http.post(NetworkService.setAddress(),obj,{headers:NetworkService.getAuthHeader()}).pipe(map((r:any)=>{
  //     if(r.code=="ECM007")
  //       this.defaultState=r.payload;
      
  //     return r;
  //   }));
  // }

  // setPaymentMethod(obj):Observable<any>{
  //   return this.http.post(NetworkService.setPayment(),obj,{headers:NetworkService.getAuthHeader()}).pipe(map(r=>{
  //     return r;
  //   }));
  // }
}
