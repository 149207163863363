<div class="shop-filters mb-2">
    <div id="accordion">
        <div class="card">
            <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
			            Subcategory <span class="ml-1" *ngIf="subCatsArray">({{subCatsArray.length}})</span>
                        <span class="mdi mdi-chevron-down float-right"></span>
			        </button>
                </h5>
            </div>
            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body card-shop-filters overflow-auto " style="height: 350px;">
                    <form class="form-inline">
                        <div class="w-100 mb-0">
                            <input type="text" class="form-control w-100" placeholder="Search By Subcategory" (input)="searchSubCats($event)">
                            <!-- <button type="button" class="pl-2 pr-2 btn btn-secondary btn-lg"><i class="mdi mdi-file-find"></i></button> -->
                        </div>
                    </form>
                    <ng-container *ngIf="subCatsArray && subCatsArray.length > 0 else noSubCatsFound" >
                        <div class="custom-control custom-checkbox" >
                            <input type="checkbox" name="subcatArray" hidden (change)="allchangeHandler($event)" class="custom-control-input" id="cball">
                            <label class="custom-control-label" for="cball">Select/Deselect All </label>
                        </div>
                        <div class="custom-control custom-checkbox" *ngFor="let subCat of subCatsArray; let i = index;">
                            <input type="checkbox" name="subcatArray" [checked]="selectSubCats.includes(subCat._id)" (change)="changeHandler(subCat, $event)" class="custom-control-input" [id]="'cb'+i">
                            <label class="custom-control-label" [for]="'cb'+i">{{subCat.name | titlecase}} </label>
                        </div>
                        <!-- <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="cb1">
                            <label class="custom-control-label" for="cb1">All Fruits </label>
                        </div>
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="cb7">
                            <label class="custom-control-label" for="cb7">Fresh & Herbs <span class="badge badge-primary">5% OFF</span></label>
                        </div> -->
                    </ng-container>
                </div>
                <ng-template #noSubCatsFound>
                    <label class=" p-2" >No Data Found</label>
                </ng-template>
            </div>
        </div>
        <div class="card">
            <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
			 Price <span class="mdi mdi-chevron-down float-right"></span>
			 </button>
                </h5>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body card-shop-filters">
                    <div class="filter-section-inner custom-scrollbar">
                        <div class="filter-section">
                            <div class="filter-price">
                                <form>
                                    <div class="price-input">
                                        <div class="form-group"><input placeholder="Min" type="number" id="price-from" min="0" (focusout)="setPriceRange($event, 0)" class="form-control price-from"></div>
                                        <div class="form-group"><input placeholder="Max" type="number" id="price-to" min="1" (focusout)="setPriceRange($event, 1)" class="form-control price-to"></div>
                                    </div>
                                    <button type="reset" class="border-none btn float-right pb-2 text-secondary" (click)="setPriceRange($event, 3)" >Clear</button>
                                    <!-- <div id="steps-slider"></div> -->
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="1">
                        <label class="custom-control-label" for="1">$68 to $659 <span class="badge badge-warning">50% OFF</span></label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="2">
                        <label class="custom-control-label" for="2">$660 to $1014</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="3">
                        <label class="custom-control-label" for="3">$1015 to $1679</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="4">
                        <label class="custom-control-label" for="4">$1680 to $1856</label>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="card d-none">
            <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
			 Brand <span class="mdi mdi-chevron-down float-right"></span>
			 </button>
                </h5>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div class="card-body card-shop-filters">
                    <form class="form-inline mb-3">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search By Brand">
                        </div>
                        <button type="submit" class="btn btn-secondary ml-2">GO</button>
                    </form>
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="b1">
                        <label class="custom-control-label" for="b1">Imported Fruits <span class="badge badge-warning">50% OFF</span></label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="b2">
                        <label class="custom-control-label" for="b2">Seasonal Fruits <span class="badge badge-secondary">NEW</span></label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="b3">
                        <label class="custom-control-label" for="b3">Imported Fruits <span class="badge badge-danger">10% OFF</span></label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="b4">
                        <label class="custom-control-label" for="b4">Citrus</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card d-none">
            <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
			 Imported Fruits <span class="mdi mdi-chevron-down float-right"></span>
			 </button>
                </h5>
            </div>
            <div id="collapsefour" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div class="card-body">
                    <div class="list-group">
                        <a href="#" class="list-group-item list-group-item-action">All Fruits</a>
                        <a href="#" class="list-group-item list-group-item-action">Imported Fruits</a>
                        <a href="#" class="list-group-item list-group-item-action">Seasonal Fruits</a>
                        <a href="#" class="list-group-item list-group-item-action">Citrus</a>
                        <a href="#" class="list-group-item list-group-item-action disabled">Cut Fresh & Herbs</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>