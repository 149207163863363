import { Component, EventEmitter, OnDestroy, OnInit, Output  } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { ProductsService } from 'src/app/services/products.service';

declare var noUiSlider:any;
declare var wNumb:any;
declare var $:any;

@Component({
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.css']
})
export class CategoryFilterComponent implements OnInit {

  stepsSlider:any;
  input0:any;
  input1:any;
  inputs:any;
  @Output()
  vals:EventEmitter<any>=new EventEmitter<any>();

  constructor(
    private prodService:ProductsService,
    private alert:AlertService,
    private dService: DataService
  ) { }

  catId: any = '';
  subCats:any[];
  subCatsArray:any[];
  selectSubCats:any = [];
  loading: boolean = true;

  lengths:number[];
  selectedLength:number=12;

  dropDownFilters:any;
  selectedDdFilter:any;
  page:number=1;
  totalLength:number=0;
 // pageOffSet:number=0;
  currentShowingPages:number[];
  currentType:any={
      _id:null
  };

  searchTxt: any;
  priceRange:any=[0,200000];

  requestBody:any;

  ngOnInit(): void {
    let str = document.URL;      
    this.catId = str.substring(str.lastIndexOf('/') + 1);
    if(!this.catId || this.catId == '' || this.catId == null){
      this.loading = false;
      return;
    } 

    // console.log('constructor called ', str.substring(str.lastIndexOf('/') + 1));

    this.prodService.getRequestBody().subscribe(response => {
      // console.log(response);
      this.requestBody = response;
    })

    this.requestBody.categoryId=this.catId;
    // let item = {
    //   _id: str.substring(str.lastIndexOf('/') + 1),
    //   searchType:"categoryId"
    // }
      
    // this.dService.setSearchItem(item);
  
    // this.lengths=[2,4,6,8];      
    // this.dropDownFilters=this.dService.getDropDownFilters();
    // this.currentType=this.dService.getSearchItem();
    // this.selectedDdFilter=this.dropDownFilters['newToOld'];
    // this.searchTxt=this.dService.searchTxt;

    this.prodService.getSubCats(this.catId).subscribe(r=>{
      if(r.code=='ECM005'){
        this.subCats=r.payload.subCategories;
        this.subCatsArray = this.subCats;
        this.loading = false;
      }
    });

    this.getTotalLength();

    this.getProducts();

    // this.getDoms();
    // this.init();
    // this.keys();

  }

  ngAfterViewInit(): void {
    if ($(window).width() > 767) {
      $('#collapseOne').addClass('show');
    }
  }

  getDoms(){
    this.stepsSlider=document.getElementById('steps-slider');
    this.input0=document.getElementById('price-from');
    this.input1=document.getElementById('price-to');
    this.inputs=[this.input0,this.input1];
  }
  keys(){
    // var stepsSlider:any = document.getElementById('steps-slider');
    // var input0:any = document.getElementById('price-from');
    // var input1:any = document.getElementById('price-to');
    // var inputs:any = [input0, input1];

    const self=this;
    const {inputs,stepsSlider}=this;
    inputs.forEach(function (input, handle) {
      input.addEventListener('change', function () {
        stepsSlider.noUiSlider.setHandle(handle, this.value);
      });
  
      input.addEventListener('keydown', function (e) {
  
          var values = stepsSlider.noUiSlider.get();
          var value = Number(values[handle]);
  
          // [[handle0_down, handle0_up], [handle1_down, handle1_up]]
          var steps = stepsSlider.noUiSlider.steps();
  
          // [down, up]
          var step = steps[handle];
  
          var position;
  
          // 13 is enter,
          // 38 is key up,
          // 40 is key down.
          switch (e.which) {
  
              case 13:
                  stepsSlider.noUiSlider.setHandle(handle, this.value);
                  break;
  
              case 38:
  
                  // Get step to go increase slider value (up)
                  position = step[1];
  
                  // false = no step is set
                  if (position === false) {
                      position = 1;
                  }
  
                  // null = edge of slider
                  if (position !== null) {
                      stepsSlider.noUiSlider.setHandle(handle, value + position);
                  }
  
                  break;
  
              case 40:
  
                  position = step[0];
  
                  if (position === false) {
                      position = 1;
                  }
  
                  if (position !== null) {
                      stepsSlider.noUiSlider.setHandle(handle, value - position);
                  }
  
                  break;
          }
      });
  });
  }

  init(){
    // var stepsSlider:any = document.getElementById('steps-slider');
    // var input0:any = document.getElementById('price-from');
    // var input1:any = document.getElementById('price-to');
    // var inputs:any = [input0, input1];

    const self=this;
    const {inputs,stepsSlider}=this;
    noUiSlider.create(stepsSlider, {
        start: [0, 10000],
        connect: true,
        // tooltips: [true, wNumb({decimals: 1})],
        range: {
            'min': [0],
            // '10%': [700, 700],
            // '50%': [3500, 3500],
            // '80%': 5600,
            'max': 10000
        }
    });

    stepsSlider.noUiSlider.on('update', function (values, handle) {
        inputs[handle].value = values[handle];
        self.vals.emit(values);
    });
  }

  searchSubCats(e){
    let value = e.target.value;
    if(value != '' || value != null)
      this.subCatsArray = this.subCats.filter(el => {return el['name'].toLowerCase().indexOf(value.toLowerCase()) !== -1})
    else
      this.subCatsArray = this.subCats;
  }

  allchangeHandler(e){
    console.log(e.target.checked);
    
    if(e.target.checked){
      this.selectSubCats = this.subCatsArray.map(e => e._id);
    }
    else{
      this.selectSubCats.length = 0;
    }
    
    setTimeout(() => {      
      this.searchProduct();
    }, 100);
  }

  changeHandler(item: any, event: KeyboardEvent) {
    const id = item;

    const index = this.selectSubCats.findIndex(u => u === id._id);
    if (index === -1) {
      // ADD TO SELECTION
      // this.selection.push(item);
      this.selectSubCats = [...this.selectSubCats, item._id];
    } else {
      // REMOVE FROM SELECTION
      this.selectSubCats = this.selectSubCats.filter(user => user !== item._id)
      // this.selection.splice(index, 1)
    }

    setTimeout(() => {      
      this.searchProduct();
    }, 100);
  }

  searchProduct(){
    console.log(this.selectSubCats);
    
    // this.selectSubCats.push(data._id);

    let item;

    if(this.selectSubCats == [] || this.selectSubCats.length ==0){
      this.requestBody.categoryId=this.catId;
      delete this.requestBody.subCategoryId;
    }
    else{
      this.requestBody.subCategoryId=this.selectSubCats.map(e => e);
      delete this.requestBody.categoryId;
    }
      
    this.dService.setSearchItem(this.requestBody);
  
    this.lengths=[2,4,6,8];      
    this.dropDownFilters=this.dService.getDropDownFilters();
    // this.currentType=this.dService.getSearchItem();
    this.selectedDdFilter=this.dropDownFilters['newToOld'];
    this.searchTxt=this.dService.searchTxt;

    let data={
      loading: true,
    }

    this.prodService.setProducts(data);
    // console.log(this.currentType);
    this.getTotalLength();

    this.getProducts();

  }

  changeName(data: any) {
    this.prodService.setProducts(data);
  }


  getRequestBody():any{
    
    this.requestBody.priceRange=this.priceRange.map(p=>parseFloat(p));
    delete this.requestBody.title;

    if(this.searchTxt)
      this.requestBody.searchTxt=this.searchTxt;

    this.requestBody.size=parseInt(this.requestBody.size);
    // if(this.currentType._id!=null){
    //   this.requestBody={...this.requestBody,[this.currentType.searchType]:this.currentType._id};
    // }
    this.prodService.setRequestBody(this.requestBody);

    return this.requestBody;
  }

  setPriceRange(e, t){
    let data={
      loading: true,
    }

    if(t == 3){
      this.priceRange=[0,200000];
    }
    else{  
      if(this.priceRange[t] != e.target.value){

        if(t == 0){
          this.priceRange[t] = e.target.value == ''? 0 : e.target.value;
        }

        if(t == 1){
          this.priceRange[t] = e.target.value == ''? 200000 : e.target.value;
        }
      }   
    }
        
    this.prodService.setProducts(data);
    
    this.getTotalLength();

    this.getProducts();
    console.log(`price range changed`, this.priceRange);

  }

  getTotalLength():void{  
    // console.log(this.getRequestBody());
      this.prodService.totalProduct(this.getRequestBody()).subscribe(r=>{
        // console.log(r);
        this.totalLength= (r.payload && r.payload.totalCount) || 0;
        // console.log(this.totalLength);
      },err=>{
        this.totalLength=0;
        // console.log(err.message);
      });
  }

  getProducts(){
    // console.log(this.getRequestBody());

    this.loading=true;

    this.prodService.searchProduct(this.getRequestBody()).subscribe(r=>{
        if(!r.success)
          this.alert.showAlertVW(r.message,this.alert.alertTypes.danger);

          let data={
            products: r.payload.products, 
            totalLength: this.totalLength
          }
        this.prodService.setProducts(data);
        // this.prodService.products=r.payload.products && r.payload.products.length!=0?r.payload.products:[];
        this.loading=false;
    },err=>{
        this.prodService.setProducts([]);
        // this.products=[];
        this.loading=false;
        this.alert.showAlertVW(err.message,this.alert.alertTypes.danger);
    });
  }
}
